<h3 *ngIf="data.title" mat-dialog-title style="text-align: center">{{ data.title }}</h3>
<mat-dialog-content fxLayout="column" fxLayoutGap="10px">
  <mat-icon *ngIf="data.type == MessageBoxType.Error" color="warn">warning</mat-icon>
  <div innerHTML="{{ data.message }}"></div>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxFlex fxLayout="row" fxLayoutAlign="end center">
    <ng-container *ngIf="data.type == MessageBoxType.YesNo">
      <button id="yes-button" mat-raised-button cdkFocusInital [mat-dialog-close]="true">{{ 'buttons.yes' | translate }}</button>
      <button id="no-button" mat-raised-button [mat-dialog-close]="false">{{ 'buttons.no' | translate }}</button>
    </ng-container>
    <ng-container *ngIf="data.type == MessageBoxType.Ok">
      <button id="ok-button" mat-raised-button cdkFocusInital [mat-dialog-close]="true">{{ 'buttons.ok' | translate }}</button>
    </ng-container>
    <ng-container *ngIf="data.type == MessageBoxType.OkCancel">
      <button id="ok-button" mat-raised-button cdkFocusInital [mat-dialog-close]="true">{{ 'buttons.ok' | translate }}</button>
      <button id="cancel-button" *ngIf="data.type == MessageBoxType.OkCancel" [mat-dialog-close]="false">
        {{ 'buttons.cancel' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="data.type == MessageBoxType.Error">
      <button id="ok-button" mat-raised-button cdkFocusInital color="warn" [mat-dialog-close]="true">{{ 'buttons.ok' | translate }}</button>
    </ng-container>
  </div>
</mat-dialog-actions>
