import { Component, Input, OnChanges } from '@angular/core';
import { CWQuestionType, ICWQuestionBase } from '../../../../../../../Common/model/cwquestion';
import { LabelTextCompressor, TextCompressed } from '../../../util/label-text-compressor';

@Component({
  selector: 'app-show-tile-question',
  templateUrl: './show-tile-question.component.html',
})
export class ShowTileQuestionComponent implements OnChanges {
  CWQuestionType = CWQuestionType;
  @Input()
  question: ICWQuestionBase;

  @Input()
  tileSize: number;

  imageSize = 0;
  textCompressed: TextCompressed = { fontSize: 12, line1: '', line2: '', charsPerLine: 5, gapBetweenLines: -5 };

  ngOnChanges(): void {
    this.compressQuestionToShow();
    this.imageSize = this.tileSize / 2 - 4;
  }
  private compressQuestionToShow() {
    if (this.tileSize > 0) {
      if (this.question.type == CWQuestionType.Image) {
        this.textCompressed = LabelTextCompressor.compressToOneLine(this.tileSize, this.question.question);
      } else {
        this.textCompressed = LabelTextCompressor.compressText(this.tileSize, this.question.question);
      }
    }
  }
}
