const BLANK_SPACE = ' ';

export interface FontSizeAndCharsPerLine {
  size: number;
  charsPerLine: number;
  interval: SizeInterval;
}
interface SizeInterval {
  start: number;
  end: number;
}

export class FontSizeAndLengthIntervals {
  private static readonly calculationDatas: FontSizeAndLengthIntervals[] = [
    new FontSizeAndLengthIntervals(
      { start: 48, end: Number.MAX_SAFE_INTEGER },
      -4,
      { size: 11, charsPerLine: 8, interval: { start: 13, end: Number.MAX_SAFE_INTEGER } },
      { size: 14, charsPerLine: 6, interval: { start: 0, end: 13 } },
    ),
    new FontSizeAndLengthIntervals({ start: 40, end: 48 }, -4, {
      size: 11,
      charsPerLine: 6,
      interval: { start: 0, end: Number.MAX_SAFE_INTEGER },
    }),
    new FontSizeAndLengthIntervals({ start: 32, end: 40 }, -4, {
      size: 11,
      charsPerLine: 5,
      interval: { start: 0, end: Number.MAX_SAFE_INTEGER },
    }),
    new FontSizeAndLengthIntervals({ start: 0, end: 32 }, -4, {
      size: 10,
      charsPerLine: 4,
      interval: { start: 0, end: Number.MAX_SAFE_INTEGER },
    }),
  ];
  private sizeAndLength: FontSizeAndCharsPerLine[] = [];
  private constructor(
    private size: SizeInterval,
    public gapBetwwenLines: number,
    ...args: FontSizeAndCharsPerLine[]
  ) {
    this.sizeAndLength.push(...args);
  }

  public static calculateFromWidthAndValue(width: number, value: string) {
    const data = this.getClosestFontSize(width);
    const lengthToCheck = value.length + 2;
    for (const fontLength of data.sizeAndLength) {
      if (lengthToCheck >= fontLength.interval.start && lengthToCheck < fontLength.interval.end) {
        return { size: fontLength.size, charsPerLine: fontLength.charsPerLine, gapBetweenLines: data.gapBetwwenLines };
      }
    }
    return null;
  }

  private static getClosestFontSize(width: number) {
    for (const data of this.calculationDatas) {
      if (width >= data.size.start && width < data.size.end) {
        return data;
      }
    }
    return null;
  }
}

export interface TextCompressed {
  line1: string;
  line2: string;
  fontSize: number;
  charsPerLine: number;
  gapBetweenLines: number;
}
export class LastLineCompressor {
  constructor(private charsPerLine: number) {}
  compress(value: string): CompressedLine {
    if (value.length > this.charsPerLine) {
      const line = value.slice(0, this.charsPerLine - 2) + '..';
      const remainingValue = value.slice(this.charsPerLine - 2);
      return { line, remainingValue };
    } else {
      return { line: value, remainingValue: '' };
    }
  }
}

export class JoinWordOverLine {
  constructor(private charsPerLine: number) {}
  joinOverLine(line: string, remainingValue: string): CompressedLine {
    const remainingWords: string[] = remainingValue.split(BLANK_SPACE);
    if (remainingWords.length > 0) {
      const firstWord = remainingWords[0];
      if (firstWord.length > this.charsPerLine) {
        const charsLeft = this.charsPerLine - line.length;
        const blankSpaceBetween = line.length > 0;
        const leastAmountNeededToAppend = (blankSpaceBetween ? 1 : 0) + 1;
        if (charsLeft > leastAmountNeededToAppend) {
          const toAppend = firstWord.slice(0, charsLeft - leastAmountNeededToAppend);
          const toReplace = firstWord.slice(toAppend.length);
          remainingWords[0] = toReplace;
          const newLine = line + (blankSpaceBetween ? BLANK_SPACE : '') + toAppend + '-';
          return { line: newLine, remainingValue: remainingWords.join(BLANK_SPACE) };
        }
      }
    }
    return { line: line, remainingValue: remainingValue };
  }
}
export interface CompressedLine {
  remainingValue: string;
  line: string;
}
export class FirstLineCompressor {
  constructor(private charsPerLine: number) {}
  compressToLine(value: string): CompressedLine {
    const words = value.split(BLANK_SPACE);
    let line = '';
    let i = 0;
    for (; i < words.length; i++) {
      const word = words[i];
      const append = this.checkAndAddEmptyChar(i) + word;
      if (line.length + append.length <= this.charsPerLine) {
        line += append;
      } else {
        break;
      }
    }

    return { remainingValue: words.slice(i).join(BLANK_SPACE), line: line };
  }

  private checkAndAddEmptyChar(i: number) {
    return i > 0 ? BLANK_SPACE : '';
  }
}

export class LabelTextCompressor {
  static compressToOneLine(width: number, value: string): TextCompressed {
    const { size, charsPerLine, gapBetweenLines } = FontSizeAndLengthIntervals.calculateFromWidthAndValue(width, value);

    const lineCompressor = new LastLineCompressor(charsPerLine);

    const compressedLine = lineCompressor.compress(value);

    return {
      line1: compressedLine.line,
      line2: '',
      fontSize: size,
      charsPerLine: charsPerLine,
      gapBetweenLines: gapBetweenLines,
    };
  }
  static compressText(width: number, value: string): TextCompressed {
    const { size, charsPerLine, gapBetweenLines } = FontSizeAndLengthIntervals.calculateFromWidthAndValue(width, value);

    const lineCompressor = new FirstLineCompressor(charsPerLine);
    const joinOverLine = new JoinWordOverLine(charsPerLine);
    const endLineCompress = new LastLineCompressor(charsPerLine);

    const compressedLine1 = lineCompressor.compressToLine(value);
    const overLine = joinOverLine.joinOverLine(compressedLine1.line, compressedLine1.remainingValue);
    const compressedLine2 = endLineCompress.compress(overLine.remainingValue);

    return {
      line1: overLine.line,
      line2: compressedLine2.line,
      fontSize: size,
      charsPerLine: charsPerLine,
      gapBetweenLines: gapBetweenLines,
    };
  }
  static doesTextFitOnOneLine(width: number, value: string): boolean {
    const { charsPerLine } = FontSizeAndLengthIntervals.calculateFromWidthAndValue(width, value);
    const lineCompressor = new FirstLineCompressor(charsPerLine);

    const compressedLine1 = lineCompressor.compressToLine(value);

    return compressedLine1.remainingValue.length == 0;
  }
}
