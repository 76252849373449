export class CachedMap<K, T> {
  private map: Map<K, T> = new Map<K, T>();
  private lastTime: number = Number.MIN_SAFE_INTEGER;
  constructor(private cacheTime: number) {}

  getMap() {
    if (this.hasExpired()) {
      this.map.clear();
    }
    this.lastTime = Date.now();
    return this.map;
  }
  remove(index: K) {
    this.map.delete(index);
  }
  private hasExpired() {
    return Date.now() > this.lastTime + this.cacheTime;
  }
}
