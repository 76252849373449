export class IntervalTimer {
  private intervalCounter: number = 0;
  private startIntervalTimestamp: number = 0;
  constructor(private readonly interval: number[], private readonly perIntervalTimeInMilli: number) {}
  getCurrentInterval() {
    return this.interval[this.intervalCounter];
  }
  checkAndIncreaseInterval() {
    if (Date.now() < this.startIntervalTimestamp + this.perIntervalTimeInMilli) {
      return;
    }
    this.startIntervalTimestamp = Date.now();
    if (this.interval.length <= this.intervalCounter + 1) {
      return;
    }
    this.intervalCounter++;
  }
  reset() {
    this.intervalCounter = 0;
    this.startIntervalTimestamp = Date.now();
  }
}
export class BackoffTimer {
  private timerId: any;
  private method: () => void;
  private intervalCounter: IntervalTimer;

  constructor(interval: number[], perIntervalTimeInMilli: number, method: () => void) {
    this.intervalCounter = new IntervalTimer(interval, perIntervalTimeInMilli);
    this.method = method;
  }
  resetIntervalCounter(interval: number[], perIntervalTimeInMilli: number) {
    this.intervalCounter = new IntervalTimer(interval, perIntervalTimeInMilli);
    this.reset();
  }
  start() {
    this.stop();
    this.timerId = setTimeout(() => this.onTimer(), this.intervalCounter.getCurrentInterval());
  }
  stop() {
    if (this.timerId != null) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
  }
  reset() {
    if (this.isRunning()) {
      this.stop();
      this.intervalCounter.reset();
      this.start();
    }
  }
  private onTimer() {
    this.method();
    this.intervalCounter.checkAndIncreaseInterval();
    this.start();
  }
  private isRunning() {
    return this.timerId != null;
  }
}
