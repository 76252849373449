export class StopWatch {
  private static watches: number[] = [];
  static start(pThis: any) {
    this.watches[pThis] = Date.now();
  }
  static stop(pThis: any, log?: boolean, id?: string) {
    const started = this.watches[pThis];
    if (started != null) {
      this.watches[pThis] = null;
      const elapsed = Date.now() - started;
      if (log) {
        console.log(`Elapsed id:${id} time:${elapsed}ms`);
      }
      return elapsed;
    } else {
      return -1;
    }
  }
}
