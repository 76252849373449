<h3 mat-dialog-title style="text-align: center">{{ 'help-instruction.title' | translate }}</h3>
<mat-dialog-content>
  <div *ngIf="data.helpInstruction == HelpInstruction.Game" innerHTML="{{ 'help-instruction.game' | translate }}"></div>
  <div
    *ngIf="data.helpInstruction == HelpInstruction.BuildCrossword"
    innerHTML="{{ 'help-instruction.build-crossword' | translate }}"
  ></div>
  <div
    *ngIf="data.helpInstruction == HelpInstruction.AddAppToHomescreen"
    innerHTML="{{ 'help-instruction.add-app-to-home-screen' | translate }}"
  ></div>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxFlex fxLayout="row" fxLayoutAlign="end center">
    <button id="dissmiss-help" *ngIf="data.showDismiss" mat-raised-button [mat-dialog-close]="HelpDialogClose.CloseAndDismissInstruction">
      {{ 'buttons.close-and-dismiss' | translate }}
    </button>
    <button id="close-help" mat-raised-button cdkFocusInital [mat-dialog-close]="HelpDialogClose.Close">
      {{ 'buttons.close' | translate }}
    </button>
  </div>
</mat-dialog-actions>
