import { LanguageCode } from '../data/language';

export class LanguageUtil {
  static getLanguageCode(locale: string) {
    switch (locale.toLowerCase()) {
      case 'sv':
        return LanguageCode.Swedish;
      case 'en':
        return LanguageCode.English;
      default:
        return LanguageCode.Swedish;
    }
  }
  static getLocaleFromLanguageCode(languageCode: LanguageCode) {
    switch (languageCode) {
      case LanguageCode.Swedish:
        return 'sv';
      case LanguageCode.English:
        return 'en';
      default:
        return 'sv';
    }
  }
}
