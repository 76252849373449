import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ICrossword } from '../../../../../Common/model/crossword';
import { CrosswordBuildListModel, ICrosswordBuild } from '../../../../../Common/model/crossword.build';
import { IPlayer } from '../../../../../Common/model/player';
import { AppSettingsService } from '../appsettings/app-settings.service';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class CrosswordBuildService {
  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
    private appSettingsService: AppSettingsService,
  ) {}

  async get(id: string) {
    const routes = this.configService.getRoutes();
    return await lastValueFrom(
      this.httpClient.get<ICrosswordBuild>(`${this.appSettingsService.appSettings.apiurl}${routes.crosswordBuild}/${id}`),
    );
  }

  async getListOfCrosswordBuilds(player: IPlayer) {
    const routes = this.configService.getRoutes();
    return await lastValueFrom(
      this.httpClient.get<CrosswordBuildListModel[]>(
        `${this.appSettingsService.appSettings.apiurl}${routes.crosswordBuild}/player/${player._id}`,
      ),
    );
  }

  async create(player: IPlayer, crossword: ICrossword) {
    const routes = this.configService.getRoutes();
    return await lastValueFrom(
      this.httpClient.put<ICrosswordBuild>(this.appSettingsService.appSettings.apiurl + routes.crosswordBuild, {
        crossword,
        cwQuestionsAdded: [],
        player,
      }),
    );
  }
  async update(crosswordBuild: ICrosswordBuild) {
    const routes = this.configService.getRoutes();
    return await lastValueFrom(
      this.httpClient.post<ICrosswordBuild>(this.appSettingsService.appSettings.apiurl + routes.crosswordBuild, crosswordBuild),
    );
  }

  async delete(id: string) {
    const routes = this.configService.getRoutes();
    return await lastValueFrom(
      this.httpClient.delete<ICrosswordBuild>(this.appSettingsService.appSettings.apiurl + `${routes.crosswordBuild}/${id}`),
    );
  }

  async publish(crosswordBuild: ICrosswordBuild) {
    const routes = this.configService.getRoutes();
    return await lastValueFrom(
      this.httpClient.post<ICrosswordBuild>(
        this.appSettingsService.appSettings.apiurl + `${routes.crosswordBuild}/publish`,
        crosswordBuild,
      ),
    );
  }
  async unpublish(crosswordBuild: ICrosswordBuild) {
    const routes = this.configService.getRoutes();
    return await lastValueFrom(
      this.httpClient.post<ICrosswordBuild>(
        this.appSettingsService.appSettings.apiurl + `${routes.crosswordBuild}/unpublish`,
        crosswordBuild,
      ),
    );
  }
  async republish(crosswordBuild: ICrosswordBuild) {
    const routes = this.configService.getRoutes();
    return await lastValueFrom(
      this.httpClient.post<ICrosswordBuild>(
        this.appSettingsService.appSettings.apiurl + `${routes.crosswordBuild}/republish`,
        crosswordBuild,
      ),
    );
  }
}
