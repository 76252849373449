<div *ngIf="loaded" fxLayout="column" fxLayoutGap="10px" style="padding: 10px">
  <app-flexable-button-menu fxFlex [buttons]="actionButtons"> </app-flexable-button-menu>
  <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap="10px">
    <mat-form-field fxFlex>
      <mat-label>{{ 'labels.filter-crosswords' | translate }}</mat-label>
      <input
        #filterInput
        matInput
        [placeholder]="'labels.filter-crosswords' | translate"
        (keyup)="applyFilter(filterInput.value)"
        autocomplete="off"
      />
    </mat-form-field>
    <app-select-filter-items
      fxFlex
      id="bookable-classes"
      [selectFilterGroups]="selectFilterGroups"
      [allValues]="allBuiltCrosswordListItems"
      (filteredValuesChanged)="onSelectFilteredValuesChanged($event)"
    ></app-select-filter-items>
  </div>
  <mat-table
    id="list-crosswords-table"
    #table
    matSort
    [dataSource]="crosswordLisItems"
    (matSortChange)="onSortChange($event)"
    matSortActive="name"
    matSortDirection="asc"
  >
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'columns.name' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let crossword"> {{ crossword.name }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="authorName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'columns.author' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let crossword"> {{ crossword.authorName }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef class="w-150" mat-sort-header>{{ 'columns.published' | translate }}</mat-header-cell>
      <mat-cell class="w-150" *matCellDef="let crossword"> {{ crossword.date | date: 'yyyy-MM-dd' }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="difficultyAsString">
      <mat-header-cell *matHeaderCellDef mat-sort-header class="w-100">{{ 'columns.difficulty' | translate }}</mat-header-cell>
      <mat-cell class="w-100" *matCellDef="let crossword"> {{ crossword.difficultyAsString }} </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="dispayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let crossword; columns: dispayedColumns"
      [id]="'crossword-' + crossword.id"
      [ngClass]="{ hovered: crossword.hovered }"
      (click)="onCrosswordSelected(crossword)"
      (mouseover)="crossword.hovered = true"
      (mouseout)="crossword.hovered = false"
      (blur)="crossword.hovered = false"
      (focus)="crossword.hovered = true"
    >
    </mat-row>
  </mat-table>
  <mat-paginator
    [ngStyle]="{ display: !loaded || crosswordLisItems.data.length == 0 ? 'none' : 'block' }"
    [pageSizeOptions]="[10, 50, 100]"
  ></mat-paginator>
</div>
<app-progress-bar *ngIf="!loaded"></app-progress-bar>
