import { Injectable } from '@angular/core';
import { DialogPosition, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { PriorityValue } from '../../../../../../Common/data/priority.value';
import { ICWQuestionBase } from '../../../../../../Common/model/cwquestion';
import { HelpInstruction } from '../../../../../../Common/model/player.settings';
import {
  SearchImagesComponent,
  SearchImagesData,
  SearchImagesResult,
} from '../../build-crossword/create-edit-question/search-images/search-images.component';
import { ShowLargeImageComponent, ShowLargeImageData } from '../../common/show-crossword/show-large-image/show-large-image.component';
import { AskDirection, AskDirectionMessageBoxComponent } from '../ask-direction-message-box/ask-direction-message-box.component';
import { HelpDialogContentComponent, HelpDialogData } from '../help-dialog-content/help-dialog-content.component';
import { LoadingProgressModalContentComponent } from '../loading-progress-modal-content/loading-progress-modal-content.component';
import { MessageBoxContentComponent, MessageBoxData, MessageBoxType } from '../message-box-content/message-box-content.component';
import { QuestionPriorityData, QuestionPriorityDialogComponent } from '../question-priority/question-priority-dialog.component';
import {
  SelectPlayerColorComponent,
  SelectPlayerColorData,
  SelectPlayerColorResult,
} from '../select-player-color/select-player-color.component';
import { ShareGameComponent, ShareGameSettings } from '../share-game/share-game.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private currentMessageBox: MatDialogRef<MessageBoxContentComponent> = null;
  constructor(
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {}
  private progressDialogRef: MatDialogRef<unknown>;
  async wrapInProgress<T>(action: () => Promise<T>, message?: string, minimal = false) {
    this.showProgress(true, message, minimal);
    try {
      return await action();
    } finally {
      this.showProgress(false);
    }
  }
  async showProgress(visible: boolean, message?: string, minimal = false) {
    if (visible) {
      await this.showProgressDialogIfNotShowing(message != null ? message : '', minimal);
    } else {
      await this.hideProgressDialogIfShowing();
    }
  }
  isMessageBoxShowing() {
    return this.currentMessageBox != null;
  }
  async showMessageBox(title: string, message: string, type: MessageBoxType, disableClose = false) {
    const data: MessageBoxData = { title, message, type };
    this.currentMessageBox = this.matDialog.open(MessageBoxContentComponent, {
      data,
      disableClose: disableClose,
    });
    const result: boolean = await lastValueFrom(this.currentMessageBox.afterClosed());
    this.currentMessageBox = null;
    return result;
  }
  async showQuestionPriorityDialog(disableCancel: boolean) {
    const data: QuestionPriorityData = { disableCancel: disableCancel };
    const result: PriorityValue = await lastValueFrom(
      this.matDialog.open(QuestionPriorityDialogComponent, { data: data, disableClose: true }).afterClosed(),
    );
    return result;
  }
  async showShareGame(shareGameSettings: ShareGameSettings) {
    await lastValueFrom(this.matDialog.open(ShareGameComponent, { data: shareGameSettings }).afterClosed());
  }
  async showAskDirectionDialog() {
    const result: AskDirection = await lastValueFrom(
      this.matDialog.open(AskDirectionMessageBoxComponent, { disableClose: false }).afterClosed(),
    );
    return result;
  }
  async showHelpDialog(helpInstruction: HelpInstruction, showDismiss: boolean) {
    const data: HelpDialogData = { helpInstruction: helpInstruction, showDismiss: showDismiss };
    const result = await lastValueFrom(
      this.matDialog
        .open(HelpDialogContentComponent, {
          data,
        })
        .afterClosed(),
    );
    return result;
  }

  async showSnackbar(message: string, duration = -1) {
    const result = await lastValueFrom(
      this.matSnackBar.open(message, this.translateService.instant('buttons.close'), { duration }).afterDismissed(),
    );
    return result;
  }

  private async hideProgressDialogIfShowing() {
    if (this.progressDialogRef != null) {
      this.progressDialogRef.close();
      await lastValueFrom(this.progressDialogRef.afterClosed());
      this.progressDialogRef = null;
    }
  }

  private async showProgressDialogIfNotShowing(message: string, minimal = false) {
    if (this.progressDialogRef == null) {
      const position: DialogPosition = minimal ? { top: '20px' } : null;
      this.progressDialogRef = this.matDialog.open(LoadingProgressModalContentComponent, {
        panelClass: 'transparent',
        maxWidth: minimal ? 300 : 200,
        disableClose: true,
        data: { message, minimal },
        position: position,
      });
      await lastValueFrom(this.progressDialogRef.afterOpened());
    }
  }
  async showSelectPlayerColor(data: SelectPlayerColorData) {
    const result: SelectPlayerColorResult = await lastValueFrom(
      this.matDialog
        .open(SelectPlayerColorComponent, {
          data,
          disableClose: true,
        })
        .afterClosed(),
    );
    return result;
  }

  async showSearchForImages(currentImageUrl: string) {
    const data: SearchImagesData = { currentImageUrl: currentImageUrl };
    const result: SearchImagesResult = await lastValueFrom(
      this.matDialog
        .open(SearchImagesComponent, {
          data: data,
          width: `${window.innerWidth - 20}px`,
          maxWidth: `${window.innerHeight - 20}px`,
          disableClose: true,
        })
        .afterClosed(),
    );
    return result;
  }

  async showLargeImage(question: ICWQuestionBase) {
    const data: ShowLargeImageData = { question: question };
    const width = Math.min(window.innerWidth - 20, 400);
    await lastValueFrom(
      this.matDialog
        .open(ShowLargeImageComponent, {
          data: data,
          width: `${width}px`,
          maxWidth: `${width}px`,
          disableClose: true,
        })
        .afterClosed(),
    );
  }
}
