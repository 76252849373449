import { Component, Input, OnChanges } from '@angular/core';
import { Direction, Position, Rectangle } from '../../../../../../../Common/data/position';
import { ICrosswordSize } from '../../../../../../../Common/model/crossword';
import { CWQuestionType, ICWQuestionBase } from '../../../../../../../Common/model/cwquestion';
import { ImageService } from '../../../../services/image/image.service';
import { DialogService } from '../../../dialog/service/dialog.service';
import { ShowAllImageUtil } from './show-all-image.util';

@Component({
  selector: 'app-show-all-image',
  templateUrl: './show-all-image.component.html',
  styleUrls: ['show-all-image.component.scss'],
})
export class ShowAllImageComponent implements OnChanges {
  CWQuestionType = CWQuestionType;
  @Input()
  questionPosition: Position;
  @Input()
  question: ICWQuestionBase;
  @Input()
  size: number;
  @Input()
  showCrosswordWidth: number;
  @Input()
  matrixDirection: Direction;
  @Input()
  crosswordSize: ICrosswordSize;
  positionAndSize: Rectangle;
  imageLoaded = false;
  imageUrl: string | null;

  private readonly wantedImageSize = 200;

  constructor(
    private imageService: ImageService,
    private dialogService: DialogService,
  ) {}

  ngOnChanges(changes: any): void {
    this.calculatePosition();
    if (changes.question) {
      this.checkAndSetImageUrl();
    }
  }
  checkAndSetImageUrl() {
    this.imageUrl = this.imageService.getImageUrl(this.question.imageId);
    this.imageLoaded = false;
  }
  onImageLoaded() {
    this.imageLoaded = true;
  }
  async onShowLargeImage() {
    await this.dialogService.showLargeImage(this.question);
  }
  private calculatePosition() {
    const showAllImageUtil = new ShowAllImageUtil(
      this.crosswordSize,
      this.size,
      this.matrixDirection,
      this.questionPosition,
      this.question.question,
      this.wantedImageSize,
      this.wantedImageSize + 35,
      1,
    );
    this.positionAndSize = showAllImageUtil.getImageRectangle();
  }
}
