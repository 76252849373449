import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs';
import { PriorityValue } from '../../../../../../../Common/data/priority.value';
import { CWQuestionCategory } from '../../../../../../../Common/model/cwquestion';
import { FilterForQuestionsResult } from '../../../../../../../Common/query/filter.for.questions';
import { CWQuestionService } from '../../../../services/cwquestion/cwquestion.service';
import { CrosswordConstants, QuestionPriorityConstant } from '../../../util/crossword.constants';
import { FilterQuestions } from '../models/filter.questions';

export enum SelectFilterType {
  Category = 0,
  Tag = 1,
  AnswerLength = 2,
  Priority = 3,
}
export interface SelectFilterValue {
  id: string;
  title: string;
  value: unknown;
  type: SelectFilterType;
}
export interface SelectFilterGroup {
  id: string;
  title: string;
  values: SelectFilterValue[];
  expanded: boolean;
}
export interface QuestionIds {
  ids?: number[];
  notIds?: number[];
}
@Component({
  selector: 'app-select-filter-questions',
  templateUrl: './select-filter-questions.component.html',
  styleUrls: ['./select-filter-questions.component.scss'],
})
export class SelectFilterQuestionsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() showPriorites = false;
  @Input() questionIds: QuestionIds;
  @Output() filterQuestions = new EventEmitter<FilterQuestions>();

  form = new FormControl<SelectFilterValue[]>([]);

  groups: SelectFilterGroup[] = [];

  private alive = true;
  private inited = false;
  constructor(
    private cwQuestionsService: CWQuestionService,
    private translateService: TranslateService,
  ) {}
  async ngOnChanges(changes: SimpleChanges) {
    if (changes.questionIds && this.inited) {
      await this.updateGeneralFilters();
      this.updateFormValues();
    }
  }
  ngOnDestroy(): void {
    this.alive = false;
  }
  async ngOnInit() {
    await this.updateGeneralFilters();
    this.updatePriorites();
    this.form.valueChanges.pipe(takeWhile(() => this.alive)).subscribe((values) => {
      const priorityValues: PriorityValue[] = this.getFilterTypeValues(values, SelectFilterType.Priority);
      const categoryValues: CWQuestionCategory[] = this.getFilterTypeValues(values, SelectFilterType.Category);
      const tagValues: string[] = this.getFilterTypeValues(values, SelectFilterType.Tag);
      const answerLengthValues: number[] = this.getFilterTypeValues(values, SelectFilterType.AnswerLength);
      this.filterQuestions.next({
        tags: tagValues,
        priorities: priorityValues,
        answerLengths: answerLengthValues,
        categories: categoryValues,
      });
    });
    this.inited = true;
  }
  private getFilterTypeValues<T>(values: SelectFilterValue[], type: SelectFilterType) {
    const filteredValues = values.filter((value) => value.type == type);
    return filteredValues.map((filteredValue) => filteredValue.value as T);
  }

  isGroupSelected(group: SelectFilterGroup): boolean {
    const allFormValues = new Set<SelectFilterValue>(this.form.value);
    const valueNotSelected = group.values.find((value) => !allFormValues.has(value));
    return valueNotSelected == null;
  }
  toggleSelection(group: SelectFilterGroup): void {
    const allFormValues = new Set<SelectFilterValue>(this.form.value);
    if (this.isGroupSelected(group)) {
      group.values.forEach((value) => allFormValues.delete(value));
      group.expanded = false;
    } else {
      group.values.forEach((value) => allFormValues.add(value));
      group.expanded = true;
    }
    this.form.setValue([...allFormValues.values()]);
  }
  onSelectClosed() {
    const groupIsExpaned = this.groups.find((group) => group.expanded);
    if (!groupIsExpaned) {
      this.groups[0].expanded = true;
    }
  }
  private updatePriorites() {
    if (this.showPriorites) {
      const priorityValues = CrosswordConstants.QuestionPriorities.map((priority) => this.mapPriorityValue(priority));
      if (!this.groups[SelectFilterType.Priority]) {
        this.groups[SelectFilterType.Priority] = {
          id: 'select-priority-group',
          title: this.translateService.instant('options.priority-group'),
          values: null,
          expanded: false,
        };
      }
      this.groups[SelectFilterType.Priority].values = priorityValues;
    }
  }

  private async updateGeneralFilters() {
    const filterForQuestions = await this.cwQuestionsService.getFilterForQuestions({ ...this.questionIds });
    this.updateCategories(filterForQuestions);
    this.updateTags(filterForQuestions);
    this.updateAnswerLengths(filterForQuestions);
  }
  private updateCategories(filterForQuestions: FilterForQuestionsResult) {
    const categories = filterForQuestions.categories.map((cat) => cat.category);
    const values = categories.map((cat) => this.mapCategoryValue(cat));
    if (!this.groups[SelectFilterType.Category]) {
      this.groups[SelectFilterType.Category] = {
        id: 'select-category-group',
        title: this.translateService.instant('options.category-group'),
        values: null,
        expanded: false,
      };
    }
    this.groups[SelectFilterType.Category].values = values;
  }
  private updateTags(filterForQuestions: FilterForQuestionsResult) {
    const tags = filterForQuestions.tags.map((filterTag) => filterTag.tag);
    const tagValues = tags.map((tag) => this.mapTagValue(tag));
    if (!this.groups[SelectFilterType.Tag]) {
      this.groups[SelectFilterType.Tag] = {
        id: 'select-tag-group',
        title: this.translateService.instant('options.tag-group'),
        values: null,
        expanded: false,
      };
    }
    this.groups[SelectFilterType.Tag].values = tagValues;
  }
  private updateAnswerLengths(filterForQuestions: FilterForQuestionsResult) {
    const lengths = filterForQuestions.answerLengths.map((length) => length.answerLength);
    const values = lengths.map((length) => this.mapAnswerLengthValue(length));
    if (!this.groups[SelectFilterType.AnswerLength]) {
      this.groups[SelectFilterType.AnswerLength] = {
        id: 'select-answer-length-group',
        title: this.translateService.instant('options.answer-length-group'),
        values: null,
        expanded: false,
      };
    }
    this.groups[SelectFilterType.AnswerLength].values = values;
  }
  private mapCategoryValue(category: CWQuestionCategory): SelectFilterValue {
    return {
      id: `select-category-value-${category}`,
      title: this.translateService.instant(CrosswordConstants.findCWQuestionCategoryLabel(category)),
      value: category,
      type: SelectFilterType.Category,
    };
  }
  private mapTagValue(tag: string): SelectFilterValue {
    return { id: `select-category-value-${tag}`, title: tag, value: tag, type: SelectFilterType.Tag };
  }
  private mapAnswerLengthValue(length: number): SelectFilterValue {
    return {
      id: `select-answer-length-value-${length}`,
      title: this.translateService.instant('options.answer-length-value', { length: length }),
      value: length,
      type: SelectFilterType.AnswerLength,
    };
  }
  private mapPriorityValue(priority: QuestionPriorityConstant): SelectFilterValue {
    return {
      id: `select-priority-value-${priority}`,
      title: this.translateService.instant(priority.label),
      value: priority.value,
      type: SelectFilterType.Priority,
    };
  }
  private updateFormValues() {
    if (!this.form) {
      return;
    }
    const currentValues = this.form.value;
    const allFilterValues = this.groups.flatMap((value) => value.values);
    const select = allFilterValues.filter((value) => this.hasValue(currentValues, value));
    const emitEvent = currentValues.length != select.length;
    this.form.setValue(select, { emitEvent: emitEvent });
  }
  private hasValue(values: SelectFilterValue[], value: SelectFilterValue) {
    return values.find((valueTmp) => valueTmp.type == value.type && valueTmp.value == value.value);
  }
}
