import { ReMatrix } from '../../../../../../Common/builder/util/re.matrix';
import { Interval } from '../../../../../../Common/data/interval';
import { Direction, Position } from '../../../../../../Common/data/position';
import { ICrossword } from '../../../../../../Common/model/crossword';
import { CrosswordTile, QuestionWithCrosswordTiles, TilesState, TileType } from '../../../models/tile';

export class HandleCrosswordTileUtil {
  public static getRowTilesFromMatrixTile(matrixTile: CrosswordTile, matchedRowMatrixTiles: Map<CrosswordTile, CrosswordTile>) {
    const rowTiles: CrosswordTile[] = [];
    for (const [rowTile, matrixTileTmp] of matchedRowMatrixTiles) {
      if (matrixTile == matrixTileTmp) {
        rowTiles.push(rowTile);
      }
    }
    return rowTiles;
  }
  public static getFocusedRowTilesStateFromState(
    tilesState: TilesState,
    focusedRowTiles: Set<CrosswordTile>,
    rowTiles: QuestionWithCrosswordTiles[],
  ) {
    const questionWithCrosswordTiles = rowTiles.find((rowTile) => rowTile.cwQuestion.indexId == tilesState.focused.cwQuestion.indexId);
    const focusedTile = questionWithCrosswordTiles.tiles.find((questionTile) => focusedRowTiles.has(questionTile));
    const interval = new Interval(0, focusedTile.y, Direction.MAINAXIS, questionWithCrosswordTiles.tiles.length);
    return {
      ...tilesState,
      focused: { tile: focusedTile, cwQuestion: questionWithCrosswordTiles.cwQuestion, interval: interval },
    };
  }
  public static setTilesAsAddCrosswordTiles(tiles: CrosswordTile[][], crossword: ICrossword) {
    const matrix = ReMatrix.createFromCrossword(crossword);
    const openIntervals = matrix.getOpenIntervals();
    for (let x = 0; x < tiles.length; x++) {
      for (let y = 0; y < tiles[x].length; y++) {
        const tile = tiles[x][y];
        if (tile.type == TileType.Empty) {
          const hasOpenInterval = openIntervals.find(
            (i) => i.interval.equal(new Position(x, y, Direction.MAINAXIS)) || i.interval.equal(new Position(x, y, Direction.CROSSAXIS)),
          );
          if (hasOpenInterval) {
            tile.type = TileType.AddQuestion;
          }
        }
      }
    }
  }
  public static setQuestionTilesAsShowPriority(tiles: CrosswordTile[][], crossword: ICrossword) {
    for (let x = 0; x < tiles.length; x++) {
      for (let y = 0; y < tiles[x].length; y++) {
        const tile = tiles[x][y];
        if (tile.type == TileType.Question) {
          tile.type = TileType.PriorityQuestion;
        }
      }
    }
  }
  public static getFocusedRowStateFromFocusedQuestion(tilesState: TilesState, rowTiles: QuestionWithCrosswordTiles[]): TilesState {
    const rowTile = rowTiles.find((rowTile) => rowTile.cwQuestion == tilesState.focused.cwQuestion);
    if (rowTile) {
      const interval = new Interval(0, rowTile.tiles[0].y, Direction.MAINAXIS, rowTile.tiles.length);
      return { ...tilesState, focused: { tile: null, interval: interval, cwQuestion: tilesState.focused.cwQuestion } };
    } else {
      return { ...tilesState, focused: { tile: null, interval: null, cwQuestion: null } };
    }
  }
}
