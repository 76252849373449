<ng-container *ngIf="crosswordGame">
  <div fxLayout="column" fxLayoutGap="10px">
    <app-show-crossword [handleCrosswordView]="gameHandleCrosswordView"> </app-show-crossword>
    <div fxLayout="column" fxLayoutGap="10px" style="z-index: 3">
      <mat-card>
        <app-crossword-game-information id="crossword-game-information" [crosswordGame]="crosswordGame"></app-crossword-game-information>
      </mat-card>
      <mat-expansion-panel *ngIf="crosswordGame.playersInfo.players.length > 1" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ 'panel-titles.chat' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <app-crossword-chat [crosswordGame]="crosswordGame"></app-crossword-chat>
      </mat-expansion-panel>
      <mat-expansion-panel
        *ngIf="crosswordGame.global.state == GameState.Ended && crosswordGame.initial.crossword.type == CrosswordType.Built"
        [expanded]="true"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>{{ 'panel-titles.crossword-information' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <app-crossword-information
          id="crossword-information"
          [crosswordId]="crosswordGame.initial.crossword._id"
        ></app-crossword-information>
      </mat-expansion-panel>
    </div>
  </div>
</ng-container>
<app-progress-bar *ngIf="!crosswordGame"></app-progress-bar>
