import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICWQuestionBase } from '../../../../../../../Common/model/cwquestion';
import { ImageService } from '../../../../services/image/image.service';

export interface ShowLargeImageData {
  question: ICWQuestionBase;
}

@Component({
  selector: 'app-show-large-image',
  templateUrl: './show-large-image.component.html',
  styleUrls: ['show-large-image.component.scss'],
})
export class ShowLargeImageComponent {
  imageUrl: string | null;
  imageLoaded = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShowLargeImageData,
    private matDialogRef: MatDialogRef<ShowLargeImageComponent>,
    private imageService: ImageService,
  ) {
    this.imageUrl = this.imageService.getImageUrl(this.data.question.imageId);
    this.imageLoaded = false;
  }

  onImageLoaded() {
    this.imageLoaded = true;
  }

  onClose() {
    this.matDialogRef.close();
  }
}
