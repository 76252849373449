import { GenerateCrosswordData } from '../../data/generate.crossword.data';
import { ReMatchPositionAndQuestion } from '../util/re.match.position.for.question';
import { ReMatrix } from '../util/re.matrix';
import { ReRemoveQuestions } from '../util/re.remove.questions';
import { ReCrosswordBuildAction } from './re.build.action';

export class ReRepositionQuestionsToBetterPositions implements ReCrosswordBuildAction {
  constructor(
    private readonly settings: GenerateCrosswordData,
    private readonly matchPositionAndQuestion: ReMatchPositionAndQuestion,
    private readonly removeNumberOfQuestions: ReRemoveQuestions
  ) {}
  run(cmatrixOrg: ReMatrix) {
    const numberOfQuestions = this.settings.accuracy.repositionQuestions;
    const cmatrix = cmatrixOrg.copy();
    const questionsToFit = this.removeNumberOfQuestions.remove(numberOfQuestions, cmatrix, true);
    let intervals = cmatrix.getOpenIntervals();
    for (const questionToFit of questionsToFit) {
      const qp = this.matchPositionAndQuestion.getPositionForQuestion(questionToFit, intervals);
      if (qp) {
        cmatrix.addQuestion(qp.position, qp.question);
        intervals = cmatrix.getOpenIntervals();
      }
    }
    return cmatrix.getScore().greatherThen(cmatrixOrg.getScore()) ? cmatrix : cmatrixOrg;
  }
}
