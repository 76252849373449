import { GenerateCrosswordData } from '../../data/generate.crossword.data';
import { PriorityValue } from '../../data/priority.value';
import { ReMatrix } from './re.matrix';
import { RePositionAndQuestion } from './re.position.and.question';
import { ReQuestionsContainer } from './re.questions.container';

export class ReAddQuestionsToPosition {
  constructor(
    private readonly settings: GenerateCrosswordData,
    private readonly positionAndQuestion: RePositionAndQuestion,
    private readonly questionsContainer: ReQuestionsContainer
  ) {}
  public addQuestionsToFreePositions(matrix: ReMatrix, addQuestionsFrom: PriorityValue) {
    const questionsToFit = this.getQuestionsToFit(addQuestionsFrom, matrix);
    const numberOfQuestions = this.getNumberOfQuestions(addQuestionsFrom);
    for (let i = 0; i < numberOfQuestions; i++) {
      const openIntervals = matrix.getOpenIntervals();
      const positionAndData = this.positionAndQuestion.getPositionAndQuestion(questionsToFit, openIntervals);
      if (!positionAndData) {
        break;
      }
      questionsToFit.splice(questionsToFit.indexOf(positionAndData.question), 1);
      matrix.addQuestion(positionAndData.position, positionAndData.question);
    }
    return matrix;
  }
  private getQuestionsToFit(addQuestionsFrom: PriorityValue, matrix: ReMatrix) {
    return this.questionsContainer.getQuestionsToFit(matrix, addQuestionsFrom, this.settings.accuracy.addQuestions.questionsCountPerRound);
  }
  private getNumberOfQuestions(addQuestionsFrom: PriorityValue) {
    const addQuestionSettings = this.settings.accuracy.addQuestions;
    return addQuestionsFrom != PriorityValue.Theme ? addQuestionSettings.random : addQuestionSettings.theme;
  }
}
