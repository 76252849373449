export enum FlexibleButtonType {
  Standard,
  Link,
}
export interface StandardButtonAction {
  event: () => void;
}
export interface LinkButtonLink {
  href: string;
}
export interface FlexibleButtonInMenu {
  id: string;
  text: string;
  badgeText?: string;
  icon?: string;
  visible?: boolean;
  disabled?: boolean;
  menuOnly?: boolean;
  type: FlexibleButtonType;
  standardAction?: StandardButtonAction;
  link?: LinkButtonLink;
}
