export class PackageVersionUtil {
  static parse(packageVersion: string) {
    const values = packageVersion.split('.');
    return { major: Number.parseInt(values[0]), minor: Number.parseInt(values[1]), patch: Number.parseInt(values[2]) } as PackageVersion;
  }
  static equal(packageVersion1: PackageVersion, packageVersion2: PackageVersion) {
    return (
      packageVersion1 != null &&
      packageVersion2 != null &&
      packageVersion1.major == packageVersion2.major &&
      packageVersion1.minor == packageVersion2.minor &&
      packageVersion1.patch == packageVersion2.patch
    );
  }
}

export interface PackageVersion {
  major: number;
  minor: number;
  patch: number;
}
