import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CrosswordListItem, ICrossword } from '../../../../../Common/model/crossword';
import { AppSettingsService } from '../appsettings/app-settings.service';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class CrosswordService {
  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
    private appSettingsService: AppSettingsService,
  ) {}
  async getCrosswordListItems() {
    const routes = this.configService.getRoutes();

    return await lastValueFrom(
      this.httpClient.get<CrosswordListItem[]>(`${this.appSettingsService.appSettings.apiurl}${routes.crossword}/crosswordListItems`),
    );
  }
  async getCrossword(id: string) {
    const routes = this.configService.getRoutes();
    return await lastValueFrom(
      this.httpClient.get<ICrossword>(this.appSettingsService.appSettings.apiurl + `${routes.crossword}/byId/${id}`),
    );
  }
  async getRandomCaptchaCrossword() {
    const routes = this.configService.getRoutes();
    return await lastValueFrom(
      this.httpClient.get<ICrossword>(this.appSettingsService.appSettings.apiurl + `${routes.crossword}/randomCaptchaCrossword`),
    );
  }
  async getRandomCrosswordIds() {
    const routes = this.configService.getRoutes();
    return await lastValueFrom(
      this.httpClient.get<string[]>(this.appSettingsService.appSettings.apiurl + `${routes.crossword}/randomCrosswordIds`),
    );
  }
}
