import { PriorityValue } from '../../data/priority.value';
import { ReAddQuestionsToPosition } from '../util/re.add.questions.to.position';
import { ReMatrix } from '../util/re.matrix';
import { ReCrosswordBuildAction } from './re.build.action';

export class ReAddQuestions implements ReCrosswordBuildAction {
  constructor(private readonly addQuestionsToPosition: ReAddQuestionsToPosition, private readonly addQuestionsFrom: PriorityValue) {}
  run(matrix: ReMatrix) {
    return this.addQuestionsToPosition.addQuestionsToFreePositions(matrix, this.addQuestionsFrom);
  }
}
