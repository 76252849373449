import { RandomizeUtil } from '../builder/util/randomize.util';

export class IdUtil {
  static filterIds<T>(ids: T[], filter: T[]) {
    const filterSet = new Set<T>(filter);
    return ids.filter((id) => !filterSet.has(id));
  }
  static removeFromArray(ids: any[], id: any) {
    const index = this.findIndex(ids, id);
    if (index >= 0) {
      ids.splice(index, 1);
    }
  }
  static updateItemInArray(ids: any[], id: any) {
    const index = this.findIndex(ids, id);
    if (index >= 0) {
      ids[index] = id;
    }
  }
  static addOrUpdateItemInArray(ids: any[], id: any) {
    const index = this.findIndex(ids, id);
    if (index >= 0) {
      ids[index] = id;
    } else {
      ids.push(id);
    }
  }
  public static containsIndex(ids: any[], id: any) {
    return this.findIndex(ids, id) >= 0;
  }
  private static findIndex(ids: any[], id: any) {
    return ids.findIndex((idTmp) => this.idEquals(idTmp, id));
  }
  static idEquals(id1: any, id2: any) {
    if (id1 == null && id2 == null) {
      return true;
    }
    if (id1 == null || id2 == null) {
      return false;
    }
    const id1Tmp = id1.toString != null ? id1.toString() : id1;
    const id2Tmp = id2.toString != null ? id2.toString() : id2;
    return id1Tmp == id2Tmp;
  }
  static getSimpleNewId(): string {
    return `${RandomizeUtil.getRandomNumber(100000, 999999)}.${RandomizeUtil.getRandomNumber(
      100000,
      999999
    )}.${RandomizeUtil.getRandomNumber(100000, 999999)}`;
  }
}
