import { CrosswordGameNotification, GameNotificationType } from '../data/crossword.game.notification';
import { GameType } from '../data/game.type';
import { ILocalizationText } from '../localization/localization.text';
import { IPlayer } from '../model/player';
import { NotificationUtil } from './notification.util';
export interface RemoveQuestionData {
  question: string;
}
export interface AddedQuestionData {
  question: string;
  score: number;
}
export interface ChatMessageData {
  message: string;
}
export interface ChangedUserMessageData {
  changedTo: IPlayer;
}
export interface ScoreAndCharsMessageData {
  count: number;
  score: number;
  extraScore: {
    chars: number;
    completeWords: number;
  };
  charsLayed: number;
}
export interface ScoreMessageData {
  score: number;
  percentageCorrect: number;
  manuallyEnded: boolean;
}
export interface NotificationTextParams {
  player: IPlayer;
  localizationText: ILocalizationText;
  crosswordGameNotification: CrosswordGameNotification;
  gameType: GameType;
}
interface GameNotificationText {
  getText(params: NotificationTextParams);
}
class CurrentPlayerChangedMessage implements GameNotificationText {
  getText(params: NotificationTextParams) {
    if (NotificationUtil.isNotificationPlayer(params.crosswordGameNotification, params.player)) {
      return params.localizationText.getTranslation('me-current-player-changed');
    } else {
      return params.localizationText.getTranslation('others-current-player-changed', {
        player: params.crosswordGameNotification.playerName,
      });
    }
  }
}
class PlayerTimedoutMessage implements GameNotificationText {
  getText(params: NotificationTextParams) {
    if (NotificationUtil.isNotificationPlayer(params.crosswordGameNotification, params.player)) {
      return params.localizationText.getTranslation('me-player-timedout');
    } else {
      return params.localizationText.getTranslation('others-player-timedout', { player: params.crosswordGameNotification.playerName });
    }
  }
}
class RemindPlayerMessage implements GameNotificationText {
  getText(params: NotificationTextParams) {
    if (NotificationUtil.isNotificationPlayer(params.crosswordGameNotification, params.player)) {
      return params.localizationText.getTranslation('me-remind-player');
    }
  }
}
class AddChatMessage implements GameNotificationText {
  getText(params: NotificationTextParams) {
    const chatMessageData: ChatMessageData = params.crosswordGameNotification.messageData;
    if (!NotificationUtil.isNotificationPlayer(params.crosswordGameNotification, params.player)) {
      return params.localizationText.getTranslation('player-wrote-in-chat', {
        player: params.crosswordGameNotification.playerName,
        message: chatMessageData.message,
      });
    }
  }
}
class GameJoinedMessage implements GameNotificationText {
  getText(params: NotificationTextParams) {
    if (!NotificationUtil.isNotificationPlayer(params.crosswordGameNotification, params.player)) {
      return params.localizationText.getTranslation('others-player-joined-game', {
        player: params.crosswordGameNotification.playerName,
      });
    }
  }
}
class GameLeftMessage implements GameNotificationText {
  getText(params: NotificationTextParams) {
    if (!NotificationUtil.isNotificationPlayer(params.crosswordGameNotification, params.player)) {
      return params.localizationText.getTranslation('others-player-left-game', {
        player: params.crosswordGameNotification.playerName,
      });
    }
  }
}
class GameStartedMessage implements GameNotificationText {
  getText(params: NotificationTextParams) {
    return params.localizationText.getTranslation('game-started');
  }
}
class GameCompletedMessage implements GameNotificationText {
  getText(params: NotificationTextParams) {
    const gameCompleteMessageData: ScoreMessageData = params.crosswordGameNotification.messageData;
    if (params.gameType == GameType.Match) {
      if (NotificationUtil.isNotificationPlayer(params.crosswordGameNotification, params.player)) {
        return this.getMessageForMeWinningGame(params, gameCompleteMessageData);
      } else {
        return this.getTextForOtherWinningGame(params, gameCompleteMessageData);
      }
    } else {
      return this.getMessageForMeCompletingPlay(params, gameCompleteMessageData);
    }
  }

  private getMessageForMeCompletingPlay(params: NotificationTextParams, gameCompleteMessageData: ScoreMessageData) {
    const key = gameCompleteMessageData.manuallyEnded ? 'game-manually-completed-single' : 'game-completed-single';
    return params.localizationText.getTranslation(key, gameCompleteMessageData);
  }

  private getTextForOtherWinningGame(params: NotificationTextParams, gameCompleteMessageData: ScoreMessageData) {
    const key = gameCompleteMessageData.manuallyEnded ? 'others-game-manually-completed-match' : 'others-game-completed-match';
    return params.localizationText.getTranslation(key, {
      player: params.crosswordGameNotification.playerName,
      ...gameCompleteMessageData,
    });
  }

  private getMessageForMeWinningGame(params: NotificationTextParams, gameCompleteMessageData: ScoreMessageData) {
    const key = gameCompleteMessageData.manuallyEnded ? 'me-game-manually-completed-match' : 'me-game-completed-match';
    return params.localizationText.getTranslation(key, gameCompleteMessageData);
  }
}
class PassTurnMessage implements GameNotificationText {
  getText(params: NotificationTextParams) {
    if (NotificationUtil.isNotificationPlayer(params.crosswordGameNotification, params.player)) {
      return params.localizationText.getTranslation('me-pass-turn');
    } else {
      return params.localizationText.getTranslation('others-pass-turn', {
        player: params.crosswordGameNotification.playerName,
      });
    }
  }
}
class CharsAddedMessage implements GameNotificationText {
  getText(params: NotificationTextParams) {
    const charsAddedChatMessage: ScoreAndCharsMessageData = params.crosswordGameNotification.messageData;
    if (params.gameType == GameType.Match) {
      if (charsAddedChatMessage.count > 0) {
        if (NotificationUtil.isNotificationPlayer(params.crosswordGameNotification, params.player)) {
          return this.getMeCorrectCharsAdded(params, charsAddedChatMessage);
        } else {
          return params.localizationText.getTranslation('others-chars-added-game', {
            ...charsAddedChatMessage,
            player: params.crosswordGameNotification.playerName,
          });
        }
      } else {
        if (NotificationUtil.isNotificationPlayer(params.crosswordGameNotification, params.player)) {
          return this.getTextWhenNoCorrectCharsWhereLayed(params);
        } else {
          return params.localizationText.getTranslation('others-no-correct-chars-added-game', {
            player: params.crosswordGameNotification.playerName,
          });
        }
      }
    } else {
      if (charsAddedChatMessage.count > 0) {
        return this.getMeCorrectCharsAdded(params, charsAddedChatMessage);
      } else {
        return this.getTextWhenNoCorrectCharsWhereLayed(params);
      }
    }
  }
  private getMeCorrectCharsAdded(params: NotificationTextParams, charsAddedChatMessage: ScoreAndCharsMessageData) {
    return (
      params.localizationText.getTranslation('me-chars-added-game', charsAddedChatMessage) +
      this.getExtraScoreText(params.localizationText, charsAddedChatMessage)
    );
  }

  private getTextWhenNoCorrectCharsWhereLayed(params: NotificationTextParams) {
    const charsAddedChatMessage: ScoreAndCharsMessageData = params.crosswordGameNotification.messageData;
    if (charsAddedChatMessage.charsLayed > 0) {
      return params.localizationText.getTranslation('me-no-correct-chars-added-game');
    } else {
      return params.localizationText.getTranslation('me-no-chars-added-game');
    }
  }

  private getExtraScoreText(localizationText: ILocalizationText, charsAddedChatMessage: ScoreAndCharsMessageData) {
    let extraText = '';
    if (charsAddedChatMessage.extraScore != null) {
      if (charsAddedChatMessage.extraScore.chars > 0) {
        extraText += localizationText.getTranslation('me-chars-added-game-extra-chars', charsAddedChatMessage);
      }
      if (charsAddedChatMessage.extraScore.completeWords > 0) {
        extraText += localizationText.getTranslation('me-chars-added-game-extra-complete-words', charsAddedChatMessage);
      }
    }
    return extraText;
  }
}
export class GameNotificationTextUtil {
  private static gameNotificationText = new Map<GameNotificationType, GameNotificationText>([
    [GameNotificationType.CharsAdded, new CharsAddedMessage()],
    [GameNotificationType.PassedTurn, new PassTurnMessage()],
    [GameNotificationType.GameCompleted, new GameCompletedMessage()],
    [GameNotificationType.GameStarted, new GameStartedMessage()],
    [GameNotificationType.PlayerJoined, new GameJoinedMessage()],
    [GameNotificationType.PlayerLeft, new GameLeftMessage()],
    [GameNotificationType.AddedChatMessage, new AddChatMessage()],
    [GameNotificationType.RemindPlayer, new RemindPlayerMessage()],
    [GameNotificationType.PlayerTimedOut, new PlayerTimedoutMessage()],
    [GameNotificationType.CurrentPlayerChanged, new CurrentPlayerChangedMessage()],
  ]);
  static getText(getTextData: NotificationTextParams) {
    const gameNotificationText = this.gameNotificationText.get(getTextData.crosswordGameNotification.type);
    if (!gameNotificationText) {
      throw new Error('Unsupported gameNotification type:' + getTextData.crosswordGameNotification.type);
    }
    return gameNotificationText.getText(getTextData);
  }
}
