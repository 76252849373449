import { Component } from '@angular/core';

export enum AskDirection {
  Cancel,
  Side,
  Down,
}

@Component({
  templateUrl: './ask-direction-message-box.component.html',
})
export class AskDirectionMessageBoxComponent {
  AskDirection = AskDirection;
  constructor() {}
}
