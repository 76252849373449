import { AccuracyParameters } from '../../data/generate.crossword.data';
import { ReMatrix } from '../util/re.matrix';
import { ReRemoveQuestions } from '../util/re.remove.questions';
import { ReCrosswordBuildAction } from './re.build.action';

export class ReRemoveQuestionsForBetterFit implements ReCrosswordBuildAction {
  constructor(
    private readonly accuracy: AccuracyParameters,
    private removeQuestions: ReRemoveQuestions,
    private removeThemeQuestions: boolean
  ) {}
  run(matrix: ReMatrix) {
    const percentage = this.accuracy.removePercent;
    const numberOfQuestionsToRemove = Math.round(matrix.getQuestions().length * (percentage / 100));
    this.removeQuestions.remove(numberOfQuestionsToRemove, matrix, this.removeThemeQuestions);
    return matrix;
  }
}
