import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { UserCodeComponent, UserCodeSettings } from '../number-code/number-code.component';

@Injectable({
  providedIn: 'root',
})
export class UserCodeDialogService {
  constructor(private matDialog: MatDialog) {}

  async showAskUserCode<T>(data: UserCodeSettings) {
    const result: T = await lastValueFrom(this.matDialog.open(UserCodeComponent, { data: data }).afterClosed());
    return result;
  }
}
