<h4 mat-dialog-title style="text-align: center">
  {{ (editQuestionData.cwQuestion._id ? 'titles.update-question' : 'titles.create-question') | translate }}
</h4>
<form [formGroup]="cwQuestionForm" novalidate>
  <mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="row" fxLayoutGap="5px" fxlayoutAlign="center center">
        <mat-form-field fxFlex test-id="enter-question-form-field">
          <mat-label>{{ 'labels.enter-question' | translate }}</mat-label>
          <input id="enter-question" matInput formControlName="question" autocomplete="off" />
          <mat-error *ngIf="cwQuestionForm.controls.question.hasError('required')">{{ 'errors.question-missing' | translate }}</mat-error>
          <mat-error *ngIf="cwQuestionForm.controls.question.hasError('maxlength')">{{ 'errors.question-to-long' | translate }}</mat-error>
        </mat-form-field>
        <div *ngIf="cwQuestionForm.controls.imageUrl.value?.length > 0" class="image-container" (click)="onAddOrEditImage()">
          <img fxFlexFill [src]="cwQuestionForm.controls.imageUrl.value" [alt]="'errors.missing-image' | translate" />
        </div>
      </div>
      <div style="padding-bottom: 10px">
        <span>{{ 'labels.enter-answer' | translate }}</span>
        <app-edit-question-tiles [editQuestionData]="editQuestionTiles" [answer]="cwQuestionForm.controls.answer"></app-edit-question-tiles>
        <mat-error *ngIf="cwQuestionForm.controls.answer.hasError('required')">{{ 'errors.answer-missing' | translate }}</mat-error>
        <mat-error *ngIf="cwQuestionForm.controls.answer.hasError('maxlength')">{{ 'errors.answer-to-long' | translate }}</mat-error>
        <mat-error *ngIf="cwQuestionForm.controls.answer.hasError('pattern') && !cwQuestionForm.controls.answer.hasError('required')">{{
          'errors.answer-characters' | translate
        }}</mat-error>
        <mat-error
          *ngIf="cwQuestionForm.controls.answer.hasError('notOneOfFilterLengths') && !cwQuestionForm.controls.answer.hasError('required')"
          >{{ 'errors.answer-not-one-of-filter-lengths' | translate }}</mat-error
        >
      </div>
      <mat-form-field>
        <mat-label>{{ 'labels.cwquestion-category' | translate }}</mat-label>
        <mat-select formControlName="category" test-id="select-category">
          <mat-option *ngFor="let category of CWQuestionCategories" test-id="select-category-option" [value]="category.value">
            {{ category.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ 'panel-titles.extra-question-data' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column" fxLayoutGap="5px">
          <mat-form-field>
            <mat-label>{{ 'labels.difficulty' | translate }}</mat-label>
            <mat-select formControlName="difficulty">
              <mat-option *ngFor="let difficulty of Difficulties" [value]="difficulty.value">
                {{ difficulty.label | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <app-cwquestion-tags [tags]="cwQuestionForm.controls.tags" [tagInput]="cwQuestionForm.controls.tagInput"></app-cwquestion-tags>
          <mat-form-field>
            <mat-label>{{ 'labels.cwquestion-visiblity' | translate }}</mat-label>
            <mat-select formControlName="visibility">
              <mat-option *ngFor="let visibility of CWQuestionVisiblities" [value]="visibility.value">
                {{ visibility.label | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row">
    <button test-id="add-edit-image-question" mat-raised-button (click)="onAddOrEditImage()">
      <mat-icon>image</mat-icon>{{ 'buttons.image-question' | translate }}
    </button>
    <div fxFlex fxLayout="row" fxLayoutAlign="end">
      <button id="save-question" mat-raised-button (click)="onSaveQuestion()" [disabled]="!cwQuestionForm.valid">
        {{ (editQuestionData.cwQuestion._id ? 'buttons.save' : 'buttons.create') | translate }}
      </button>
      <button mat-raised-button (click)="onCancel()">{{ 'buttons.cancel' | translate }}</button>
    </div>
  </mat-dialog-actions>
</form>
