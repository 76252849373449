<mat-form-field fxFlex>
  <mat-label>{{ 'options.select-filter' | translate }}</mat-label>
  <mat-select id="select-filter-questions" [formControl]="form" multiple>
    <mat-select-trigger>
      {{ form.value?.length > 0 ? form.value[0].title : '' }}
      <span *ngIf="form.value?.length > 1" class="additional-selection"
        >{{ 'options.additional-selection' | translate: { count: form.value.length - 1 } }}
      </span>
    </mat-select-trigger>

    <mat-optgroup *ngFor="let group of groups" [id]="group.id">
      <div>
        <mat-checkbox [checked]="isGroupSelected(group)" (change)="toggleSelection(group)" (click)="$event.stopPropagation()">
          {{ group.title }}
        </mat-checkbox>
        <button [id]="group.id + '-expand'" mat-button (click)="group.expanded = !group.expanded">
          <mat-icon>{{ group.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
        </button>
      </div>
      <ng-container *ngIf="group.expanded">
        <mat-option *ngFor="let value of group.values" [id]="value.id" [value]="value" class="option">
          {{ value.title }}
        </mat-option>
      </ng-container>
    </mat-optgroup>
    <mat-option [style.display]="'none'"></mat-option>
  </mat-select>
</mat-form-field>
