import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CrosswordGameNotification } from '../../../../../Common/data/crossword.game.notification';
import { GameType } from '../../../../../Common/data/game.type';
import { ILocalizationText } from '../../../../../Common/localization/localization.text';
import { IPlayer } from '../../../../../Common/model/player';
import { GameNotificationTextUtil } from '../../../../../Common/util/game.notification.text.util';

class LocalizationText implements ILocalizationText {
  constructor(private translateService: TranslateService) {}

  getTranslation(key: string, obj?: any) {
    return this.translateService.instant(`game-notifications.${key}`, obj);
  }
}

@Injectable({
  providedIn: 'root',
})
export class GameNotificationTextService {
  constructor(private translateService: TranslateService) {}
  getTitleForNotification(crosswordName: string) {
    const localizationText = new LocalizationText(this.translateService);
    return localizationText.getTranslation('title', { titleEnd: crosswordName });
  }
  getTextForNotification(player: IPlayer, crosswordGameNotification: CrosswordGameNotification, gameType: GameType) {
    const localizationText = new LocalizationText(this.translateService);
    return GameNotificationTextUtil.getText({
      crosswordGameNotification: crosswordGameNotification,
      player: player,
      gameType: gameType,
      localizationText: localizationText,
    });
  }
}
