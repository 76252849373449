import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CachedList } from '../../../../../Common/builder/util/cached.list';
import { CachedMap } from '../../../../../Common/builder/util/cached.map';
import { CWQuestionExists } from '../../../../../Common/data/cwquestion.exists';
import { ICWQuestion, ICWQuestionBase } from '../../../../../Common/model/cwquestion';
import { FilterForQuestionsQuery, FilterForQuestionsResult } from '../../../../../Common/query/filter.for.questions';
import { SearchQuestionsQuery, SearchQuestionsResult } from '../../../../../Common/query/search.question.query';
import { AppSettingsService } from '../appsettings/app-settings.service';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class CWQuestionService {
  private readonly cachedTags: CachedList<string>;
  private readonly cachedIndexBaseQuestions: CachedMap<number, ICWQuestionBase>;
  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
    private appSettingsService: AppSettingsService,
  ) {
    this.cachedTags = new CachedList<string>(300 * 1000);
    this.cachedIndexBaseQuestions = new CachedMap<number, ICWQuestionBase>(600 * 1000);
  }

  async createCWQuestion(cwQuestion: ICWQuestion) {
    this.cachedTags.clear();
    return await lastValueFrom(this.httpClient.put<ICWQuestion>(this.getCWQuestionRoute(), cwQuestion));
  }
  async createCWQuestions(cwQuestions: ICWQuestion[]) {
    return await lastValueFrom(this.httpClient.post<ICWQuestion[]>(`${this.getCWQuestionRoute()}/createCWQuestions`, cwQuestions));
  }
  async updateCWQuestion(cwQuestion: ICWQuestion) {
    this.cachedIndexBaseQuestions.remove(cwQuestion.base.indexId);
    this.cachedTags.clear();
    return await lastValueFrom(this.httpClient.post<ICWQuestion>(this.getCWQuestionRoute(), cwQuestion));
  }
  async doesQuestionsAndAnswersExist(cwQuestion: ICWQuestionBase[]) {
    return await lastValueFrom(
      this.httpClient.post<CWQuestionExists[]>(`${this.getCWQuestionRoute()}/doesQuestionsAndAnswersExist`, cwQuestion),
    );
  }
  async doesQuestionAndAnswerExist(cwQuestion: ICWQuestionBase) {
    return await lastValueFrom(this.httpClient.post<boolean>(`${this.getCWQuestionRoute()}/doesQuestionAndAnswerExist`, cwQuestion));
  }
  async deleteCWQuestion(cwQuestion: ICWQuestion) {
    this.cachedTags.clear();
    return await lastValueFrom(this.httpClient.delete<ICWQuestion>(`${this.getCWQuestionRoute()}/${cwQuestion._id}`));
  }
  async getTags() {
    if (this.cachedTags.hasExpired()) {
      const result = await this.getFilterForQuestions({});
      this.cachedTags.addItems(result.tags.map((tag) => tag.tag));
    }
    return this.cachedTags.getItems();
  }
  async getFilterForQuestions(filterForQuestions: FilterForQuestionsQuery) {
    return await lastValueFrom(
      this.httpClient.post<FilterForQuestionsResult>(`${this.getCWQuestionRoute()}/filterForQuestions/`, filterForQuestions),
    );
  }
  async loadAllQuestions() {
    return await lastValueFrom(this.httpClient.get<ICWQuestion[]>(this.getCWQuestionRoute()));
  }
  async loadQuestionsWithIndexIds(indexIds: number[]) {
    const map = this.cachedIndexBaseQuestions.getMap();
    const uncachedIndexIds = indexIds.filter((i) => !map.has(i));
    const questions = await lastValueFrom(
      this.httpClient.post<ICWQuestionBase[]>(`${this.getCWQuestionRoute()}/questionsWithIndexIds/`, uncachedIndexIds),
    );
    questions.forEach((q) => map.set(q.indexId, q));
    return indexIds.map((i) => map.get(i)).filter((q) => q != null);
  }
  async loadQuestions(searchQuestionsQuery: SearchQuestionsQuery) {
    return await lastValueFrom(this.httpClient.post<SearchQuestionsResult>(`${this.getCWQuestionRoute()}/search/`, searchQuestionsQuery));
  }
  private getCWQuestionRoute() {
    const routes = this.configService.getRoutes();
    return `${this.appSettingsService.appSettings.apiurl}${routes.cwquestion}`;
  }
}
