import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, take } from 'rxjs';
import { GameState } from '../../../../../Common/data/game.state';
import { GameType } from '../../../../../Common/data/game.type';
import { CrosswordGameStats } from '../../../../../Common/model/crossword.game.stats';
import { IdUtil } from '../../../../../Common/util/id.util';
import { ComponentCommunicationService } from '../../services/componentcommunication/component-communication.service';
import { CrosswordGameStatsService } from '../../services/crosswordgamestats/crossword.game.stats.service';
import { UserService } from '../../services/user/user.service';
import { LocalizedPaginatorIntl } from '../build-crossword/select-cwquestions/localized.paginator';
import { SizeChanged } from '../util/resize.directive';
import { SortColumn, SortColumnsUtil, SortOn, compare } from '../util/sort.util';
import { WaitUtil } from '../util/wait.util';

export enum ListCrosswordGameTypes {
  Ongoing,
  Complete,
}

export interface ListCrosswordGamesData {
  type: ListCrosswordGameTypes;
}

interface CrosswordGameStatsData {
  playerCount: number;
  name: string;
  date: Date;
  currentTurnPlayerName: string;
  completedPercentage: number;
  hovered?: boolean;
  crosswordGameStats: CrosswordGameStats;
  score: number;
}

@Component({
  selector: 'app-list-crossword-games',
  templateUrl: './list-crossword-games.component.html',
  styleUrls: ['./list-crossword-games.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: LocalizedPaginatorIntl }],
})
export class ListCrosswordGamesComponent implements OnInit {
  GameType = GameType;

  columns: string[];
  columnsForSmallWidth: string[];
  columnsForLargeWidth: string[];
  dataSource = new MatTableDataSource<CrosswordGameStatsData>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  loaded = false;
  private currentSort: SortOn = { columnId: 'date', direction: 'desc' };
  private sortUtil: SortColumnsUtil<CrosswordGameStatsData>;
  private listCrosswordGamesType: ListCrosswordGameTypes;
  constructor(
    private userService: UserService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    protected crosswordGameStatsService: CrosswordGameStatsService,
    protected componentCommunication: ComponentCommunicationService,
    protected translateService: TranslateService,
  ) {}
  async ngOnInit() {
    const data: ListCrosswordGamesData = (await lastValueFrom(this.activeRoute.data.pipe(take(1)))) as ListCrosswordGamesData;
    this.listCrosswordGamesType = data.type;
    const crosswordGameStats = await this.crosswordGameStatsService.getPlayerStats(this.userService.getLoggedIn()._id);

    const crosswordGameStatsToShow = this.getCrosswordGameStatsToShow(crosswordGameStats);
    this.dataSource.data = crosswordGameStatsToShow.map((c) => this.getCrosswordGameData(c));

    this.setTitle();
    this.initializeColumns();
    this.populateSortItems();
    this.applySort();
    WaitUtil.waitUntilSet(() => this.paginator).then(() => (this.dataSource.paginator = this.paginator));

    this.loaded = true;
  }

  onSortChange(sort: Sort) {
    this.currentSort.columnId = sort.active;
    this.currentSort.direction = sort.direction;
    this.applySort();
    this.paginator?.firstPage();
  }
  async applyFilter(filter: string) {
    this.dataSource.filter = filter;
  }
  private applySort() {
    this.dataSource.data = this.sortUtil.sortItem(this.currentSort, this.dataSource.data);
  }
  protected initializeColumns() {
    if (this.listCrosswordGamesType == ListCrosswordGameTypes.Ongoing) {
      this.columnsForSmallWidth = ['type', 'name', 'date', 'currentTurnPlayerName'];
      this.columnsForLargeWidth = ['type', 'name', 'date', 'completedPercentage', 'currentTurnPlayerName'];
    } else {
      this.columnsForSmallWidth = ['type', 'name', 'date', 'completedPercentage', 'score'];
      this.columnsForLargeWidth = ['type', 'name', 'date', 'completedPercentage', 'score'];
    }
  }

  protected setTitle() {
    if (this.listCrosswordGamesType == ListCrosswordGameTypes.Ongoing) {
      this.componentCommunication.currentRouteTitle.next(this.translateService.instant('route-titles.list-crossword-games'));
    } else {
      this.componentCommunication.currentRouteTitle.next(this.translateService.instant('route-titles.list-crossword-games-completed'));
    }
  }

  async onCrosswordGameSelected(c: CrosswordGameStatsData) {
    this.router.navigate(['/playCrossword', c.crosswordGameStats.gameId]);
  }
  onSizeChanged(size: SizeChanged) {
    this.columns = size.width < 500 ? this.columnsForSmallWidth : this.columnsForLargeWidth;
  }
  protected getCrosswordGameStatsToShow(crosswordGameStats: CrosswordGameStats[]) {
    return crosswordGameStats.filter((c) => this.shouldCrosswordGameStatsBeIncluded(c)).sort((c1, c2) => this.sortDates(c1, c2));
  }
  private shouldCrosswordGameStatsBeIncluded(crosswordGameStat: CrosswordGameStats) {
    if (this.listCrosswordGamesType == ListCrosswordGameTypes.Ongoing) {
      return crosswordGameStat.state != GameState.Ended;
    } else {
      return crosswordGameStat.state == GameState.Ended;
    }
  }
  protected sortDates(c1: CrosswordGameStats, c2: CrosswordGameStats): number {
    return c2.date.getTime() - c1.date.getTime();
  }
  private getCrosswordGameData(c: CrosswordGameStats): CrosswordGameStatsData {
    const playerStatistics = c.playerStatistics.find((p) => IdUtil.idEquals(p.playerId, this.userService.getLoggedIn()._id));
    return {
      playerCount: c.playerStatistics.length,
      name: c.crosswordName,
      date: c.date,
      completedPercentage: c.completedPercent,
      currentTurnPlayerName: c.currentPlayer?.name,
      crosswordGameStats: c,
      score: playerStatistics.score,
    };
  }
  private populateSortItems() {
    this.sortUtil = new SortColumnsUtil([
      new SortColumn('type', (t1, t2, isAsc) => compare(t1.playerCount, t2.playerCount, isAsc)),
      new SortColumn('name', (t1, t2, isAsc) => compare(t1.name.toLowerCase(), t2.name.toLowerCase(), isAsc)),
      new SortColumn('date', (t1, t2, isAsc) => compare(t1.date, t2.date, isAsc)),
      new SortColumn('completedPercentage', (t1, t2, isAsc) => compare(t1.completedPercentage, t2.completedPercentage, isAsc)),
      new SortColumn('currentTurnPlayerName', (t1, t2, isAsc) =>
        compare(t1.currentTurnPlayerName.toLowerCase(), t2.currentTurnPlayerName.toLowerCase(), isAsc),
      ),
      new SortColumn('score', (t1, t2, isAsc) => compare(t1.score, t2.score, isAsc)),
    ]);
  }
}
