<div #container [ngStyle]="{ 'left.px': positionAndSize.x, 'top.px': positionAndSize.y }" style="position: absolute; z-index: 100">
  <div
    class="image-container"
    [ngStyle]="{
      'width.px': positionAndSize.width,
      'maxWidth.px': positionAndSize.width,
      'height.px': positionAndSize.height,
      'maxHeight.px': positionAndSize.height
    }"
    fxLayout="column"
    fxLayoutGap="5px"
    fxLayoutAlign="center center"
  >
    <span>{{ question.question }}</span>
    <mat-progress-spinner mode="indeterminate" *ngIf="!imageLoaded" class="progress-spinner"></mat-progress-spinner>
    <div fxFlex fxLayout="column" style="overflow: auto">
      <img
        fxFlexFill
        [src]="imageUrl"
        [alt]="'Image url:' + imageUrl"
        (load)="onImageLoaded()"
        (click)="onShowLargeImage()"
        style="cursor: pointer"
        [ngClass]="{ showing: imageLoaded, hidden: !imageLoaded }"
      />
    </div>
  </div>
</div>
