export class ArrayUtil {
  static shuffleArray<T>(array: T[]) {
    const arrayTmp = array.slice();
    for (let i = arrayTmp.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arrayTmp[i], arrayTmp[j]] = [arrayTmp[j], arrayTmp[i]];
    }
    return arrayTmp;
  }
  static divideIntoSubArrays<T>(array: T[], count: number) {
    const containsSubArrays: T[][] = [];
    let arrayCounter = 0;
    for (let i = 0; i < array.length; i += count) {
      containsSubArrays[arrayCounter++] = array.slice(i, count);
    }
    return containsSubArrays;
  }
  static remove<T>(array: T[], valueToRemoe: T) {
    return array.filter((value) => value != valueToRemoe);
  }

  static removeArray<T>(array: T[], arrayToRemove: T[]) {
    const removeSet = new Set<T>(arrayToRemove);
    return array.filter((value) => !removeSet.has(value));
  }
}
