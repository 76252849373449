import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { CaptchaGameComponent } from '../../captcha-game/captcha-game.component';

@Injectable({
  providedIn: 'root',
})
export class CaptchaDialogService {
  constructor(private matDialog: MatDialog) {}

  async showDialog() {
    const width = Math.min(window.innerHeight - 20, 340);
    return (await lastValueFrom(
      this.matDialog
        .open(CaptchaGameComponent, {
          width: `${width}px`,
          maxWidth: `${width}px`,
          disableClose: true,
        })
        .afterClosed(),
    )) as boolean;
  }
}
