<mat-form-field fxFlex>
  <mat-label>{{ 'labels.new-tag' | translate }}</mat-label>
  <mat-chip-grid #chipList>
    <mat-chip-row *ngFor="let tag of tags.value" [removable]="true" (removed)="onMatChipRemoved(tag)">
      {{ tag }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>
    <input
      placeholder="{{ 'labels.new-tag' | translate }}"
      #tagsInputElement
      [formControl]="tagInput"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="onMatChipSeperatorKey()"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onTagSelected($event)">
    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
      {{ tag }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
