<div *ngIf="crosswordBuildForm" fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutGap="10px" class="padd-top-bottom-5-px">
  <div fxFlex.lt-md fxFlex.gt-sm="1 1 50%" fxLayout="column" fxLayoutGap="10px">
    <app-flexable-button-menu [buttons]="buildActionButtons"> </app-flexable-button-menu>
    <mat-card>
      <mat-card-content fxLayout="column" fxLayoutGap="10px">
        <mat-form-field>
          <mat-label>{{ 'labels.enter-crossword-name' | translate }}</mat-label>
          <input matInput [formControl]="crosswordBuildForm.controls.name" autocomplete="off" />
          <mat-error *ngIf="crosswordBuildForm.controls.name.hasError('required')">
            {{ 'errors.crossword-name-missing' | translate }}
          </mat-error>
          <mat-error *ngIf="crosswordBuildForm.controls.name.hasError('maxlength')">
            {{ 'errors.crossword-name-to-long' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'labels.enter-description' | translate }}</mat-label>
          <textarea matInput [formControl]="crosswordBuildForm.controls.description" cdkTextareaAutosize cdkAutosizeMinRows="2"></textarea>
          <mat-error *ngIf="crosswordBuildForm.controls.description.hasError('maxlength')">
            {{ 'errors.crossword-description-to-long' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'labels.difficulty' | translate }}</mat-label>
          <mat-select [formControl]="crosswordBuildForm.controls.difficulty">
            <mat-option *ngFor="let difficulty of Difficulties" [value]="difficulty.value">
              {{ difficulty.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox [formControl]="crosswordBuildForm.controls.autoSave">{{ 'labels.autosave' | translate }}</mat-checkbox>
      </mat-card-content>
    </mat-card>
    <mat-expansion-panel test-id="selected-question" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'panel-titles.select-questions' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <app-select-cwquestions
          [crosswordBuildForm]="crosswordBuildForm.controls"
          [sampleQuestionsChanged]="sampleQuestionsChanged"
          [type]="SelectCWQuestionType.Select"
        ></app-select-cwquestions>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel test-id="selected-questions" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'panel-titles.selected-questions' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <app-select-cwquestions
          [crosswordBuildForm]="crosswordBuildForm.controls"
          [sampleQuestionsChanged]="sampleQuestionsChanged"
          [type]="SelectCWQuestionType.Selected"
        ></app-select-cwquestions>
      </div>
    </mat-expansion-panel>
    <div></div>
  </div>
  <div fxFlex.lt-md fxFlex.gt-sm="1 1 50%">
    <div id="show-crossword-build" *ngIf="!generatingCrossword" fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutGap="5px">
        <div>
          <app-toggle-show-crossword [handleCrosswordView]="handleCrosswordView"></app-toggle-show-crossword>
        </div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
          <span
            [matTooltip]="
              'misc.crossword-detailed-build-points'
                | translate: { withPriority: buildScore.withPriority, withoutPriority: buildScore.withoutPriority }
            "
            >{{ 'misc.crossword-build-points' | translate: { score: buildScore.withPriority } }}</span
          >
        </div>
        <app-flexable-button-menu fxFlex [buttons]="crosswordActionButtons"> </app-flexable-button-menu>
      </div>
      <app-show-crossword [handleCrosswordView]="handleCrosswordView"> </app-show-crossword>
    </div>
    <div
      test-id="generating-crossword-progress"
      *ngIf="generatingCrossword"
      style="height: 300px"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
    >
      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-label>{{ 'misc.progress-generate-crossword' | translate }}</mat-label>
      </div>
      <mat-progress-bar style="width: 300px" mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</div>
<app-progress-bar *ngIf="!crosswordBuildForm"></app-progress-bar>
