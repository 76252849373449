import { Component, OnInit, Output } from '@angular/core';
import { ComponentCommunicationService } from '../../services/componentcommunication/component-communication.service';

@Component({
  selector: 'app-connection-bar',
  templateUrl: './connection-bar.component.html',
})
export class ConnectionBarComponent implements OnInit {
  @Output()
  showToolbar = false;
  constructor(public componentCommunication: ComponentCommunicationService) {}

  ngOnInit(): void {
    this.componentCommunication.noConnectionToServer.subscribe(async (value) => {
      if (value) {
        setTimeout(() => {
          if (this.componentCommunication.noConnectionToServer.getValue()) {
            this.showToolbar = true;
          }
        });
      } else {
        this.showToolbar = false;
      }
    });
  }
}
