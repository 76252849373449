import { Interval } from '../data/interval';
import { LanguageCode } from '../data/language';
import { Direction, IPosition, Position, RealDirection } from '../data/position';
import { CrosswordDifficultyEnum, CrosswordType, DefaultSize, ICrossword, ICrosswordCWQuestion, ICrosswordSize } from '../model/crossword';
import { IPlayer } from '../model/player';

export class CrosswordUtil {
  static getNumberOfQuestionsSortedAfterDistanceToCenter(crosswordQuestions: ICrosswordCWQuestion[], questionsCount: number) {
    return this.getQuestionsSortedAfterDistanceToCenter(crosswordQuestions)
      .slice(0, questionsCount)
      .map((c) => c.cwQuestion.indexId);
  }
  static getQuestionsSortedAfterDistanceToCenter(crosswordQuestions: ICrosswordCWQuestion[]) {
    const centerPoint: Position = new Position(DefaultSize.mainAxis / 2, DefaultSize.crossAxis / 2, Direction.MAINAXIS);
    return crosswordQuestions.sort(
      (c1, c2) => centerPoint.distanceTo(CrosswordUtil.getCenterPosition(c1)) - centerPoint.distanceTo(CrosswordUtil.getCenterPosition(c2))
    );
  }
  static removeQuestionFromCrossword(crossword: ICrossword, indexId: number) {
    const index = crossword.crosswordQuestions.findIndex((q) => q.cwQuestion.indexId == indexId);
    crossword.crosswordQuestions.splice(index, 1);
  }
  static getIncreasePosition(position: IPosition, index: number) {
    const x = position.x + (index + 1) * (position.direction === 0 ? 1 : 0);
    const y = position.y + (index + 1) * (position.direction === 1 ? 1 : 0);
    return { x, y };
  }
  static createEmptyCrossword(languageCode: LanguageCode, player: IPlayer, size: ICrosswordSize): ICrossword {
    return {
      languageCode: languageCode,
      name: '',
      description: '',
      difficulty: CrosswordDifficultyEnum.Easy,
      author: player,
      _id: null,
      crosswordQuestions: [],
      size: size,
      date: new Date(),
      type: CrosswordType.Built,
    };
  }
  static findCrosswordQuestion(crossword: ICrossword, index: number) {
    return crossword.crosswordQuestions.find((q) => q.cwQuestion.indexId == index);
  }
  static getCenterPosition(q: ICrosswordCWQuestion) {
    return Position.toPosition(q.position).add(Math.floor(q.cwQuestion.answer.length / 2) + 1);
  }
  static getAnswerForPosition(crossword: ICrossword, position: IPosition) {
    const questionPosition = crossword.crosswordQuestions.find((c) => this.doesCrosswordQuestionContainsPosition(position, c));
    if (questionPosition == null) {
      return null;
    }
    return this.getCharFromAnswer(position, questionPosition);
  }
  static getRealQuestionDirection(crosswordDirection: Direction, questionDirection: Direction) {
    if (crosswordDirection == Direction.MAINAXIS) {
      return questionDirection == Direction.MAINAXIS ? RealDirection.Horizontal : RealDirection.Vertical;
    }
    return questionDirection == Direction.MAINAXIS ? RealDirection.Vertical : RealDirection.Horizontal;
  }
  static getShowCrosswordHeight(crosswordSize: ICrosswordSize, matrixDirection: Direction, tileSize: number) {
    const verticalTileCount = matrixDirection === Direction.MAINAXIS ? crosswordSize.crossAxis : crosswordSize.mainAxis;
    return tileSize * verticalTileCount;
  }
  static getShowCrossworWidth(crosswordSize: ICrosswordSize, matrixDirection: Direction, tileSize: number) {
    const horizontalTileCount = matrixDirection === Direction.MAINAXIS ? crosswordSize.mainAxis : crosswordSize.crossAxis;
    return tileSize * horizontalTileCount;
  }
  private static doesCrosswordQuestionContainsPosition(position: IPosition, questionPosition: ICrosswordCWQuestion) {
    const interval = Interval.fromPosition(questionPosition.position, questionPosition.cwQuestion.answer.length + 1);
    return interval.insideInterval(position.x, position.y);
  }
  private static getCharFromAnswer(position: IPosition, questionPosition: ICrosswordCWQuestion) {
    const distance = Position.toPosition(position).distanceTo(questionPosition.position);
    return questionPosition.cwQuestion.answer[distance - 1];
  }
}
