import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { ChatMessage } from '../../../../../../Common/data/chat.message';
import { ICrosswordGame } from '../../../../../../Common/model/crossword.game';
import { CrosswordGameUtil } from '../../../../../../Common/util/crossword.game.util';
import { CrosswordGameService } from '../../../services/crosswordgame/crossword-game.service';
import { UserService } from '../../../services/user/user.service';
import { DialogService } from '../../dialog/service/dialog.service';

@Component({
  selector: 'app-crossword-chat',
  templateUrl: './crossword-chat.component.html',
  styleUrls: ['./crossword-chat.component.scss'],
})
export class CrosswordChatComponent implements OnChanges {
  displayedColumns: string[] = ['name', 'message'];

  @ViewChild('chatMessageInput')
  chatMessageInput: ElementRef;

  @ViewChild('chatTableContainer')
  chatTableContainer: ElementRef;

  @Input()
  crosswordGame: ICrosswordGame;

  chatMessages: ChatMessage[] = [];

  private currentCrosswordGame: ICrosswordGame;

  constructor(private userService: UserService, private crosswordGameService: CrosswordGameService, private dialogService: DialogService) {}

  ngOnChanges(): void {
    if (this.currentCrosswordGame == null || CrosswordGameUtil.shouldUpdateCrosswordChats(this.currentCrosswordGame, this.crosswordGame)) {
      this.currentCrosswordGame = this.crosswordGame;
      this.chatMessages = this.crosswordGame.chats.chatMessages;
      setTimeout(() => this.scrollTableToBottom(), 500);
    }
  }
  blurTextArea() {
    if (this.chatMessageInput) {
      this.chatMessageInput.nativeElement.blur();
    }
  }
  async onSendChatMessages(message: string) {
    this.dialogService.wrapInProgress(async () => {
      const currentPlayer = this.userService.getLoggedIn();
      await this.crosswordGameService.addChatMessage({
        gameId: this.crosswordGame._id,
        chatMessage: { player: currentPlayer, message: message },
      });
      this.blurTextArea();
    });
  }
  private scrollTableToBottom() {
    if (this.chatTableContainer) {
      this.chatTableContainer.nativeElement.scrollBy(0, 10000);
    }
  }
}
