import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CrosswordGameReminderData } from '../../../../../Common/data/crossword.game.reminder.data';
import { AppSettingsService } from '../appsettings/app-settings.service';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class CrosswordGameReminderService {
  constructor(private configService: ConfigService, private httpClient: HttpClient, private appSettingsService: AppSettingsService) {}
  async initialize() {
    if (isDevMode()) {
      setInterval(() => this.checkCrosswordGameByReminder(), 30000);
    }
  }
  async checkCrosswordGameByReminder() {
    const routes = this.configService.getRoutes();
    try {
      const result = await lastValueFrom(
        this.httpClient.get<CrosswordGameReminderData>(
          this.appSettingsService.appSettings.apiurl + `${routes.crosswordGameReminder}/7eda74ce-1b28-43e1-b977-33b0fdb979cf`
        )
      );
      return result;
    } catch (error) {
      console.log('Failed to check for game reminder in dev mode', error);
    }
  }
}
