import deepEqual from 'deep-equal';

export class ObjectUtil {
  static copyAndClean<T>(obj: T) {
    return JSON.parse(JSON.stringify(obj)) as T;
  }
  static copyAndCleanAll<T>(objs: T[]) {
    return objs.map((obj) => JSON.parse(JSON.stringify(obj)) as T);
  }
  static assignProperties<T>(assignTo: T, assignFrom: T) {
    for (const property in assignFrom) {
      assignTo[property] = assignFrom[property];
    }
  }
  static isObjectEqual<T>(obj1: T, obj2: T) {
    if (obj1 && !obj2) {
      return false;
    }
    if (!obj1 && obj2) {
      return false;
    }
    return deepEqual(obj1, obj2, { strict: false });
  }
}
