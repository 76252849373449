import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { CrosswordGameStats } from '../../../../../Common/model/crossword.game.stats';
import { DateUtil } from '../../components/util/date.util';
import { AppSettingsService } from '../appsettings/app-settings.service';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class CrosswordGameStatsService {
  constructor(private http: HttpClient, private configService: ConfigService, private appSettingsService: AppSettingsService) {}
  async getCrosswordStats(id: string) {
    return await lastValueFrom(
      this.http
        .get<CrosswordGameStats[]>(
          `${this.appSettingsService.appSettings.apiurl}${this.configService.getRoutes().crosswordGameStats}/crosswordStats/${id}`
        )
        .pipe(map(this.mapDates))
    );
  }
  async getPlayerStats(id: string) {
    return await lastValueFrom(
      this.http
        .get<CrosswordGameStats[]>(
          `${this.appSettingsService.appSettings.apiurl}${this.configService.getRoutes().crosswordGameStats}/playerStats/${id}`
        )
        .pipe(map(this.mapDates))
    );
  }
  private mapDates(stats: CrosswordGameStats[]): CrosswordGameStats[] {
    return DateUtil.exchangeDates(stats, () => DateUtil.nameof<CrosswordGameStats>('date'));
  }
}
