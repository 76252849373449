import { PriorityValue } from '../data/priority.value';
import { ICrosswordCWQuestion } from '../model/crossword';
import { IndexPriority } from '../model/crossword.build';
import { CWQuestionDifficultyEnum, CWQuestionVisibility, ICWQuestion, ICWQuestionBase, QuestionBasePriority } from '../model/cwquestion';
import { SearchQuestionsItem } from '../query/search.question.query';
import { IndexPriorityUtil } from './index.priority.util';
import { LanguageUtil } from './language.util';

export interface FilterCWQuestion {
  regExFilter: string;
  lengths: number[];
}

export class CWQuestionUtil {
  static removeMoreQuestionsThanMaxPerPriority(questions: IndexPriority[], MaxNumberOfQuestionsPerBuild: number) {
    const lowQuestionPriority = questions
      .filter((question) => question.priority == PriorityValue.Low)
      .slice(0, MaxNumberOfQuestionsPerBuild);
    const mediumQuestionPriority = questions
      .filter((question) => question.priority == PriorityValue.Medium)
      .slice(0, MaxNumberOfQuestionsPerBuild);
    const hightQuestionPriority = questions
      .filter((question) => question.priority == PriorityValue.Medium)
      .slice(0, MaxNumberOfQuestionsPerBuild);
    const themeQuestionPriority = questions.filter((question) => question.priority == PriorityValue.Theme);
    return [...lowQuestionPriority, ...mediumQuestionPriority, ...hightQuestionPriority, ...themeQuestionPriority];
  }
  static getQuestionsFromIndexes(crosswordQuestions: ICrosswordCWQuestion[], questionIndexes: number[]) {
    const questionIndexesSet = new Set<number>(questionIndexes);
    return crosswordQuestions.filter((c) => questionIndexesSet.has(c.cwQuestion.indexId));
  }
  static isAuthorIdEqual(cwQuestion: ICWQuestion, id: any) {
    return cwQuestion.author._id != null ? cwQuestion.author._id == id : cwQuestion.author == id;
  }
  static removeFiltersLongerThen(filterCWQuestion: FilterCWQuestion, longerThen: number) {
    const lengths = filterCWQuestion.lengths.filter((length) => length <= longerThen);
    const filter = filterCWQuestion.regExFilter.slice(0, Math.min(filterCWQuestion.regExFilter.length, longerThen));
    return { lengths: lengths, regExFilter: filter } as FilterCWQuestion;
  }
  static getFilteredQuestions(cwQuestions: ICWQuestion[], filterCWQuestion: FilterCWQuestion) {
    return cwQuestions.filter((cwQuestion) => this.canFitBaseQuestion(cwQuestion.base, filterCWQuestion));
  }
  static canFitBaseQuestion(question: ICWQuestionBase, filterCWQuestion: FilterCWQuestion) {
    const length = question.answer.length;
    if (filterCWQuestion.lengths.find((lengthTmp) => lengthTmp == length)) {
      const regexp = filterCWQuestion.regExFilter.slice(0, length);
      const matchedLength = question.answer.toLowerCase().match(regexp)?.length ?? 0;
      return matchedLength > 0;
    }
    return false;
  }
  static getEmptyQuestion(browerLang: string) {
    return {
      languageCode: LanguageUtil.getLanguageCode(browerLang),
      base: { answer: '', question: '' },
      difficulty: CWQuestionDifficultyEnum.Easy,
      tags: [],
      visibility: CWQuestionVisibility.All,
    } as unknown as ICWQuestion;
  }
  static getBaseQuestionFrom(item: SearchQuestionsItem): ICWQuestionBase {
    return {
      indexId: item.indexId,
      answer: item.answer,
      question: item.question,
      type: item.type,
    };
  }

  static getBaseQuestionsFrom(items: SearchQuestionsItem[]): ICWQuestionBase[] {
    return items.map((item) => this.getBaseQuestionFrom(item));
  }
  static getPriorityBaseQuestionFrom(questions: ICWQuestionBase[], indexPriorities: IndexPriority[]) {
    const indexMap = IndexPriorityUtil.getIndexPriorityMap(indexPriorities);
    return questions.map((question) => {
      return {
        ...question,
        priority: indexMap.get(question.indexId),
      } as QuestionBasePriority;
    });
  }
  static getPriorityBaseQuestionWithPriority(questions: ICWQuestionBase[], priority: PriorityValue) {
    return questions.map((question) => {
      return { ...question, priority: priority } as QuestionBasePriority;
    });
  }
  static getCWQuestionFromSearchQuestionsItem(item: SearchQuestionsItem, browerLang: string): ICWQuestion {
    return {
      _id: item.questionId,
      author: null,
      base: { question: item.question, answer: item.answer, indexId: item.indexId, type: item.type, imageId: item.imageId },
      tags: item.tags,
      date: item.updated,
      difficulty: item.difficulty,
      languageCode: LanguageUtil.getLanguageCode(browerLang),
      visibility: item.visibility,
      category: item.category,
      imageUrl: item.imageUrl,
    };
  }
  static getPriorityQuestion(question: ICrosswordCWQuestion): QuestionBasePriority {
    return { ...question.cwQuestion, priority: question.priority };
  }
}
