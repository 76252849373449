import { ReQuestionPositionData } from '../models/re.question.position.data';
import { RandomizeUtil } from './randomize.util';
import { OpenInterval, ReQuestion } from './re.matrix';
import { RePositionAndQuestion } from './re.position.and.question';

export class ReRandomPositionAndQuestion implements RePositionAndQuestion {
  public getPositionAndQuestion(questions: ReQuestion[], intervals: OpenInterval[]) {
    const randomQuestions = RandomizeUtil.shuffle([...questions]);
    for (const question of randomQuestions) {
      const positionForQuestion = this.getPositionForQuestion(question, intervals);
      if (positionForQuestion) {
        return positionForQuestion;
      }
    }
    return null;
  }
  public getPositionForQuestion(question: ReQuestion, intervals: OpenInterval[]) {
    const randomIntervals = RandomizeUtil.shuffle([...intervals]);
    for (const interval of randomIntervals) {
      if (interval.canAddQuestion(question)) {
        return this.getQuestionPositionData(question, interval);
      }
    }
    return null;
  }
  private getQuestionPositionData(question: ReQuestion, interval: OpenInterval): ReQuestionPositionData {
    const crossings = interval.getCrossings(question);
    return {
      question,
      position: interval.interval,
      points: question.score.perCrossing * crossings + question.answer.length * question.score.perChar,
    };
  }
}
