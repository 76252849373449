<mat-form-field fxFlex>
  <mat-label>{{ 'options.select-filter' | translate }}</mat-label>
  <mat-select id="select-filter-items" [formControl]="form" multiple>
    <mat-select-trigger>
      {{ form.value?.length > 0 ? form.value[0].title : '' }}
      <span *ngIf="form.value?.length > 1" class="additional-selection"
        >{{ 'options.additional-selection' | translate: { count: form.value.length - 1 } }}
      </span>
    </mat-select-trigger>

    <mat-optgroup *ngFor="let group of selectFilterGroups">
      <div>
        <mat-checkbox [checked]="isGroupSelected(group)" (change)="toggleSelection(group)" (click)="$event.stopPropagation()">
          {{ group.title }}
        </mat-checkbox>
        <button mat-button (click)="group.expanded = !group.expanded">
          <mat-icon>{{ group.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
        </button>
      </div>
      <mat-option
        *ngFor="let selectItem of group.selectItems"
        [value]="selectItem"
        class="option"
        [style.display]="group.expanded ? 'flex' : 'none'"
      >
        {{ selectItem.title }}
      </mat-option>
    </mat-optgroup>
    <mat-option [style.display]="'none'"></mat-option>
  </mat-select>
</mat-form-field>
