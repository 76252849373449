import { Component, Input } from '@angular/core';
import { ArrowDirection } from '../content/crossword-tile.component';

@Component({
  selector: 'app-show-direction',
  templateUrl: './show-direction.component.html',
  styleUrls: ['./show-direction.component.scss'],
})
export class ShowDirectionComponent {
  ArrowDirection = ArrowDirection;
  @Input()
  set tileSize(value: number) {
    this.triangleSize = value / 10;
  }
  @Input()
  questionDirection: ArrowDirection;

  triangleSize: number;
}
