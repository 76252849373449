import { KrysskampenConstants } from '../const/krysskampen.constants';
import { GameState } from '../data/game.state';
import { Point } from '../data/point';
import { Position } from '../data/position';
import { ICrossword, ICrosswordCWQuestion } from '../model/crossword';
import { ICrosswordGame } from '../model/crossword.game';
import { IdUtil } from './id.util';

export class CrosswordGameTurnUtil {
  static changeToNextPlayer(crosswordGame: ICrosswordGame) {
    const nextPlayer = CrosswordGameTurnUtil.getNextPlayer(crosswordGame, crosswordGame.turns.currentPlayerId)?._id;
    if (nextPlayer == null) {
      throw new Error('Cant change to next player');
    }
    crosswordGame.turns.currentPlayerId = nextPlayer;
  }
  static getCrosswordsNumberOfTurns(crossword: ICrossword) {
    const answerCharPositions = new Set<number>();
    crossword.crosswordQuestions.forEach((c) => this.addQuesionAnswerCharsPositions(c, answerCharPositions));
    return Math.max(Math.floor(answerCharPositions.size / KrysskampenConstants.CharsAddedPerTurn), 1);
  }
  static getTotalNumberOfTurns(numberOfPlayers: number, crossword: ICrossword) {
    const crosswordNumberOfTurns = this.getCrosswordsNumberOfTurns(crossword);
    return Math.max(Math.floor(crosswordNumberOfTurns / numberOfPlayers), 1);
  }
  static getCurrentGameTurn(crosswordGame: ICrosswordGame) {
    return this.getCurrentTurn(crosswordGame.turns.playerTurns.length, crosswordGame.playersInfo.players.length);
  }
  static getCurrentTurn(numberOfTurns: number, numberOfPlayers: number) {
    return Math.floor(numberOfTurns / numberOfPlayers);
  }
  static getNextPlayerTurn(numberOfTurns: number, numberOfPlayers: number) {
    return Math.floor((numberOfTurns + 1) / numberOfPlayers);
  }
  static addPlayerTurn(crosswordGame: ICrosswordGame, charsSet: Point[]) {
    crosswordGame.turns.playerTurns.push({
      playerId: crosswordGame.turns.currentPlayerId,
      tilesSet: charsSet,
      turn: this.getCurrentGameTurn(crosswordGame),
    });
  }
  static getPlayerOrderInGame(crosswordGame: ICrosswordGame, playerId: any) {
    if (crosswordGame.global.state == GameState.Created) {
      return '';
    }
    const firstPlayerId = CrosswordGameTurnUtil.getFirstPlayerId(crosswordGame);
    const firstPlayerIndex = this.getIndexOfPlayerId(crosswordGame, firstPlayerId);
    for (let i = 0; i < crosswordGame.playersInfo.players.length; i++) {
      const player = this.getPlayerFromIndex(crosswordGame, firstPlayerIndex + i);
      if (IdUtil.idEquals(player._id, playerId)) {
        return `${i + 1}`;
      }
    }
    throw new Error('User not in game:' + playerId);
  }
  private static getNextPlayer(crosswordGame: ICrosswordGame, playerId: any) {
    if (crosswordGame.playersInfo.players.length == 0) {
      return null;
    }
    const index = CrosswordGameTurnUtil.getIndexOfPlayerId(crosswordGame, playerId);
    return CrosswordGameTurnUtil.getPlayerFromIndex(crosswordGame, index + 1);
  }

  private static getPlayerFromIndex(crosswordGame: ICrosswordGame, index: number) {
    return crosswordGame.playersInfo.players[index % crosswordGame.playersInfo.players.length];
  }

  private static getIndexOfPlayerId(crosswordGame: ICrosswordGame, playerId: any) {
    return crosswordGame.playersInfo.players.findIndex((player) => IdUtil.idEquals(player._id, playerId));
  }
  private static getFirstPlayerId(crosswordGame: ICrosswordGame) {
    return crosswordGame.turns.playerTurns.length > 0 ? crosswordGame.turns.playerTurns[0].playerId : crosswordGame.turns.currentPlayerId;
  }
  private static addQuesionAnswerCharsPositions(question: ICrosswordCWQuestion, answerCharPositions: Set<number>) {
    for (let i = 0; i < question.cwQuestion.answer.length; i++) {
      const charPosition = Position.toPosition(question.position).add(i + 1);
      answerCharPositions.add(charPosition.x * 100 + charPosition.y);
    }
  }
}
