import { Injectable, isDevMode } from '@angular/core';
import { ComponentCommunicationService } from '../componentcommunication/component-communication.service';

@Injectable({ providedIn: 'root' })
export class DevHookService {
  constructor(private componentCommunicationService: ComponentCommunicationService) {}
  init() {
    if (isDevMode()) {
      console.log('DevHookService:init');
      window.addEventListener('triggerGameReload', () => {
        console.log('DevHookService:triggerGameReload');
        this.componentCommunicationService.reloadCurrentCrosswordGame.next();
      });
      window.addEventListener('setInProductionMode', () => {
        console.log('DevHookService:setInProductionMode');
        this.componentCommunicationService.devMode.next(false);
      });
    }
  }
}
