import { Component, Input, OnChanges } from '@angular/core';
import { Position, XYPosition } from '../../../../../../../Common/data/position';
import { CWQuestionType, ICWQuestionBase } from '../../../../../../../Common/model/cwquestion';
import { TextWidthUtil } from '../../../util/text.width.util';

enum TextPositon {
  TopRight,
  TopLeft,
  BottomRight,
  BottomLeft,
}
const padding = 20;

@Component({
  selector: 'app-show-all-question',
  templateUrl: './show-all-question.component.html',
  styleUrls: ['show-all-question.component.scss'],
})
export class ShowAllQuestionComponent implements OnChanges {
  CWQuestionType = CWQuestionType;
  @Input()
  questionPosition: Position;
  @Input()
  question: ICWQuestionBase;
  @Input()
  size: number;
  @Input()
  showCrosswordWidth: number;
  position: XYPosition;

  ngOnChanges(): void {
    this.calculatePosition();
  }
  private calculatePosition() {
    const textWidth = this.getWantedWidth() + padding;
    const textPosition = this.getTextPosition();
    const rightX = this.questionPosition.x + this.size + 1;
    const leftX = Math.max(this.questionPosition.x - textWidth, 0);
    const topY = this.questionPosition.y - this.getWantedHeight() + 1;
    const bottomY = this.questionPosition.y + this.size + 3;

    if (textPosition == TextPositon.TopRight) {
      this.position = { x: rightX, y: topY };
    } else if (textPosition == TextPositon.TopLeft) {
      this.position = { x: leftX, y: topY };
    } else if (textPosition == TextPositon.BottomRight) {
      this.position = { x: rightX, y: bottomY };
    } else {
      this.position = { x: leftX, y: bottomY };
    }
  }
  private getTextPosition() {
    const totalWidth = this.showCrosswordWidth;
    const textWidth = this.getWantedWidth() + padding;
    const textWillExceedWitdth = this.questionPosition.x + textWidth + this.size < totalWidth;
    if (this.questionPosition.y - this.getWantedHeight() > 0) {
      return textWillExceedWitdth ? TextPositon.TopRight : TextPositon.TopLeft;
    } else {
      return textWillExceedWitdth ? TextPositon.BottomRight : TextPositon.BottomLeft;
    }
  }

  private getWantedWidth() {
    return TextWidthUtil.getTextWidth(this.question.question, '18px Roboto');
  }

  private getWantedHeight() {
    return 28;
  }
}
