/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, isDevMode, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HelpInstruction } from '../../../Common/model/player.settings';
import packageInfo from '../../package.json';
import { MessageBoxType } from './components/dialog/message-box-content/message-box-content.component';
import { DialogService } from './components/dialog/service/dialog.service';
import { AppSettingsService } from './services/appsettings/app-settings.service';
import { AppVersionService } from './services/appversion/app-version.service';
import { BrowserDeviceType, ComponentCommunicationService } from './services/componentcommunication/component-communication.service';
import { ConfigService, DevDatabaseType } from './services/config/config.service';
import { CookieConsentService } from './services/cookieconsent/cookie-consent.service';
import { CrosswordGameService } from './services/crosswordgame/crossword-game.service';
import { CrosswordGameReminderService } from './services/crosswordgamereminder/crossword-game-reminder.service';
import { DevHookService } from './services/dev-hook/dev-hook.service';
import { HelpDialogsService } from './services/helpdialogs/help-dialogs.service';
import { LanguageService } from './services/language/language.service';
import { UserService } from './services/user/user.service';
import { BrowserUtil } from './util/browser.util';
import { UserCodeDialogService } from './components/dialog/service/user-code-dialog.service';
import { UserCodeType } from './components/dialog/number-code/number-code.component';

export interface MenuAction {
  id: string;
  title: string;
  icon: string;
  action: () => void;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  topActions: MenuAction[] = [];
  bottomActions: MenuAction[] = [];

  initialized = false;
  virutalKeyboardHeight = 0;
  sideNavigationShowing = false;
  constructor(
    private configService: ConfigService,
    private userService: UserService,
    private appSettingsService: AppSettingsService,
    private router: Router,
    private translateService: TranslateService,
    private dialogService: DialogService,
    public communicationService: ComponentCommunicationService,
    private crosswordGameReminderService: CrosswordGameReminderService,
    private appVersionService: AppVersionService,
    private crosswordGameService: CrosswordGameService,
    private cookieConsentService: CookieConsentService,
    private devHookService: DevHookService,
    private helpDialogService: HelpDialogsService,
    private languageService: LanguageService,
    private userCodeDialogService: UserCodeDialogService,
  ) {
    this.translateService.setDefaultLang('sv');

    this.clearSelectedQuestionOnNavigation();
  }

  async ngOnInit() {
    await this.appVersionService.clearCacheAndReloadIfNoVersionSet();
    await Promise.all([lastValueFrom(this.translateService.use(this.languageService.getLocale())), this.appSettingsService.initialize()]);

    this.cookieConsentService.initialize();
    if (isDevMode() && this.communicationService.deviceType.getValue() == BrowserDeviceType.Browser) {
      await this.configService.initializeWhenDeveloping(DevDatabaseType.Medium);
    } else {
      await this.configService.initialize();
    }
    console.log(
      `Starting up Krysskampen with version:${JSON.stringify(
        this.configService.getAppPackageVersion(),
      )} and server version:${JSON.stringify(this.configService.getServerPackageVersion())}`,
    );
    if (
      this.appVersionService.isAppVersionToHigh(this.configService.getAppPackageVersion(), this.configService.getServerPackageVersion())
    ) {
      await this.dialogService.showMessageBox('', this.translateService.instant('messages.app-version-to-high'), MessageBoxType.Ok);
      return;
    }
    if (this.appVersionService.isAppVersionToLow(this.configService.getAppPackageVersion(), this.configService.getServerPackageVersion())) {
      await this.appVersionService.waitAndApplyNewAppVersion();
    }

    await this.userService.initialize();
    await this.crosswordGameReminderService.initialize();
    await this.appVersionService.listenForUpdates();

    this.addTopActions();
    this.addBottomActions();

    this.devHookService.init();
    // this.addVirtualKeyboardHandle();
    this.initialized = true;

    this.showAddToHomescreenOnIos();
  }

  private addTopActions() {
    this.topActions.push({
      id: 'list-crosswords',
      title: this.translateService.instant('actions.list-crosswords'),
      action: () => this.router.navigateByUrl('/listCrosswords'),
      icon: 'games',
    });
    this.topActions.push({
      id: 'list-crossword-games',
      title: this.translateService.instant('actions.list-crossword-games'),
      action: () => this.router.navigateByUrl('/listCrosswordGames'),
      icon: 'view_quilt',
    });
    this.topActions.push({
      id: 'list-crossword-games-completed',
      title: this.translateService.instant('actions.list-crossword-games-completed'),
      action: () => this.router.navigateByUrl('/listCrosswordGamesCompleted'),
      icon: 'view_quilt',
    });
    this.topActions.push({
      id: 'list-crossword-builds',
      title: this.translateService.instant('actions.list-crossword-builds'),
      action: () => this.router.navigateByUrl('/listCrosswordBuilds'),
      icon: 'create',
    });
    this.topActions.push({
      id: 'join-game',
      title: this.translateService.instant('actions.join-crossword-game'),
      action: () => this.onJoinCrosswordGame(),
      icon: 'join_left',
    });
  }

  private addBottomActions() {
    this.bottomActions.push({
      id: 'logout',
      title: this.translateService.instant('actions.logout'),
      icon: 'exit_to_app',
      action: async () => {
        await this.userService.logout();
        this.router.navigateByUrl('/login');
      },
    });
    this.bottomActions.push({
      id: 'player-settings',
      title: this.translateService.instant('actions.player-settings'),
      icon: 'person',
      action: () => this.router.navigateByUrl('/playersettings'),
    });
    this.bottomActions.push({
      id: 'about',
      title: this.translateService.instant('actions.about'),
      icon: 'info',
      action: () => this.onAbout(),
    });
  }
  private onAbout() {
    const serverVersion = this.configService.getConfig().version;
    this.dialogService.showMessageBox(
      'Krysskampen',
      this.translateService.instant('misc.about', { version: packageInfo.version, serverVersion: serverVersion }),
      MessageBoxType.Ok,
    );
  }

  isAuthenticated() {
    return this.userService.isLoggedIn();
  }
  private clearSelectedQuestionOnNavigation() {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.communicationService.selectedQuestion.next(null);
    });
  }
  private addVirtualKeyboardHandle() {
    if ('virtualKeyboard' in navigator) {
      const virtualKeyboard: any = (<any>navigator).virtualKeyboard;
      virtualKeyboard.overlaysContent = true;
      virtualKeyboard.addEventListener('geometrychange', (event) => {
        const { height } = event.target.boundingRect;
        this.virutalKeyboardHeight = height;
      });
    }
  }
  private async onJoinCrosswordGame() {
    const crosswordGameId = await this.userCodeDialogService.showAskUserCode({
      type: UserCodeType.JoinGame,
    });
    if (!crosswordGameId) {
      return;
    }

    window.location.href = `/playCrossword/${crosswordGameId}?join=true`;
  }
  private showAddToHomescreenOnIos() {
    this.communicationService.playerLoggedIn.subscribe((value) => {
      if (value && BrowserUtil.isIOS() && !BrowserUtil.isPWA()) {
        this.helpDialogService.checkAndShowDialog(HelpInstruction.AddAppToHomescreen);
      }
    });
  }
}
