export class TextWidthUtil {
  private static canvasElement: HTMLCanvasElement;
  static getTextWidth(text, font) {
    // re-use canvas object for better performance
    if (!this.canvasElement) {
      this.canvasElement = document.createElement('canvas');
    }
    const context = this.canvasElement.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }
}
