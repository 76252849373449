import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, Subject } from 'rxjs';
import { ICrossword } from '../../../../../Common/model/crossword';
import {
  CrosswordGameUpdate,
  FindCrosswordGame,
  IAddChatMessage,
  ICreateCrosswordGame,
  ICrosswordGame,
  JoinCrosswordGame,
  LeaveCrosswordGame,
  StartCrosswordGame,
  StopCrosswordGame,
} from '../../../../../Common/model/crossword.game';
import { CrosswordGameStats } from '../../../../../Common/model/crossword.game.stats';
import { IPlayer } from '../../../../../Common/model/player';
import { AssertUtil } from '../../../../../Common/util/assert.util';
import { BackoffTimer } from '../../../../../Common/util/backoff.timer';
import { IdUtil } from '../../../../../Common/util/id.util';
import { LanguageUtil } from '../../../../../Common/util/language.util';
import { UpdateCrosswordGameUtil } from '../../../../../Common/util/update.crossword.game.util';
import { CrosswordGamePlay } from '../../components/util/crossword.game.play';
import { GameHandleCrosswordView } from '../../components/util/handleCrosswordView/game.handle.crossword.view';
import { AppSettingsService } from '../appsettings/app-settings.service';
import { ComponentCommunicationService } from '../componentcommunication/component-communication.service';
import { ConfigService } from '../config/config.service';
import { CrosswordGameStatsService } from '../crosswordgamestats/crossword.game.stats.service';
import { UserService } from '../user/user.service';
const backoffInterval = [5000, 10000, 30000];
const FiveMinutesInMillis = 300000;
export interface ISolveQuestionResult {
  solvedQuestion: boolean;
  crosswordGame: ICrosswordGame;
}

export type CrosswordChangedListener = (crosswordGame: ICrosswordGame) => void;

@Injectable({
  providedIn: 'root',
})
export class CrosswordGameService {
  private crosswordGame: ICrosswordGame;
  private crosswordGamePlay: CrosswordGamePlay;
  private gameHandleCrosswordView: GameHandleCrosswordView;
  private crosswordGameStats: CrosswordGameStats[];
  public readonly crosswordGameSubject = new Subject<ICrosswordGame>();
  private readonly checkCrosswordChangedTimer: BackoffTimer;
  constructor(
    private userService: UserService,
    private configService: ConfigService,
    private httpClient: HttpClient,
    private appSettingsService: AppSettingsService,
    private translateService: TranslateService,
    private componentCommunicationService: ComponentCommunicationService,
    private crosswordGameStatsService: CrosswordGameStatsService,
    private router: Router,
  ) {
    this.checkCrosswordChangedTimer = new BackoffTimer(backoffInterval, FiveMinutesInMillis, async () => {
      await this.checkIfCrosswordGameIsUpdated();
    });
  }

  initializePlay() {
    this.componentCommunicationService.reloadCurrentCrosswordGame.subscribe(() => this.checkIfCrosswordGameIsUpdated());
    this.crosswordGamePlay = new CrosswordGamePlay();
    this.gameHandleCrosswordView = new GameHandleCrosswordView(this.getPlayer(), this.crosswordGamePlay);
  }

  setTimerIntervalForCheckCrosswordChangedForTest(interval: number[]) {
    this.checkCrosswordChangedTimer.resetIntervalCounter(interval, 5);
  }
  getCrosswordGame() {
    return this.crosswordGame;
  }
  getGameHandleCrosswordView() {
    return this.gameHandleCrosswordView;
  }
  getCrosswordGamePlay() {
    return this.crosswordGamePlay;
  }
  hasPlayedCrosswordGameBefore() {
    const playerStatistics = this.crosswordGameStats.filter((c) =>
      c.playerStatistics.find((p) => IdUtil.idEquals(p.playerId, this.getPlayer()._id)),
    );
    return playerStatistics.length > 0;
  }
  setCheckForCrosswordChanged(setTo: boolean) {
    if (setTo) {
      this.checkCrosswordChangedTimer.start();
    } else {
      this.checkCrosswordChangedTimer.stop();
    }
  }
  async loadAndSetCurrentCrosswordGame(id: string) {
    const routes = this.configService.getRoutes();
    const crosswordGame = await lastValueFrom(
      this.httpClient.get<ICrosswordGame>(this.appSettingsService.appSettings.apiurl + `${routes.crosswordGame}/${id}`),
    );
    this.setCrosswordGame(crosswordGame);
    await this.loadAndSetCrosswordGameStatistics();
    return crosswordGame;
  }

  async createCrosswordGame(crossword: ICrossword) {
    const player = this.userService.getLoggedIn();
    const routes = this.configService.getRoutes();
    const createCrossword: ICreateCrosswordGame = {
      player: player,
      crossword,
      languageCode: LanguageUtil.getLanguageCode(this.translateService.getBrowserLang()),
    };
    this.crosswordGame = await lastValueFrom(
      this.httpClient.put<ICrosswordGame>(this.appSettingsService.appSettings.apiurl + routes.crosswordGame, createCrossword),
    );
    return this.crosswordGame;
  }
  async updateCrosswordGame(crosswordGame: ICrosswordGame, updateCurrentGame = true) {
    const routes = this.configService.getRoutes();
    const crosswordGameUpdate = UpdateCrosswordGameUtil.createUpdateByGameObjectChanges(crosswordGame, this.crosswordGame);
    AssertUtil.assert(() => UpdateCrosswordGameUtil.hasChanges(crosswordGameUpdate.gameVersion, this.crosswordGame.version));
    const update = await lastValueFrom(
      this.httpClient.post<CrosswordGameUpdate>(this.appSettingsService.appSettings.apiurl + routes.crosswordGame, crosswordGameUpdate),
    );
    if (updateCurrentGame) {
      this.setUpdateOnCurrentGameAndNotifiyListener(update);
    }
    return this.crosswordGame;
  }
  async addChatMessage(addChatMessage: IAddChatMessage) {
    const routes = this.configService.getRoutes();
    const crosswordGameUpdate = await lastValueFrom(
      this.httpClient.post<CrosswordGameUpdate>(
        `${this.appSettingsService.appSettings.apiurl + routes.crosswordGame}/addChatMessage`,
        addChatMessage,
      ),
    );
    this.setUpdateOnCurrentGameAndNotifiyListener(crosswordGameUpdate);
    return this.crosswordGame;
  }
  async startGame(startGame: StartCrosswordGame) {
    const routes = this.configService.getRoutes();
    const crosswordGameUpdate = await lastValueFrom(
      this.httpClient.post<CrosswordGameUpdate>(
        `${this.appSettingsService.appSettings.apiurl + routes.crosswordGame}/startGame`,
        startGame,
      ),
    );
    this.setUpdateOnCurrentGameAndNotifiyListener(crosswordGameUpdate);
    return this.crosswordGame;
  }
  async stopGame(stopGame: StopCrosswordGame) {
    const routes = this.configService.getRoutes();
    const crosswordGameUpdate = await lastValueFrom(
      this.httpClient.post<CrosswordGameUpdate>(`${this.appSettingsService.appSettings.apiurl + routes.crosswordGame}/stopGame`, stopGame),
    );
    this.setUpdateOnCurrentGameAndNotifiyListener(crosswordGameUpdate);
    return this.crosswordGame;
  }
  async joinGame(joinGame: JoinCrosswordGame) {
    const routes = this.configService.getRoutes();
    const crosswordGameUpdate = await lastValueFrom(
      this.httpClient.post<CrosswordGameUpdate>(`${this.appSettingsService.appSettings.apiurl + routes.crosswordGame}/joinGame`, joinGame),
    );
    this.setUpdateOnCurrentGameAndNotifiyListener(crosswordGameUpdate);
    return this.crosswordGame;
  }
  async findGame(findGame: FindCrosswordGame) {
    const routes = this.configService.getRoutes();
    return lastValueFrom(
      this.httpClient.post<string>(`${this.appSettingsService.appSettings.apiurl + routes.crosswordGame}/findGame`, findGame),
    );
  }
  async leaveGame(leaveGame: LeaveCrosswordGame) {
    const routes = this.configService.getRoutes();
    const crosswordGameUpdate = await lastValueFrom(
      this.httpClient.post<CrosswordGameUpdate>(
        `${this.appSettingsService.appSettings.apiurl + routes.crosswordGame}/leaveGame`,
        leaveGame,
      ),
    );
    this.setUpdateOnCurrentGameAndNotifiyListener(crosswordGameUpdate);
    return this.crosswordGame;
  }
  async checkIfCrosswordGameIsUpdated() {
    const routes = this.configService.getRoutes();
    const path = `${this.appSettingsService.appSettings.apiurl}${routes.crosswordGame}`;
    const update = await this.handleOffline(
      async () =>
        await lastValueFrom(
          this.httpClient.get<CrosswordGameUpdate>(
            `${path}/checkForChanges/${this.crosswordGame._id}/version/${JSON.stringify(this.crosswordGame.version)}`,
          ),
        ),
    );
    if (update?.gameVersion) {
      this.setUpdateOnCurrentGameAndNotifiyListener(update);
    }
  }

  async getCrosswordGamesForPlayer(player: IPlayer) {
    const routes = this.configService.getRoutes();
    const crosswordGames = await lastValueFrom(
      this.httpClient.get<ICrosswordGame[]>(this.appSettingsService.appSettings.apiurl + `${routes.crosswordGame}/player/${player._id}`),
    );
    return crosswordGames;
  }

  private setUpdateOnCurrentGameAndNotifiyListener(update: CrosswordGameUpdate) {
    if (UpdateCrosswordGameUtil.hasChanges(update.gameVersion, this.crosswordGame.version)) {
      const crosswordGame = UpdateCrosswordGameUtil.getUpdateGame(this.crosswordGame, update);
      this.setCrosswordGame(crosswordGame);
      this.crosswordGameSubject.next(crosswordGame);
      this.checkCrosswordChangedTimer.reset();
    }
  }
  private async handleOffline<T>(action: () => T) {
    try {
      const actionResult = await action();
      this.componentCommunicationService.noConnectionToServer.next(false);
      return actionResult;
    } catch (error) {
      if ((error.status >= 502 && error.status <= 504) || error.status == 0) {
        this.componentCommunicationService.noConnectionToServer.next(true);
      } else if (error.status == 401) {
        this.router.navigateByUrl('/login');
      } else {
        throw error;
      }
    }
  }
  private getPlayer(): IPlayer {
    return this.userService.getLoggedIn();
  }
  private setCrosswordGame(crosswordGame: ICrosswordGame) {
    this.crosswordGame = crosswordGame;
    this.gameHandleCrosswordView.setCrosswordGame(crosswordGame);
    this.crosswordGamePlay.setCrosswordGame(crosswordGame);
  }
  private async loadAndSetCrosswordGameStatistics() {
    this.crosswordGameStats = await this.crosswordGameStatsService.getCrosswordStats(this.crosswordGame.initial.crossword._id);
  }
}
