import { PriorityValue } from '../../data/priority.value';
import { QuestionBasePriority } from '../../model/cwquestion';
import { AssertUtil } from '../../util/assert.util';
import { QuestionCharsId } from '../models/build.models';

export interface ScoreQuestionChar {
  perCrossing: number;
  perChar: number;
}
class PointsPerPrioriority {
  private static priorities: number[] = [];
  static readonly Theme = new PointsPerPrioriority(PriorityValue.Theme, 5000);
  static readonly High = new PointsPerPrioriority(PriorityValue.High, 500);
  static readonly Medium = new PointsPerPrioriority(PriorityValue.Medium, 200);
  static readonly Low = new PointsPerPrioriority(PriorityValue.Low, 100);

  constructor(priority: PriorityValue, public readonly points: number) {
    PointsPerPrioriority.priorities[priority] = points;
  }
  static getPoints(priorityValue: PriorityValue) {
    const value = PointsPerPrioriority.priorities[priorityValue];
    AssertUtil.assert(() => value != null);
    return value;
  }
}
export class PriorityScore {
  static getScoreForQuestion(questionCharsId: QuestionCharsId): ScoreQuestionChar {
    const perCrossing = PointsPerPrioriority.getPoints(questionCharsId.priority);
    return { perChar: perCrossing / 100, perCrossing: perCrossing };
  }
  static getScoreForQuestionBase(question: QuestionBasePriority): ScoreQuestionChar {
    const perCrossing = PointsPerPrioriority.getPoints(question.priority);
    return { perChar: perCrossing / 100, perCrossing: perCrossing };
  }
  static getPrioryScore(priorityValue: PriorityValue): ScoreQuestionChar {
    const perCrossing = PointsPerPrioriority.getPoints(priorityValue);
    return { perChar: perCrossing / 100, perCrossing: perCrossing };
  }
}
