<mat-dialog-content>
  <div>{{ 'messages.add-cwquestion-direction' | translate }}</div>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxFlex fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button cdkFocusInital [mat-dialog-close]="AskDirection.Down">
      {{ 'buttons.add-question-down' | translate }}
    </button>
    <button mat-raised-button [mat-dialog-close]="AskDirection.Side">{{ 'buttons.add-question-side' | translate }}</button>
    <button mat-raised-button [mat-dialog-close]="AskDirection.Cancel">
      {{ 'buttons.cancel' | translate }}
    </button>
  </div>
</mat-dialog-actions>
