import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GamePlayerColor } from '../../../../../../Common/model/crossword.game';
import { PlayerMatchColor } from '../../util/player.match.color';

export interface SelectPlayerColorResult {
  gamePlayerColor: GamePlayerColor;
  rememberDecision: boolean;
}

export interface SelectPlayerColor {
  id: string;
  player: GamePlayerColor;
  color: string;
  text: string;
}
export interface SelectPlayerColorData {
  gamePlayerColorsAvailable: GamePlayerColor[];
  playersPrimaryColorIsTaken: boolean;
  allowCancel: boolean;
}

@Component({
  selector: 'app-select-player-color',
  templateUrl: './select-player-color.component.html',
  styleUrls: ['./select-player-color.component.scss'],
})
export class SelectPlayerColorComponent {
  colors: SelectPlayerColor[][] = [];
  selectedColor: SelectPlayerColor;
  rememberDecision: boolean;
  readonly playersPrimaryColorIsTaken: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SelectPlayerColorData,
    private matDialogRef: MatDialogRef<SelectPlayerColorComponent>,
  ) {
    const playerColorsPerColumn = Math.ceil(data.gamePlayerColorsAvailable.length / 2);
    for (let i = 0; i < data.gamePlayerColorsAvailable.length; i++) {
      const gamePlayer = data.gamePlayerColorsAvailable[i];
      const arrayIndex = i < playerColorsPerColumn ? 0 : 1;
      this.colors[arrayIndex] = this.createArrayIfNotCreated(this.colors[arrayIndex]);
      this.colors[arrayIndex].push(this.getSelectColor(gamePlayer));
    }
    this.playersPrimaryColorIsTaken = data.playersPrimaryColorIsTaken;
    this.rememberDecision = this.playersPrimaryColorIsTaken ? false : true;
  }
  onCloseDialogWithValue() {
    const value: SelectPlayerColorResult = { gamePlayerColor: this.selectedColor.player, rememberDecision: this.rememberDecision };
    this.matDialogRef.close(value);
  }
  onCancelDialog() {
    this.matDialogRef.close(null);
  }
  private createArrayIfNotCreated(array: SelectPlayerColor[]) {
    return array ? array : [];
  }
  private getSelectColor(gamePlayer: GamePlayerColor): SelectPlayerColor {
    return {
      player: gamePlayer,
      color: PlayerMatchColor.getColor(gamePlayer, false),
      text: '',
      id: this.getIdFromGamePlayerColor(gamePlayer),
    };
  }
  private getIdFromGamePlayerColor(gamePlayer: GamePlayerColor) {
    switch (gamePlayer) {
      case GamePlayerColor.Default:
        return 'default';
      case GamePlayerColor.PlayerColor1:
        return 'PlayerColor1';
      case GamePlayerColor.PlayerColor2:
        return 'PlayerColor2';
      case GamePlayerColor.PlayerColor3:
        return 'PlayerColor3';
      case GamePlayerColor.PlayerColor4:
        return 'PlayerColor4';
      default:
        throw new Error('Unsupported game player color' + gamePlayer);
    }
  }
}
