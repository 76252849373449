import { RandomizeUtil } from '../builder/util/randomize.util';
import { KrysskampenConstants } from '../const/krysskampen.constants';
import { GameState } from '../data/game.state';
import { PlayerInfo } from '../data/player.score';
import { GamePlayerColor, ICrosswordGame } from '../model/crossword.game';
import { IPlayer } from '../model/player';
import { PlayerSettings } from '../model/player.settings';
import { AssertUtil } from './assert.util';
import { CrosswordGameUtil } from './crossword.game.util';
import { IdUtil } from './id.util';

export class CrosswordGamePlayerUtil {
  static isPlayerColorDefault(gamePlayerColor: GamePlayerColor) {
    return GamePlayerColor.Default == gamePlayerColor;
  }
  static isPlayerColorAvailable(crosswordGame: ICrosswordGame, gamePlayerColor: GamePlayerColor) {
    const availableColors = CrosswordGameUtil.getAvailablePlayerColors(crosswordGame);
    return availableColors.find((c) => c == gamePlayerColor) != null;
  }
  static isPlayerInCrosswordGame(crosswordGame: ICrosswordGame, player: IPlayer): boolean {
    return crosswordGame.playersInfo.players.find((playerTmp) => IdUtil.idEquals(playerTmp._id, player._id)) != null;
  }
  static findCurrentPlayerInfo(crosswordGame: ICrosswordGame) {
    return this.getPlayerInfo(crosswordGame, crosswordGame.turns.currentPlayerId);
  }
  static getPlayersPlayerInfo(player: IPlayer, playerSettings: PlayerSettings): PlayerInfo {
    const gamePlayerColor = playerSettings.preferredPlayerColor ?? GamePlayerColor.Default;
    return CrosswordGamePlayerUtil.getInitialPlayersInfo(player, gamePlayerColor);
  }
  public static getInitialPlayersInfo(player: IPlayer, gamePlayerColor: GamePlayerColor): PlayerInfo {
    return {
      playerId: player._id,
      gamePlayerColor: gamePlayerColor,
      lastScore: 0,
      score: 0,
      storedChars: 0,
      percentageCorrect: 0,
      showNumberOfChars: KrysskampenConstants.PlayerCanShowNumberOfChars,
    };
  }
  static getPlayerInfo(crosswordGame: ICrosswordGame, playerId: any) {
    return crosswordGame.playersInfo.playerInfo.find((playerInfo) => IdUtil.idEquals(playerInfo.playerId, playerId));
  }
  static addPlayerToGame(crosswordGame: ICrosswordGame, player: IPlayer, playerInfo: PlayerInfo) {
    AssertUtil.assert(() => crosswordGame.global.state == GameState.Created);
    AssertUtil.assert(() => !this.getPlayerInfo(crosswordGame, player._id));
    crosswordGame.playersInfo.players.push(player);
    crosswordGame.playersInfo.playerInfo.push(playerInfo);
  }
  static setPlayerToGamePlayerColor(crosswordGame: ICrosswordGame, player: IPlayer, gamePlayerColor: GamePlayerColor) {
    const playerInfo = this.getPlayerInfo(crosswordGame, player._id);
    playerInfo.gamePlayerColor = gamePlayerColor;
  }
  static getPlayer(crosswordGame: ICrosswordGame, playerId: any) {
    const allPlayers = CrosswordGamePlayerUtil.getAllPlayers(crosswordGame);
    for (const player of allPlayers) {
      if (IdUtil.idEquals(player._id, playerId)) {
        return player;
      }
    }
    return null;
  }
  static getAllPlayers(crosswordGame: ICrosswordGame) {
    return [...crosswordGame.playersInfo.players, ...crosswordGame.playersInfo.inactivePlayers];
  }

  static removePlayerFromCrosswordGame(player: IPlayer, crosswordGame: ICrosswordGame) {
    crosswordGame.playersInfo.players = crosswordGame.playersInfo.players.filter(
      (playerTmp) => !IdUtil.idEquals(playerTmp._id, player._id)
    );
    crosswordGame.playersInfo.inactivePlayers.push(player);
  }
  static getRandomPlayer(crosswordGame: ICrosswordGame) {
    const index = RandomizeUtil.getRandomNumber(0, crosswordGame.playersInfo.players.length);
    return crosswordGame.playersInfo.players[index];
  }
  static getCurrentPlayer(crosswordGame: ICrosswordGame) {
    return this.getPlayer(crosswordGame, crosswordGame.turns.currentPlayerId);
  }
  static isCreatorOfGame(crosswordGame: ICrosswordGame, player: IPlayer) {
    return IdUtil.idEquals(crosswordGame.initial.createdByPlayerId, player._id);
  }
  static isCurrentPlayer(crosswordGame: ICrosswordGame, player: IPlayer) {
    return this.isCurrentPlayerId(crosswordGame, player._id);
  }
  static isCurrentPlayerId(crosswordGame: ICrosswordGame, playerId: string) {
    return IdUtil.idEquals(crosswordGame.turns.currentPlayerId, playerId);
  }
}
