import { Component, isDevMode, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { ErrorResult, ErrorType } from '../../../../../../Common/data/error.result';
import { GamePlayerColor } from '../../../../../../Common/model/crossword.game';
import { IPlayer } from '../../../../../../Common/model/player';
import { PlayerSettings } from '../../../../../../Common/model/player.settings';
import { CWQuestionService } from '../../../services/cwquestion/cwquestion.service';
import { DevelopService } from '../../../services/develop/develop.service';
import { GenerateCrosswordService } from '../../../services/generatecrossword/generate-crossword.service';
import { PushNotificationService } from '../../../services/pushnotifcation/push-notification.service';
import { ReportErrorService } from '../../../services/reporterror/report-error.service';
import { UserService } from '../../../services/user/user.service';
import { PropertyErrorStateMatcher } from '../../../util/property.error.state.matcher';
import { FlexibleButtonInMenu, FlexibleButtonType } from '../../common/flexable-button-menu/model/flexible.button.in.menu';
import { MessageBoxType } from '../../dialog/message-box-content/message-box-content.component';
import { DialogService } from '../../dialog/service/dialog.service';
import { ComponentCommunicationService } from '../../../services/componentcommunication/component-communication.service';
import { UserCodeDialogService } from '../../dialog/service/user-code-dialog.service';
import { UserCodeType } from '../../dialog/number-code/number-code.component';

export interface PlayerSettingsInfo {
  name: string;
  email: string;
  onlyImportentNotifications: boolean;
}

@Component({
  selector: 'app-player-settings',
  templateUrl: './player-settings.component.html',
  styleUrls: ['./player-settings.component.scss'],
})
export class PlayerSettingsComponent implements OnInit {
  confirmPasswordMatcher = new PropertyErrorStateMatcher('notTheSamePassword');
  confirmPasswordValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    const pass = control.get('newPassword').value;
    const confirmPassword = control.get('confirmNewPassword').value;
    return pass === confirmPassword ? null : { notTheSamePassword: true };
  };
  playerInfo: BehaviorSubject<PlayerSettingsInfo>;

  updatePlayerInfo: UntypedFormGroup;
  updatePassword: UntypedFormGroup;
  adminPlayer = false;
  adminActionButtons: FlexibleButtonInMenu[];
  playerSettingsButtons: FlexibleButtonInMenu[] = [];
  constructor(
    private userService: UserService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private reportErrorService: ReportErrorService,
    private cwQuestionService: CWQuestionService,
    private developService: DevelopService,
    private generateCrosswordService: GenerateCrosswordService,
    private router: Router,
    private notificationService: PushNotificationService,
    private componentCommunicationService: ComponentCommunicationService,
    private userCodeaDialogService: UserCodeDialogService,
  ) {
    this.componentCommunicationService.currentRouteTitle.next(this.translateService.instant('titles.player-settings'));
  }
  async ngOnInit() {
    const playerSettings = this.userService.getPlayerSettings();
    const player = this.userService.getLoggedIn();
    this.adminPlayer = playerSettings.administrator != null ? playerSettings.administrator : false;
    this.playerInfo = new BehaviorSubject<PlayerSettingsInfo>(this.getPlayerInfo(player, playerSettings));
    this.refreshAdminActionButtons();
    await this.refreshPlayerSettingsButtons();
    this.createForms();
  }

  async onLogErrorsToConsole() {
    const errors = await this.reportErrorService.getErrorReportsOnServer();
    console.log('**** Logging errors stored on server: ****');
    errors.forEach((error) => {
      console.log(`Error reportedBy:${error.playerName} on date:${error.date} with message:${error.message}`);
      console.log(error.stack);
      console.log('End of error');
    });
    console.log('');
  }
  async onClearErrors() {
    await this.reportErrorService.clearReportsOnServer();
    console.log('Logging errors cleared on server:');
  }
  async onExportQuestions() {
    const questions = await this.cwQuestionService.loadAllQuestions();
    const blob = new Blob([JSON.stringify(questions)], { type: 'text/json' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }
  async onSetupDevelopDatabase() {
    await this.dialogService.wrapInProgress(async () => {
      await this.developService.clearDatabase();
      await this.developService.setupMediumDatabase();
    }, this.translateService.instant('messages.setting-up-develop-database'));
    await this.userService.logout();
    this.router.navigateByUrl('/login');
  }
  async onCreateRandomCrosswords() {
    this.dialogService.wrapInProgress(async () => {
      await this.generateCrosswordService.generateRandomCrossword();
    }, this.translateService.instant('messages.generating-random-crosswords'));
  }
  async onUpdatePlayerInfo() {
    await this.dialogService.wrapInProgress(async () => {
      const controls = this.updatePlayerInfo.controls;
      const playerSettings = this.userService.getPlayerSettings();
      const playerTmp = this.userService.getLoggedIn();
      playerSettings.onlyImportentNotifications = controls.onlyImportentNotifications.value;
      const player: IPlayer = { ...playerTmp, email: controls.email.value, name: controls.name.value };
      await this.userService.updatePlayer(player);
      await this.userService.savePlayerSettings();
      this.playerInfo.next(this.getPlayerInfo(player, playerSettings));
    }, this.translateService.instant('messages.update-player-progress'));
    await this.dialogService.showSnackbar(this.translateService.instant('messages.update-player-complete'), 3000);
  }
  async onUpdatePassword() {
    try {
      await this.dialogService.wrapInProgress(async () => {
        const controls = this.updatePassword.controls;
        await this.userService.updateUserPassword({ newPassword: controls.newPassword.value, oldPassword: controls.oldPassword.value });
      }, this.translateService.instant('messages.resetting-password'));
      this.dialogService.showSnackbar(this.translateService.instant('messages.password-changed'), 3000);
    } catch (error) {
      const errorResult: ErrorResult = error.error;
      if (errorResult?.type === ErrorType.OLD_PASSWORD_DOSNT_MATCH) {
        this.dialogService.showMessageBox('', this.translateService.instant('errors.old-password-dosnt-match'), MessageBoxType.Error);
      } else {
        throw error;
      }
    }
  }
  private getPlayerInfo(player: IPlayer, playerSettings: PlayerSettings): PlayerSettingsInfo {
    return {
      name: player.name,
      email: player.email,
      onlyImportentNotifications: playerSettings.onlyImportentNotifications,
    };
  }
  private createForms() {
    this.updatePlayerInfo = new UntypedFormGroup({
      name: new UntypedFormControl(this.playerInfo.value.name, [Validators.required]),
      email: new UntypedFormControl(this.playerInfo.value.email, [Validators.required, Validators.email]),
      onlyImportentNotifications: new UntypedFormControl(this.playerInfo.value.onlyImportentNotifications),
    });
    this.updatePassword = new UntypedFormGroup(
      {
        oldPassword: new UntypedFormControl('', [Validators.required]),
        newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
        confirmNewPassword: new UntypedFormControl('', [Validators.required]),
      },
      { validators: [this.confirmPasswordValidator] },
    );
  }
  private refreshAdminActionButtons() {
    this.adminActionButtons = [];
    this.adminActionButtons.push({
      id: 'log-errors',
      text: this.translateService.instant('buttons.log-errors'),
      icon: 'print',
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onLogErrorsToConsole() },
    });
    this.adminActionButtons.push({
      id: 'clear-errors',
      text: this.translateService.instant('buttons.clear-errors'),
      icon: 'clear',
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onClearErrors() },
    });
    this.adminActionButtons.push({
      id: 'export-questions',
      text: this.translateService.instant('buttons.export-questions'),
      icon: 'file_download',
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onExportQuestions() },
    });
    this.adminActionButtons.push({
      id: 'setup-develop-database',
      text: this.translateService.instant('buttons.setup-develop-database'),
      icon: 'update',
      type: FlexibleButtonType.Standard,
      visible: isDevMode(),
      standardAction: { event: () => this.onSetupDevelopDatabase() },
    });
    this.adminActionButtons.push({
      id: 'create-random-crosswords',
      text: this.translateService.instant('buttons.create-random-crosswords'),
      icon: 'create',
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onCreateRandomCrosswords() },
    });
  }
  private async refreshPlayerSettingsButtons() {
    this.playerSettingsButtons = [];
    const hasVerifiedCreatedUser = await this.userService.hasVerifiedCreatedUser();
    if (!hasVerifiedCreatedUser) {
      this.playerSettingsButtons.push({
        id: 'verify-created-user',
        text: this.translateService.instant('buttons.verify-created-user'),
        icon: 'check',
        type: FlexibleButtonType.Standard,
        standardAction: { event: () => this.onVerifyCreatedUser() },
      });
    }
    if (this.notificationService.supportsPushNotification()) {
      const hasSubscription = await this.notificationService.hasSubscription();
      this.playerSettingsButtons.push({
        id: 'enable-push-subscription',
        text: this.translateService.instant('buttons.enable-push-subscription'),
        icon: 'notification_add',
        visible: !hasSubscription,
        type: FlexibleButtonType.Standard,
        standardAction: { event: () => this.onSubscribeToPushSubsribtion() },
      });
      this.playerSettingsButtons.push({
        id: 'disable-push-subscription',
        text: this.translateService.instant('buttons.disable-push-subscription'),
        icon: 'notifications_off',
        visible: hasSubscription,
        type: FlexibleButtonType.Standard,
        standardAction: { event: () => this.dialogService.wrapInProgress(() => this.notificationService.unsubscribeToNotifications()) },
      });
    }
    this.playerSettingsButtons.push({
      id: 'clear-player-settings',
      text: this.translateService.instant('buttons.clear-player-settings'),
      icon: 'clear',
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.clearPlayerSettings() },
    });
  }
  async onSubscribeToPushSubsribtion() {
    const result = await this.notificationService.subscribeToNotifications();
    if (result) {
      this.refreshPlayerSettingsButtons();
    }
  }
  private clearPlayerSettings(): void {
    this.userService.setPlayerSettings({
      ...this.userService.getPlayerSettings(),
      fireBaseToken: null,
      helpInstructionsDismissed: [],
      onlyImportentNotifications: true,
      preferredPlayerColor: GamePlayerColor.Default,
      sendNotificationsByEmailWhenNoPush: false,
      settingsQuestionsDismissed: [],
      webSubscription: null,
    });
    this.dialogService.wrapInProgress(() => this.userService.savePlayerSettings());
  }
  private async onVerifyCreatedUser() {
    const userCodeResult: number = await this.userCodeaDialogService.showAskUserCode({
      type: UserCodeType.VerifyEmail,
    });
    if (!userCodeResult) {
      return;
    }
    await this.dialogService.wrapInProgress(
      () => this.userService.verifyCreatedUser({ code: userCodeResult }),
      this.translateService.instant('messages.verify-create-player'),
    );
  }
}
