import { AccuracyParameters } from '../../data/generate.crossword.data';
import { PriorityValue } from '../../data/priority.value';
import { ReMatrix, ReQuestion } from '../util/re.matrix';
import { ReCrosswordBuildAction } from './re.build.action';

interface CrossingPercentQuestion {
  question: ReQuestion;
  crossingPercent: number;
}

export class ReRemoveQuestionsWithToLittleCrossings implements ReCrosswordBuildAction {
  constructor(private readonly accuracy: AccuracyParameters) {}

  run(matrix: ReMatrix, iteration: number) {
    if (iteration > this.accuracy.randomQResets / 2) {
      return matrix;
    }
    const questions = matrix.getQuestions().filter((q) => q.priority != PriorityValue.Theme);
    const questionsPercent = questions.map((q) => this.getCrossingPercentForQuestion(matrix, q));
    const questionsWithToLittleCrossings = questionsPercent.filter((q) => q.crossingPercent < 100);
    const questionsWithToLittleCrossingsSorted = questionsWithToLittleCrossings.sort(
      (value1, value2) => value1.crossingPercent - value2.crossingPercent
    );
    const countToRemove = questionsWithToLittleCrossings.length * (this.accuracy.removePercent / 100);
    const removeQuestions = questionsWithToLittleCrossingsSorted.slice(0, countToRemove);
    removeQuestions.forEach((q) => matrix.removeQuestion(q.question.indexId));
    return matrix;
  }

  private getCrossingPercentForQuestion(matrix: ReMatrix, question: ReQuestion): CrossingPercentQuestion {
    const crossings = matrix.getCrossingsForQuestion(question.indexId);
    const crossingsNeeded = this.getCrossingsNeeded(question);
    return { question, crossingPercent: (crossings / crossingsNeeded) * 100 };
  }

  private getCrossingsNeeded(question: ReQuestion) {
    const calculatedCrossingsNeeded = this.getCalculatedCrossingsNeeded(question.answer);
    return Math.min(this.accuracy.crossingsNeeded, calculatedCrossingsNeeded);
  }
  private getCalculatedCrossingsNeeded(chars: string) {
    if (chars.length < 3) {
      return chars.length;
    } else {
      return Math.round(chars.length / 2);
    }
  }
}
