import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
export class LocalizedPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  firstPageLabel: string;
  itemsPerPageLabel: string;
  lastPageLabel: string;
  nextPageLabel: string;
  previousPageLabel: string;

  constructor(private translateService: TranslateService) {
    this.firstPageLabel = this.translateService.instant('paginator.first-page');
    this.itemsPerPageLabel = this.translateService.instant('paginator.items-per-page');
    this.lastPageLabel = this.translateService.instant('paginator.last-page');
    this.nextPageLabel = this.translateService.instant('paginator.next-page');
    this.previousPageLabel = this.translateService.instant('paginator.previous-page');
  }

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translateService.instant('paginator.current-page', { page: 1, amountPages: 1 });
    }
    const amountPages = Math.ceil(length / pageSize);
    return this.translateService.instant('paginator.current-page', { page: page + 1, amountPages: amountPages });
  }
}
