import { AfterContentInit, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Interval } from '../../../../../../../Common/data/interval';
import { Direction } from '../../../../../../../Common/data/position';
import { ICWQuestionBase } from '../../../../../../../Common/model/cwquestion';
import { FilterCWQuestion } from '../../../../../../../Common/util/cwquestion.util';
import { CrosswordTile, CrosswordTileEvent, TileContainerType, TileEventType, TileType, TilesState } from '../../../../models/tile';
import { CrosswordConstants } from '../../../util/crossword.constants';
import { RowInFocusChangedTileEventType, RowKeyUpTileEventType } from '../../../util/handleCrosswordView/game.handle.event.types';
import { IHandleRowTileEventType } from '../../../util/handleCrosswordView/handle.crossword.view';
import { SizeChanged } from '../../../util/resize.directive';
import { TileUtil } from '../../../util/tile.util';
function getValueAsArrayFromTiles(tiles: CrosswordTile[]) {
  const valueAsArray: string[] = [];
  for (let i = 0; i < tiles.length; i++) {
    const tile = tiles[i];
    if (tile.type == TileType.Input) {
      valueAsArray[i] = tile.text;
    } else if (tile.type == TileType.SetChar) {
      const setChar = i == 0 || valueAsArray[i - 1];
      if (setChar) {
        valueAsArray[i] = tile.text;
      }
    }
  }
  return valueAsArray;
}
function convertArrayOfStringsToString(valueAsArray: string[]) {
  let value = '';
  for (let i = 0; i < valueAsArray.length; i++) {
    if (valueAsArray) value += valueAsArray[i] ? valueAsArray[i] : ' ';
  }
  return value.trimRight();
}
class TextAddedTileEventType implements IHandleRowTileEventType {
  constructor(private answer: UntypedFormControl) {}
  handleRowTileEventType(tileEvent: CrosswordTileEvent, state: TilesState, tiles: CrosswordTile[]): TilesState {
    const valueAsArray = getValueAsArrayFromTiles(tiles);
    valueAsArray[tileEvent.tile.x] = tileEvent.data;
    this.answer.setValue(convertArrayOfStringsToString(valueAsArray));
    return state;
  }
}
class TextRemovedTileEventType implements IHandleRowTileEventType {
  constructor(private answer: UntypedFormControl) {}
  handleRowTileEventType(tileEvent: CrosswordTileEvent, state: TilesState, tiles: CrosswordTile[]): TilesState {
    const valueAsArray = getValueAsArrayFromTiles(tiles);
    valueAsArray[tileEvent.tile.x] = '';
    this.answer.setValue(convertArrayOfStringsToString(valueAsArray));
    return state;
  }
}

export interface EditQuestionTiles {
  question: ICWQuestionBase;
  filter?: FilterCWQuestion;
}

@Component({
  selector: 'app-edit-question-tiles',
  templateUrl: './edit-question-tiles.component.html',
})
export class EditQuestionTilesComponent implements AfterContentInit {
  TileContainerType = TileContainerType;
  tiles: CrosswordTile[];
  tilesState: TilesState;
  tileSize: number;
  @Input()
  editQuestionData: EditQuestionTiles;
  @Input()
  answer: UntypedFormControl;

  private readonly handleTileEventTypes: IHandleRowTileEventType[] = [];

  ngAfterContentInit(): void {
    const cwQuestion = this.editQuestionData.question;
    this.setHandleEventTypes();

    this.tilesState = {
      canAddChars: true,
      focused: {
        tile: null,
        cwQuestion: cwQuestion,
        interval: new Interval(0, 0, Direction.MAINAXIS, CrosswordConstants.MaxNumberOfChars),
      },
    };
    const numberOfTilesToCreate = this.getNumberOfTilesToCreate();
    const tiles = TileUtil.createRowOfCrosswordTiles(numberOfTilesToCreate);
    this.setAnswerOnTiles(tiles, cwQuestion);
    if (this.editQuestionData.filter) {
      this.applyFilter(tiles);
      setTimeout(() => {
        const valueAsArray = getValueAsArrayFromTiles(tiles);
        this.answer.setValue(convertArrayOfStringsToString(valueAsArray));
        this.answer.updateValueAndValidity();
      }, 0);
    }
    this.tiles = tiles;
  }
  private setAnswerOnTiles(tiles: CrosswordTile[], cwQuestion: ICWQuestionBase) {
    for (let i = 0; i < tiles.length; i++) {
      const tile = tiles[i];
      tile.cwQuestions.push(cwQuestion);
      tile.text = i >= this.answer.value.length ? '' : this.answer.value[i].toUpperCase();
      tile.type = TileType.Input;
    }
  }

  private applyFilter(tiles: CrosswordTile[]) {
    const filter = this.editQuestionData.filter;
    for (let i = 0; i < tiles.length; i++) {
      const tile = tiles[i];

      if (i < filter.regExFilter.length) {
        const filterChar = filter.regExFilter[i];
        if (filterChar != '.') {
          tile.type = TileType.SetChar;
          tile.text = filterChar;
        }
      } else {
        tile.type = TileType.Empty;
      }
    }
  }

  onTileEvent(tileEvent: CrosswordTileEvent) {
    this.tilesState = this.handleTileEvent(tileEvent, this.tilesState, this.tiles);
  }

  onSizeChanged(size: SizeChanged) {
    this.tileSize = size.width / CrosswordConstants.MaxNumberOfChars;
  }
  private handleTileEvent(tileEvent: CrosswordTileEvent, state: TilesState, tiles: CrosswordTile[]) {
    const handleEvent = this.handleTileEventTypes[tileEvent.event];
    if (handleEvent != null) {
      return handleEvent.handleRowTileEventType(tileEvent, state, tiles);
    }
    throw new Error('Unhandled row event:' + tileEvent);
  }
  private setHandleEventTypes() {
    this.handleTileEventTypes[TileEventType.KeyUp] = new RowKeyUpTileEventType(() => true);
    this.handleTileEventTypes[TileEventType.InFocus] = new RowInFocusChangedTileEventType();
    this.handleTileEventTypes[TileEventType.TextAdded] = new TextAddedTileEventType(this.answer);
    this.handleTileEventTypes[TileEventType.TextRemoved] = new TextRemovedTileEventType(this.answer);
  }
  private getNumberOfTilesToCreate() {
    if (this.editQuestionData.filter) {
      return this.editQuestionData.filter.lengths[this.editQuestionData.filter.lengths.length - 1];
    } else {
      return CrosswordConstants.MaxNumberOfChars;
    }
  }
}
