<div fxFlexFill fxLayout="column" fxLayoutGap="10px">
  <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap="10px">
    <mat-form-field fxFlex>
      <mat-label>{{ 'labels.filter-questions' | translate }}</mat-label>
      <input #filterInput matInput (keyup)="applyFilter(filterInput.value)" autocomplete="off" />
    </mat-form-field>
    <app-select-filter-questions
      fxFlex
      [questionIds]="filterQuestionIds"
      [showPriorites]="type == SelectCWQuestionType.Selected"
      (filterQuestions)="onFilterQuestionsChanged($event)"
    ></app-select-filter-questions>
  </div>
  <app-flexable-button-menu [buttons]="actionButtons"> </app-flexable-button-menu>
  <div fxFlex fxLayout="column" class="table-container">
    <mat-table
      listenForResize
      (sizeChanged)="onSizeChanged($event)"
      matSort
      #table
      [dataSource]="cwQuestionsModel"
      [ngClass]="{ 'scroll-table': type != SelectCWQuestionType.SelectOne }"
    >
      <ng-container matColumnDef="selection">
        <mat-header-cell *matHeaderCellDef class="w-50">
          <mat-checkbox
            *ngIf="type != SelectCWQuestionType.SelectOne"
            (change)="onSelectedAllQuestions($event.checked)"
            [checked]="isAllQuestionsSelected()"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell class="w-50" *matCellDef="let row">
          <mat-checkbox
            test-id="select-question-checkbox"
            (click)="$event.stopPropagation()"
            (change)="$event ? cwQuestionsSelection.toggle(row.indexId) : null"
            [checked]="cwQuestionsSelection.isSelected(row.indexId)"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="question">
        <mat-header-cell *matHeaderCellDef mat-sort-header="question">{{ 'columns.question' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.question }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="answer">
        <mat-header-cell *matHeaderCellDef mat-sort-header="answer">{{ 'columns.answer' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.answer }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="answerLength">
        <mat-header-cell *matHeaderCellDef mat-sort-header="answerLength" class="w-50">{{
          'columns.answer-length' | translate
        }}</mat-header-cell>
        <mat-cell *matCellDef="let item" class="w-50"> {{ item.answerLength }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tags">
        <mat-header-cell *matHeaderCellDef mat-sort-header="tags">{{ 'columns.tags' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.tags }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef mat-sort-header="category">{{ 'columns.category' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ getCategoryKey(item.category) | translate }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef mat-sort-header="author">{{ 'columns.author' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.author }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="updated">
        <mat-header-cell *matHeaderCellDef mat-sort-header="updated">{{ 'columns.updated' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let item"> {{ item.updated | date: 'yyyy-MM-dd' }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="priority">
        <mat-header-cell *matHeaderCellDef matSortDisabled class="w-60">{{ 'columns.priority' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let item" class="w-60">
          <mat-icon [ngStyle]="{ color: getPriorityCircleColor(item) }"> circle </mat-icon></mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="edit">
        <mat-header-cell *matHeaderCellDef style="max-width: 50px"></mat-header-cell>
        <mat-cell *matCellDef="let item" style="max-width: 50px">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu
            ><button mat-menu-item [disabled]="!canChangeQuestion(item)" (click)="onEditQuestion(item)">
              <mat-icon>edit</mat-icon>
              <span>{{ 'buttons.edit' | translate }}</span>
            </button>
            <button mat-menu-item [disabled]="!canChangeQuestion(item)" (click)="onDeleteQuestion(item)">
              <mat-icon>delete</mat-icon>
              <span>{{ 'buttons.delete' | translate }}</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let cwQuestion; columns: displayedColumns"
        [ngStyle]="{ display: (cwQuestionsModel.loading$ | async) ? 'none' : 'flex' }"
      >
      </mat-row>
    </mat-table>
    <mat-paginator
      [ngStyle]="{ display: (cwQuestionsModel.loading$ | async) || (cwQuestionsModel.allIds | async).length == 0 ? 'none' : 'block' }"
      [pageSize]="loadQuestionsQuery.pageSize"
      [length]="(cwQuestionsModel.allIds | async).length"
      [pageSizeOptions]="[10, 50, 100]"
    ></mat-paginator>
    <div
      *ngIf="(cwQuestionsModel.loading$ | async) == false && (cwQuestionsModel.allIds | async).length == 0"
      style="height: 40px"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <span>{{ 'misc.no-visible-questions' | translate }}</span>
    </div>
    <div *ngIf="cwQuestionsModel.loading$ | async" style="height: 200px" fxLayoutAlign="center center">
      <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
    </div>
  </div>
</div>
