import { Direction, Position, RealDirection, Rectangle } from '../../../../../../../Common/data/position';
import { ICrosswordSize } from '../../../../../../../Common/model/crossword';
import { CrosswordUtil } from '../../../../../../../Common/util/crossword.util';
import { TextWidthUtil } from '../../../util/text.width.util';

export enum ImagePositon {
  Top,
  Bottom,
  Right,
  Left,
}

export class ShowAllImageUtil {
  private readonly crosswordWidth: number;
  private readonly crosswordHeight: number;
  private readonly imageWidth: number;
  private readonly imageHeight: number;
  constructor(
    private crosswordSize: ICrosswordSize,
    private tileSize: number,
    private matrixDirection: Direction,
    private tilePosition: Position,
    private question: string,
    private wantedImageWidth: number,
    private wantedImageHeight: number,
    private padding: number,
  ) {
    this.crosswordWidth = CrosswordUtil.getShowCrossworWidth(this.crosswordSize, this.matrixDirection, this.tileSize);
    this.crosswordHeight = CrosswordUtil.getShowCrosswordHeight(this.crosswordSize, this.matrixDirection, this.tileSize);
    this.imageWidth = this.getWantedWidth();
    this.imageHeight = this.getWantedHeight();
  }
  getImageRectangle(): Rectangle {
    const imagePosition = this.getImagePosition();

    if (imagePosition == ImagePositon.Top) {
      return this.getTopImageRectangle();
    } else if (imagePosition == ImagePositon.Bottom) {
      return this.getBottomImageRectangle();
    } else if (imagePosition == ImagePositon.Left) {
      return this.getLeftImageRectangle();
    } else {
      return this.getRightImageRectangle();
    }
  }

  getImagePosition() {
    const middleX = this.tilePosition.x + this.tileSize / 2;
    const middleY = this.tilePosition.y + this.tileSize / 2;
    const realDirection = CrosswordUtil.getRealQuestionDirection(this.matrixDirection, this.tilePosition.direction);
    if (realDirection == RealDirection.Horizontal) {
      return middleY > this.crosswordHeight / 2 ? ImagePositon.Top : ImagePositon.Bottom;
    }
    return middleX > this.crosswordWidth / 2 ? ImagePositon.Left : ImagePositon.Right;
  }

  private getTopImageRectangle() {
    const x = this.getHorizontalImageX();
    const y = Math.max(this.tilePosition.y - this.imageHeight, 0);
    const width = Math.min(this.imageWidth, this.crosswordWidth - x);
    const height = Math.min(this.imageHeight, this.tilePosition.y);
    return { x: x, y: y, width: width, height: height };
  }

  private getBottomImageRectangle() {
    const x = this.getHorizontalImageX();
    const y = this.tilePosition.y + this.tileSize;
    const width = Math.min(this.imageWidth, this.crosswordWidth - x);
    const height = Math.min(this.imageHeight, this.crosswordHeight - y);
    return { x: x, y: y, width: width, height: height };
  }

  private getHorizontalImageX() {
    if (this.tilePosition.x + this.imageWidth > this.crosswordWidth) {
      return Math.max(this.crosswordWidth - this.imageWidth, 0);
    }
    return this.tilePosition.x;
  }

  private getLeftImageRectangle() {
    const x = Math.max(this.tilePosition.x - this.imageWidth, 0);
    const y = this.getVerticalImageY();
    const width = Math.min(this.imageWidth, this.tilePosition.x - x);
    const height = Math.min(this.imageHeight, this.crosswordHeight - y);
    return { x: x, y: y, width: width, height: height };
  }

  private getRightImageRectangle() {
    const x = this.tilePosition.x + this.tileSize;
    const y = this.getVerticalImageY();
    const width = Math.min(this.imageWidth, this.crosswordWidth - x);
    const height = Math.min(this.imageHeight, this.crosswordHeight - y);
    return { x: x, y: y, width: width, height: height };
  }

  private getVerticalImageY() {
    if (this.tilePosition.y + this.imageHeight > this.crosswordHeight) {
      return Math.max(this.crosswordHeight - this.imageHeight, 0);
    }
    return this.tilePosition.y;
  }

  private getWantedWidth() {
    const textWidth = TextWidthUtil.getTextWidth(this.question, '18px Roboto');
    return Math.max(textWidth, this.wantedImageWidth);
  }

  private getWantedHeight() {
    return this.wantedImageHeight;
  }
}
