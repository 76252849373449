export class DateUtil {
  static nameof = <T>(name: Extract<keyof T, string>): string => name;
  static exchangeDates<T>(values: T[], property: () => string): T[] {
    const newValues: T[] = [];
    for (let i = 0; i < values.length; i++) {
      newValues[i] = this.exchangeDate(values[i], property);
    }
    return newValues;
  }
  static exchangeDate<T>(value: T, property: () => string): T {
    const propertyName = property();
    const obj = { ...value } as any;
    obj[propertyName] = new Date(value[propertyName]);
    return obj as T;
  }
}
