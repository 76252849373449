import { Injectable } from '@angular/core';
import { AddCWQuestionUtil } from '../../../../../../Common/builder/util/add.cwquestion.util';
import { Position } from '../../../../../../Common/data/position';
import { ICrossword } from '../../../../../../Common/model/crossword';
import { ICWQuestion } from '../../../../../../Common/model/cwquestion';
import { CrosswordUtil } from '../../../../../../Common/util/crossword.util';
import { CWQuestionUtil } from '../../../../../../Common/util/cwquestion.util';
import { ObjectUtil } from '../../../../../../Common/util/object.util';
import { EditQuestionDialogService } from '../../dialog/service/edit-question-dialog.service';
import { CrosswordConstants } from '../../util/crossword.constants';

@Injectable({ providedIn: 'root' })
export class EditQuestionInBuildService {
  constructor(private editQuestionDialogService: EditQuestionDialogService) {}
  async editQuestion(crossword: ICrossword, cwQuestion: ICWQuestion) {
    const crosswordTmp = ObjectUtil.copyAndClean(crossword);
    const crosswordQuestion = CrosswordUtil.findCrosswordQuestion(crossword, cwQuestion.base.indexId);
    CrosswordUtil.removeQuestionFromCrossword(crosswordTmp, cwQuestion.base.indexId);
    const addCWQuestionUtil = new AddCWQuestionUtil(crosswordTmp);
    const position = Position.toPosition(crosswordQuestion.position);
    const filter = addCWQuestionUtil.getFilterForQuestions(position);

    const cwQuestionUpdated: ICWQuestion = await this.editQuestionDialogService.showEditQuestionDialog({
      cwQuestion: cwQuestion,
      filter: CWQuestionUtil.removeFiltersLongerThen(filter, CrosswordConstants.MaxNumberOfChars),
    });
    if (cwQuestionUpdated) {
      crosswordTmp.crosswordQuestions.push({
        cwQuestion: cwQuestionUpdated.base,
        position: position,
        priority: crosswordQuestion.priority,
      });
      return crosswordTmp;
    } else {
      return null;
    }
  }
}
