import { Injectable } from '@angular/core';
import { GameNotificationType } from '../../../../../Common/data/crossword.game.notification';
import { ICrosswordGame } from '../../../../../Common/model/crossword.game';
import { IPlayer } from '../../../../../Common/model/player';
import { NotificationUtil } from '../../../../../Common/util/notification.util';
import { GameNotificationTextService } from '../gamenotificationtext/game-notification-text-service.service';

export enum NotificationMessageType {
  Alert,
  SnackBar,
}

export interface NotificationMessage {
  text: string;
  type: NotificationMessageType;
}

export interface HandleNotificationData {
  messages: NotificationMessage[];
}

@Injectable({ providedIn: 'root' })
export class HandleNotificationService {
  constructor(private gameNotificationTextService: GameNotificationTextService) {}

  getNotificationMessages(crosswordGame: ICrosswordGame, crosswordGameChangedTo: ICrosswordGame, player: IPlayer) {
    const newNotifications = NotificationUtil.getNewNotifications(
      crosswordGame.notifications.notifications,
      crosswordGameChangedTo.notifications.notifications,
    );
    const handleNotificationData: HandleNotificationData = { messages: [] };
    for (const notification of newNotifications) {
      if (NotificationUtil.shouldShowInGameNotification(notification, player)) {
        const text = this.gameNotificationTextService.getTextForNotification(player, notification, crosswordGame.global.type);
        if (NotificationUtil.shouldShowInGameNotificationAsAlert(notification)) {
          handleNotificationData.messages.push({ text: text, type: NotificationMessageType.Alert });
        } else {
          handleNotificationData.messages.push({ text: text, type: NotificationMessageType.SnackBar });
        }
      }
    }
    return handleNotificationData;
  }

  shouldUpdateTiles(crosswordGame: ICrosswordGame, crosswordGameChangedTo: ICrosswordGame) {
    const newNotifications = NotificationUtil.getNewNotifications(
      crosswordGame.notifications.notifications,
      crosswordGameChangedTo.notifications.notifications,
    );

    for (const notifaction of newNotifications) {
      switch (notifaction.type) {
        case GameNotificationType.CharsAdded:
        case GameNotificationType.GameCompleted:
        case GameNotificationType.GameStarted:
        case GameNotificationType.CurrentPlayerChanged:
          return true;
      }
    }
    return false;
  }
}
