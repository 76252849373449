<div fxFlexFill fxLayoutAlign="center" fxLayout="column">
  <mat-table *ngIf="loaded" fxFlexFill #table matSort matSortActive="order" matSortDirection="asc" [dataSource]="dataSource">
    <ng-container matColumnDef="playerNames">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'columns.player-names' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let data"> {{ data.playerNames }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="score">
      <mat-header-cell *matHeaderCellDef mat-sort-header ngClass="w-100">{{ 'columns.score' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let data" ngClass="w-100">{{ data.score }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="percentageCorrect">
      <mat-header-cell *matHeaderCellDef mat-sort-header ngClass="w-100">{{ 'columns.percentage-correct' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let data" ngClass="w-100">
        {{ 'columns-value.percentage-correct' | translate: { percentageCorrect: data.percentageCorrect } }}</mat-cell
      >
    </ng-container>
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef class="w-150" mat-sort-header>{{ 'columns.date' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let data" class="w-150">{{ data.date | date: 'yyyy-MM-dd' }}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let playerInfo; columns: displayedColumns"> </mat-row>
  </mat-table>
  <app-progress-bar *ngIf="!loaded" [height]="100"></app-progress-bar>
</div>
