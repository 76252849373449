import { Direction, Position } from '../../data/position';
import { ICrosswordSize } from '../../model/crossword';

export class RandomizeUtil {
  public static shuffle<T>(arrayTmp: T[]) {
    const array = arrayTmp.slice();
    let currentIndex = array.length,
      temporaryValue: T,
      randomIndex: number;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
  public static getRandomBoolean(): boolean {
    return this.getRandomNumber(0, 2) ? true : false;
  }
  public static getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min) + min);
  }

  public static getRandomIntervalPosition(intervalLength: number, maxXOrYPosition: ICrosswordSize) {
    const direction = this.getDirection(intervalLength, maxXOrYPosition);
    const maxX = direction === Direction.CROSSAXIS ? maxXOrYPosition.mainAxis : maxXOrYPosition.mainAxis - intervalLength;
    const maxY = direction === Direction.MAINAXIS ? maxXOrYPosition.crossAxis : maxXOrYPosition.crossAxis - intervalLength;
    const minX = direction === Direction.CROSSAXIS ? 1 : 0;
    const minY = direction === Direction.MAINAXIS ? 1 : 0;

    return new Position(this.getRandomNumber(minX, maxX), this.getRandomNumber(minY, maxY), direction);
  }
  private static getDirection(intervalLength: number, maxXOrYPosition: ICrosswordSize) {
    if (intervalLength > maxXOrYPosition.mainAxis) {
      return Direction.CROSSAXIS;
    }
    if (intervalLength > maxXOrYPosition.crossAxis) {
      return Direction.MAINAXIS;
    }
    return this.getRandomNumber(0, 2);
  }
}
