import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ComponentCommunicationService } from '../componentcommunication/component-communication.service';

interface TimedData<T> {
  time: number;
  data: T;
}

class TypedLocalStorage {
  public static setItem<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  public static getItem<T>(key: string): T {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }
}

class TimedStorage {
  constructor(private devMode: BehaviorSubject<boolean>) {}
  public set<T>(key: string, value: T, ttl: number) {
    const timedData: TimedData<T> = { time: Date.now() + ttl, data: value };
    TypedLocalStorage.setItem(key, timedData);
  }

  public get<T>(key: string) {
    if (this.devMode.getValue()) {
      return null;
    }
    if (!this.has(key)) {
      return null;
    }
    const timedData: TimedData<T> = TypedLocalStorage.getItem(key);
    return timedData.data;
  }
  public has(key: string) {
    if (this.devMode.getValue()) {
      return false;
    }
    const timedData: TimedData<any> = TypedLocalStorage.getItem(key);
    return timedData?.time < Date.now() ?? false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class HttpClientCacheService {
  private readonly cache: TimedStorage;
  constructor(
    private httpClient: HttpClient,
    private communicationService: ComponentCommunicationService,
  ) {
    this.cache = new TimedStorage(this.communicationService.devMode);
  }

  public async get<T>(url: string, ttl: number): Promise<T> {
    if (this.cache.has(url)) {
      return this.cache.get(url);
    }
    const response = await lastValueFrom(this.httpClient.get<T>(url));
    this.cache.set(url, response, ttl);
    return response;
  }
  public async post<T>(url: string, body: any, ttl: number): Promise<T> {
    if (this.cache.has(url)) {
      return this.cache.get(url);
    }
    const response = await lastValueFrom(this.httpClient.post<T>(url, body));
    this.cache.set(url, response, ttl);
    return response;
  }
  public async put<T>(url: string, body: any, ttl: number): Promise<T> {
    if (this.cache.has(url)) {
      return this.cache.get(url);
    }
    const response = await lastValueFrom(this.httpClient.put<T>(url, body));
    this.cache.set(url, response, ttl);
    return response;
  }
}
