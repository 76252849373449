import { AssertUtil } from '../../../../../../../Common/util/assert.util';
import { FlexibleButtonInMenu } from '../model/flexible.button.in.menu';

export class UpdateFlexibleButtonUtil {
  static update(updateTo: FlexibleButtonInMenu, update: FlexibleButtonInMenu) {
    if (update) {
      AssertUtil.assert(() => updateTo.id == update.id);
      updateTo.standardAction = update.standardAction;
    }
    return updateTo;
  }
}
