import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { takeWhile } from 'rxjs';
import { CrosswordGameStats } from '../../../../../../Common/model/crossword.game.stats';
import { ComponentCommunicationService } from '../../../services/componentcommunication/component-communication.service';
import { CrosswordGameStatsService } from '../../../services/crosswordgamestats/crossword.game.stats.service';

export interface CrosswordInformationData {
  playerNames: string;
  score: number;
  percentageCorrect: number;
  date: Date;
}

@Component({
  selector: 'app-crossword-information',
  templateUrl: './crossword-information.component.html',
})
export class CrosswordInformationComponent implements AfterViewInit, OnInit, OnDestroy {
  displayedColumns = ['playerNames', 'score', 'percentageCorrect', 'date'];
  @Input()
  crosswordId: string;
  dataSource = new MatTableDataSource<CrosswordInformationData>();
  loaded = false;
  private alive = true;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private crosswordGameStatsService: CrosswordGameStatsService,
    private componentCommunicationService: ComponentCommunicationService,
    private cdr:ChangeDetectorRef
  ) {}
  ngOnDestroy(): void {
    this.alive = false;
  }
  async ngOnInit() {
    await this.refreshCrosswordStats();
    this.componentCommunicationService.publishedChanged.pipe(takeWhile(() => this.alive)).subscribe(() => this.refreshCrosswordStats());
    this.cdr.markForCheck();
  }
  private async refreshCrosswordStats() {
    this.loaded = false;
     this.dataSource.data = [];
    const crosswordStats = await this.crosswordGameStatsService.getCrosswordStats(this.crosswordId);

    this.dataSource.data = crosswordStats
      .filter((s) => s.published)
      .map((d) => this.mapToCrosswordInformationData(d))
      .sort((d1, d2) => d1.score - d2.score)
      .reverse();
    this.loaded = true;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }
  private mapToCrosswordInformationData(crosswordGameStats: CrosswordGameStats): CrosswordInformationData {
    return {
      playerNames: crosswordGameStats.playerStatistics.map((p) => p.playerName).join(','),
      score: crosswordGameStats.playerStatistics.map((p) => p.score).reduce((s1, s2) => s1 + s2),
      percentageCorrect: crosswordGameStats.completedPercent,
      date: crosswordGameStats.date,
    };
  }
}
