import { CrosswordGameUpdate, CrosswordGameVersion, ICrosswordGame } from '../model/crossword.game';
import { AssertUtil } from './assert.util';
import { CrosswordGameUtil } from './crossword.game.util';
import { IdUtil } from './id.util';
import { ObjectUtil } from './object.util';

interface CrosswordGamePart {
  update(game: ICrosswordGame, update: CrosswordGameUpdate): void;
  bumpVersionOnChanges(update: CrosswordGameUpdate): void;
  hasVersionChanges(newVersion: CrosswordGameVersion, version: CrosswordGameVersion): boolean;
  hasObjectChanges(game: ICrosswordGame, gameBefore: ICrosswordGame): boolean;
  setUpdate(game: ICrosswordGame, update: CrosswordGameUpdate): void;
}
class PlayersInfoPart implements CrosswordGamePart {
  setUpdate(game: ICrosswordGame, update: CrosswordGameUpdate) {
    update.playersInfo = game.playersInfo;
  }
  bumpVersionOnChanges(update: CrosswordGameUpdate) {
    if (update.playersInfo) {
      update.gameVersion.playersInfo++;
    }
  }
  update(game: ICrosswordGame, update: CrosswordGameUpdate) {
    AssertUtil.assert(() => game.version.playersInfo < update.gameVersion.playersInfo);
    ObjectUtil.assignProperties(game.playersInfo, update.playersInfo);
  }
  hasObjectChanges(game: ICrosswordGame, gameBefore: ICrosswordGame) {
    return !ObjectUtil.isObjectEqual(game.playersInfo, gameBefore.playersInfo);
  }
  hasVersionChanges(newVersion: CrosswordGameVersion, version: CrosswordGameVersion) {
    return newVersion.playersInfo > version.playersInfo;
  }
}
class TurnsPart implements CrosswordGamePart {
  setUpdate(game: ICrosswordGame, update: CrosswordGameUpdate) {
    update.turns = game.turns;
  }
  bumpVersionOnChanges(update: CrosswordGameUpdate) {
    if (update.turns) {
      update.gameVersion.turns++;
    }
  }
  update(game: ICrosswordGame, update: CrosswordGameUpdate) {
    AssertUtil.assert(() => game.version.turns < update.gameVersion.turns);
    ObjectUtil.assignProperties(game.turns, update.turns);
    game.reminder.changedCurrentPlayerTimestamp = Date.now();
    game.reminder.remindedOnTimestamp = undefined;
  }
  hasObjectChanges(game: ICrosswordGame, gameBefore: ICrosswordGame) {
    if (game.turns.playerTurns.length > gameBefore.turns.playerTurns.length) {
      return true;
    }
    if (game.global.state != gameBefore.global.state) {
      return true;
    }
    if (game.turns.totalNumberOfTurns != gameBefore.turns.totalNumberOfTurns) {
      return true;
    }
    if (!IdUtil.idEquals(game.turns.currentPlayerId, gameBefore.turns.currentPlayerId)) {
      return true;
    }
    return false;
  }
  hasVersionChanges(newVersion: CrosswordGameVersion, version: CrosswordGameVersion) {
    return newVersion.turns > version.turns;
  }
}
class ChatsPart implements CrosswordGamePart {
  setUpdate(game: ICrosswordGame, update: CrosswordGameUpdate) {
    update.chats = game.chats;
  }
  bumpVersionOnChanges(update: CrosswordGameUpdate) {
    if (update.chats) {
      update.gameVersion.chats++;
    }
  }
  update(game: ICrosswordGame, update: CrosswordGameUpdate) {
    AssertUtil.assert(() => game.version.chats < update.gameVersion.chats);
    ObjectUtil.assignProperties(game.chats, update.chats);
  }
  hasObjectChanges(game: ICrosswordGame, gameBefore: ICrosswordGame) {
    return game.chats.chatMessages.length > gameBefore.chats.chatMessages.length;
  }
  hasVersionChanges(newVersion: CrosswordGameVersion, version: CrosswordGameVersion) {
    return newVersion.chats > version.chats;
  }
}
class NotificationsPart implements CrosswordGamePart {
  private static readonly MaxNumberOfNotifications = 5;
  setUpdate(game: ICrosswordGame, update: CrosswordGameUpdate) {
    update.notifications = game.notifications;
  }
  bumpVersionOnChanges(update: CrosswordGameUpdate) {
    if (update.notifications) {
      update.gameVersion.notifications++;
    }
  }
  update(game: ICrosswordGame, update: CrosswordGameUpdate) {
    AssertUtil.assert(() => game.version.notifications < update.gameVersion.notifications);
    if (!update.notifications) {
      throw new Error('Notifications need to be set');
    }
    const numberOfNotifications = update.notifications.notifications.length;
    game.notifications.notifications = update.notifications.notifications.slice(
      Math.max(numberOfNotifications - NotificationsPart.MaxNumberOfNotifications, 0)
    );
  }
  hasObjectChanges(game: ICrosswordGame, gameBefore: ICrosswordGame) {
    return game.notifications.notifications.length > gameBefore.notifications.notifications.length;
  }
  hasVersionChanges(newVersion: CrosswordGameVersion, version: CrosswordGameVersion) {
    return newVersion.notifications > version.notifications;
  }
}
class GlobalPart implements CrosswordGamePart {
  setUpdate(game: ICrosswordGame, update: CrosswordGameUpdate) {
    update.global = game.global;
  }
  bumpVersionOnChanges(update: CrosswordGameUpdate) {
    if (update.global) {
      update.gameVersion.global++;
    }
  }
  update(game: ICrosswordGame, update: CrosswordGameUpdate) {
    AssertUtil.assert(() => game.version.global < update.gameVersion.global);
    ObjectUtil.assignProperties(game.global, update.global);
  }
  hasObjectChanges(game: ICrosswordGame, gameBefore: ICrosswordGame) {
    return !ObjectUtil.isObjectEqual(game.global, gameBefore.global);
  }
  hasVersionChanges(newVersion: CrosswordGameVersion, version: CrosswordGameVersion) {
    return newVersion.global > version.global;
  }
}
class ReminderPart implements CrosswordGamePart {
  setUpdate(game: ICrosswordGame, update: CrosswordGameUpdate) {
    update.reminder = game.reminder;
  }
  bumpVersionOnChanges(update: CrosswordGameUpdate) {
    if (update.reminder) {
      update.gameVersion.reminder++;
    }
  }
  update(game: ICrosswordGame, update: CrosswordGameUpdate) {
    AssertUtil.assert(() => game.version.reminder < update.gameVersion.reminder);
    ObjectUtil.assignProperties(game.reminder, update.reminder);
  }
  hasObjectChanges(game: ICrosswordGame, gameBefore: ICrosswordGame) {
    return !ObjectUtil.isObjectEqual(game.reminder, gameBefore.reminder);
  }
  hasVersionChanges(newVersion: CrosswordGameVersion, version: CrosswordGameVersion) {
    return newVersion.reminder > version.reminder;
  }
}
export class UpdateCrosswordGameUtil {
  static readonly parts: CrosswordGamePart[] = [
    new PlayersInfoPart(),
    new TurnsPart(),
    new ChatsPart(),
    new NotificationsPart(),
    new GlobalPart(),
    new ReminderPart(),
  ];
  static getUpdateGame(game: ICrosswordGame, update: CrosswordGameUpdate) {
    const updatedGame = CrosswordGameUtil.getCopyOfCrosswordGame(game);
    this.update(updatedGame, update);
    return updatedGame;
  }
  static update(game: ICrosswordGame, update: CrosswordGameUpdate) {
    for (const part of this.parts) {
      if (part.hasVersionChanges(update.gameVersion, game.version)) {
        part.update(game, update);
      }
    }
    game.version = { ...update.gameVersion };
  }
  static createUpdateByGameObjectChanges(game: ICrosswordGame, gameBefore: ICrosswordGame, bumpVersion = true) {
    const update: CrosswordGameUpdate = { _id: game._id, gameVersion: { ...game.version } };
    for (const part of this.parts) {
      if (part.hasObjectChanges(game, gameBefore)) {
        part.setUpdate(game, update);
        if (bumpVersion) {
          part.bumpVersionOnChanges(update);
        }
      }
    }
    return update;
  }
  static createUpdateByDifferentVersion(game: ICrosswordGame, version: CrosswordGameVersion) {
    const update: CrosswordGameUpdate = { _id: game._id, gameVersion: ObjectUtil.copyAndClean(game.version) };
    for (const part of this.parts) {
      if (part.hasVersionChanges(game.version, version)) {
        part.setUpdate(game, update);
      }
    }
    return update;
  }
  static hasChanges(newVersion: CrosswordGameVersion, version: CrosswordGameVersion) {
    for (const part of this.parts) {
      if (part.hasVersionChanges(newVersion, version)) {
        return true;
      }
    }
    return false;
  }
}
