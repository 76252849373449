<h4 mat-dialog-title style="text-align: center">
  {{ 'titles.generate-questions' | translate }}
</h4>
<mat-dialog-content fxLayout="column" fxLayoutGap="20px" style="overflow: auto">
  <form [formGroup]="formToGenerateQuestions" novalidate>
    <div fxFlex fxLayout="column" fxLayoutGap="20px">
      <div fxFlex fxLayout.gt-md="row" fxLayout.lt-lg="column" fxLayoutGap="10px" style="padding-top: 10px">
        <mat-form-field fxFlex subscriptSizing="dynamic">
          <mat-label>{{ 'labels.cwquestion-category' | translate }}</mat-label>
          <mat-select test-id="generate-questions-category" formControlName="category">
            <mat-option test-id="generate-questions-option" *ngFor="let category of CWQuestionCategories" [value]="category.value">
              {{ category.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex subscriptSizing="dynamic" fxLayout="column">
          <mat-label>{{ 'labels.generate-questions-free-text' | translate }}</mat-label>
          <input fxFlex id="generate-questions-free-text" matInput formControlName="freeText" />
        </mat-form-field>
      </div>
      <mat-checkbox formControlName="onlyUseFreeText">{{ 'labels.only-use-free-text' | translate }}</mat-checkbox>
    </div>
  </form>
  <mat-divider></mat-divider>
  <form *ngIf="generatedQuestionsForm.value.questions.length > 0" [formGroup]="generatedQuestionsForm" novalidate>
    <div fxLayout="column" fxLayoutGap="10px">
      <div formArrayName="questions" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="column" *ngFor="let item of generatedQuestionsForm.controls.questions.controls; let i = index" fxLayoutGap="5px">
          <app-question-form [questionForm]="item"></app-question-form>
          <mat-divider *ngIf="i < generatedQuestionsForm.controls.questions.controls.length - 1"></mat-divider>
        </div>
      </div>
      <div>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ 'panel-titles.extra-question-data' | translate }}</mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="column" fxLayoutGap="5px">
            <mat-form-field>
              <mat-label>{{ 'labels.difficulty' | translate }}</mat-label>
              <mat-select placeholder="{{ 'labels.difficulty' | translate }}" formControlName="difficulty">
                <mat-option *ngFor="let difficulty of Difficulties" [value]="difficulty.value">
                  {{ difficulty.label | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-cwquestion-tags
              [tags]="generatedQuestionsForm.controls.tags"
              [tagInput]="generatedQuestionsForm.controls.tagInput"
            ></app-cwquestion-tags>
            <mat-form-field>
              <mat-label>{{ 'labels.cwquestion-visiblity' | translate }}</mat-label>
              <mat-select placeholder="{{ 'labels.cwquestion-visiblity' | translate }}" formControlName="visibility">
                <mat-option *ngFor="let visibility of CWQuestionVisiblities" [value]="visibility.value">
                  {{ visibility.label | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button id="generate-questions" mat-raised-button (click)="onGenerateQuestions()" [disabled]="!formToGenerateQuestions.valid">
    {{
      (generatedQuestionsForm.value.questions.length == 0 ? 'buttons.generate-questions' : 'buttons.generate-questions-again') | translate
    }}
  </button>
  <button
    *ngIf="generatedQuestionsForm.value.questions.length > 0"
    id="create-questions"
    mat-raised-button
    (click)="onSaveQuestions()"
    [disabled]="!generatedQuestionsForm.valid"
  >
    {{ 'buttons.create' | translate }}
  </button>
  <button mat-raised-button (click)="onCancel()">{{ 'buttons.cancel' | translate }}</button>
</mat-dialog-actions>
