import { PriorityValue } from '../data/priority.value';
import { IndexPriority } from '../model/crossword.build';

export class IndexPriorityUtil {
  static getIndexs(indexPriorities: IndexPriority[]) {
    return indexPriorities.map((value) => value.index);
  }
  static getIndexsWithPriority(indexPriorities: IndexPriority[], priority: PriorityValue) {
    return indexPriorities.filter((value) => value.priority == priority).map((value) => value.index);
  }
  static getIndexsWithPriorities(indexPriorities: IndexPriority[], priorities: PriorityValue[]) {
    const prioritisSet = new Set<PriorityValue>(priorities);
    return indexPriorities.filter((value) => prioritisSet.size == 0 || prioritisSet.has(value.priority)).map((value) => value.index);
  }
  static findIndex(indexPriorities: IndexPriority[], index: number) {
    return indexPriorities.find((value) => value.index == index);
  }
  static removeIds(indexPriorities: IndexPriority[], ids: number[]) {
    const idsSet = new Set<number>(ids);
    return indexPriorities.filter((value) => !idsSet.has(value.index));
  }
  static getIndexesWithRemovedIndexes(indexPriorities: IndexPriority[], indexes: IndexPriority[]) {
    return this.removeIds(
      indexPriorities,
      indexes.map((index) => index.index)
    );
  }
  static getIndexesWithRemovedId(indexPriorities: IndexPriority[], id: number) {
    return indexPriorities.filter((value) => value.index != id);
  }
  static getIndexesWithIndexes(indexContainer: IndexPriority[], indexes: IndexPriority[]) {
    const indexPriority = this.getIndexesWithRemovedIndexes(indexContainer, indexes);
    indexPriority.push(...indexes);
    return indexPriority;
  }
  static getIndexPriorties(ids: number[], priority: PriorityValue) {
    return ids.map((id) => this.getIndexPriority(id, priority));
  }
  static getIndexPriority(id: number, priority: PriorityValue) {
    return { index: id, priority: priority } as IndexPriority;
  }
  static getIndexPriorityMap(indexPriorities: IndexPriority[]) {
    const map = new Map<number, PriorityValue>();
    for (const indexPriority of indexPriorities) {
      map.set(indexPriority.index, indexPriority.priority);
    }
    return map;
  }
}
