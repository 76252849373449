import { Interval } from '../data/interval';
import { Point } from '../data/point';
import { Position } from '../data/position';
import { PriorityValue } from '../data/priority.value';
import { ICrosswordGame } from '../model/crossword.game';
import { ICWQuestionBase } from '../model/cwquestion';
import { IdUtil } from './id.util';

export interface CharIsSet {
  playerId: string;
}

export interface CrosswordGameQuestion {
  priority: PriorityValue;
  cwQuestion: ICWQuestionBase;
  interval: Interval;
  crosswordGameChars: CrosswordGameChar[];
}

export interface CrosswordGameChar {
  point: Point;
  charAnswer: string;
  crosswordQuestions: CrosswordGameQuestion[];
  charIsSet?: CharIsSet;
}

export class CrosswordGamePlayBase {
  protected crosswordGameChars: CrosswordGameChar[][];
  protected crosswordGameQuestions: CrosswordGameQuestion[];
  protected crosswordGame: ICrosswordGame;
  setCrosswordGame(crosswordGame: ICrosswordGame) {
    this.crosswordGame = crosswordGame;
  }
  refreshTiles() {
    this.resetCrosswordGameQuestions();
    this.resetCrosswordGameChars();
    this.resetCrosswordGameCharsSet();
  }
  getQuestionsCompleted() {
    return this.getCrosswordGameQuestionsCompleted().map((crosswordGameQuestion) => crosswordGameQuestion.cwQuestion);
  }
  getCrosswordGameQuestionsCompleted() {
    return this.crosswordGameQuestions.filter((crosswordGameQuestion) =>
      this.doesCrosswordGameQuestionHaveAllCharsSet(crosswordGameQuestion)
    );
  }
  getCrosswordGameQuestionsBegunOrCompleted() {
    return this.crosswordGameQuestions.filter((crosswordGameQuestion) =>
      this.doesCrosswordGameQuestionHaveACharsSet(crosswordGameQuestion)
    );
  }
  getQuestionCrossingQuestion(indexId: number) {
    const question = this.crosswordGameQuestions.find((c) => c.cwQuestion.indexId == indexId);

    return question.crosswordGameChars.flatMap((cc) => cc.crosswordQuestions).filter((q) => q != question);
  }
  getCrosswordGameQuestionsNotCompleted() {
    return this.crosswordGameQuestions.filter(
      (crosswordGameQuestion) => !this.doesCrosswordGameQuestionHaveAllCharsSet(crosswordGameQuestion)
    );
  }
  setCorrectCharSet(charSetOnTiles: Point[], playerId: string) {
    return charSetOnTiles.forEach(
      (charSetOnTile) => (this.crosswordGameChars[charSetOnTile.x][charSetOnTile.y].charIsSet = { playerId: playerId })
    );
  }
  areAllCharsSet() {
    return this.getAllCharsSet().length == this.crosswordGameChars.flatMap((c) => c).length;
  }
  getCompletedNumberOfCharsForPlayer(playerId: string) {
    return this.crosswordGameChars.flat().filter((c) => c != null && IdUtil.idEquals(c.charIsSet?.playerId, playerId)).length;
  }
  getCompletedNumberOfChars() {
    return this.crosswordGameChars.flat().filter((c) => c != null && c.charIsSet).length;
  }
  getTotalNumberOfChars() {
    return this.crosswordGameChars.flat().filter((c) => c != null).length;
  }
  protected getAllCharsSet() {
    return this.crosswordGameChars.flat().filter((c) => c.charIsSet != null);
  }
  private doesCrosswordGameQuestionHaveAllCharsSet(crosswordGameQuestion: CrosswordGameQuestion) {
    return crosswordGameQuestion.crosswordGameChars.find((crosswordGameChar) => !crosswordGameChar.charIsSet) == null;
  }
  private doesCrosswordGameQuestionHaveACharsSet(crosswordGameQuestion: CrosswordGameQuestion) {
    return crosswordGameQuestion.crosswordGameChars.find((crosswordGameChar) => crosswordGameChar.charIsSet) != null;
  }
  protected resetCrosswordGameCharsSet() {
    for (const playerTurn of this.crosswordGame.turns.playerTurns) {
      for (const tile of playerTurn.tilesSet) {
        const crosswordChar = this.crosswordGameChars[tile.x][tile.y];
        crosswordChar.charIsSet = { playerId: playerTurn.playerId };
      }
    }
  }
  protected resetCrosswordGameChars() {
    this.crosswordGameChars = [];
    for (const crosswordQuestion of this.crosswordGameQuestions) {
      const interval = crosswordQuestion.interval;
      const startPosition = Position.toPosition(interval);
      for (let i = 1; i < interval.length; i++) {
        const position = startPosition.add(i);
        const charAnswer = crosswordQuestion.cwQuestion.answer.charAt(i - 1);
        if (!this.crosswordGameChars[position.x]) {
          this.crosswordGameChars[position.x] = [];
        }
        if (!this.crosswordGameChars[position.x][position.y]) {
          this.crosswordGameChars[position.x][position.y] = {
            point: { x: position.x, y: position.y },
            charAnswer,
            crosswordQuestions: [crosswordQuestion],
          };
        } else {
          this.crosswordGameChars[position.x][position.y].crosswordQuestions.push(crosswordQuestion);
        }
        crosswordQuestion.crosswordGameChars.push(this.crosswordGameChars[position.x][position.y]);
      }
    }
  }
  protected resetCrosswordGameQuestions() {
    this.crosswordGameQuestions = [];
    const crossword = this.crosswordGame.initial.crossword;
    for (const crosswordQuestion of crossword.crosswordQuestions) {
      const position = crosswordQuestion.position;
      const question = crosswordQuestion.cwQuestion;
      this.crosswordGameQuestions.push({
        priority: crosswordQuestion.priority,
        cwQuestion: question,
        interval: new Interval(position.x, position.y, position.direction, question.answer.length + 1),
        crosswordGameChars: [],
      });
    }
  }
}
