import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface LoadingProgressData {
  message: string;
  minimal:boolean;
}
@Component({
  selector: 'app-loading-progress-modal-content',
  templateUrl: './loading-progress-modal-content.component.html',
  styleUrls: ['./loading-progress-modal-content.component.scss'],
})
export class LoadingProgressModalContentComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: LoadingProgressData) {}
}
