<div *ngIf="crossword" class="flex-column padd-top-bottom-5-px" style="gap: 5px">
  <div class="flex-row align-center-end-row">
    <app-toggle-show-crossword [handleCrosswordView]="handleCrosswordView"></app-toggle-show-crossword>
    <app-flexable-button-menu fxFlex [buttons]="crosswordActionButtons"> </app-flexable-button-menu>
  </div>
  <div>
    <app-show-crossword [handleCrosswordView]="handleCrosswordView"> </app-show-crossword>
  </div>
  <mat-expansion-panel *ngIf="crossword.description != null" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>{{ 'panel-titles.crossword-description' | translate }}</mat-panel-title>
    </mat-expansion-panel-header>
    <div style="white-space: pre-wrap">{{ crossword.description }}</div>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>{{ 'panel-titles.crossword-information' | translate }}</mat-panel-title>
    </mat-expansion-panel-header>
    <app-crossword-information [crosswordId]="crossword._id"></app-crossword-information>
  </mat-expansion-panel>
</div>

<app-progress-bar *ngIf="!crossword"></app-progress-bar>
