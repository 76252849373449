import { Injectable, isDevMode } from '@angular/core';
import { IConfig } from '../../../../../Common/data/config';
import { PackageVersionUtil } from '../../../../../Common/data/package.version';
import { IDevDatabase } from '../../../../../Common/test/dev.database';
import { AssertUtil } from '../../../../../Common/util/assert.util';
import packageInfo from '../../../../package.json';
import { AppSettingsService } from '../appsettings/app-settings.service';
import { DevelopService } from '../develop/develop.service';
import { HttpClientCacheService } from '../http-client-cache/http-client-cache.service';

export enum DevDatabaseType {
  Small,
  Medium,
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configUrl = `api/config/`;

  private config: IConfig = null;
  private initialized = false;
  private devDatabase: IDevDatabase;
  constructor(
    private http: HttpClientCacheService,
    private appSettingsService: AppSettingsService,
    private developService: DevelopService,
  ) {}

  async initializeWhenDeveloping(devDataBase: DevDatabaseType) {
    AssertUtil.assert(() => isDevMode());
    await this.initializeDevMode(devDataBase);
    await this.initialize();
  }

  async initialize() {
    this.config = await this.http.get<IConfig>(this.appSettingsService.appSettings.apiurl + this.configUrl, 60000);
    this.initialized = true;
  }

  private async initializeDevMode(devDataBase: DevDatabaseType) {
    try {
      const resultCreate = await this.createDevDatabase(devDataBase);
      if (resultCreate && resultCreate.result !== 'ok') {
        throw new Error('Failed to setup devmode database');
      }
      this.devDatabase = resultCreate;
    } catch (error) {
      console.error('Failed to setup database:', error);
    }
  }

  private async createDevDatabase(devDataBase: DevDatabaseType) {
    if (devDataBase == DevDatabaseType.Small) {
      return await this.developService.setupSmallDatabase();
    }
    if (devDataBase == DevDatabaseType.Medium) {
      return await this.developService.setupMediumDatabase();
    }
    return null;
  }

  getConfig() {
    AssertUtil.assert(() => this.initialized);
    return this.config;
  }
  getRoutes() {
    return this.getConfig().routes;
  }
  getDevDatabase(): IDevDatabase {
    AssertUtil.assert(() => this.initialized);
    return this.devDatabase;
  }
  getAppPackageVersion() {
    return PackageVersionUtil.parse(packageInfo.version);
  }
  getServerPackageVersion() {
    AssertUtil.assert(() => this.initialized);
    return PackageVersionUtil.parse(this.getConfig().version);
  }
}
