import { Point } from './point';

export enum RealDirection {
  Horizontal = 0,
  Vertical = 1,
}

export enum Direction {
  MAINAXIS = 0,
  CROSSAXIS = 1,
}
export interface Square extends XYPosition {
  size: number;
}
export interface Rectangle extends XYPosition {
  width: number;
  height: number;
}
export interface XYPosition {
  x: number;
  y: number;
}
export interface IPosition extends XYPosition {
  direction: Direction;
}

export class Position implements IPosition {
  x: number;
  y: number;
  direction: Direction;

  constructor(x = 0, y = 0, direction = Direction.MAINAXIS) {
    this.x = x;
    this.y = y;
    this.direction = direction;
  }
  set(x = 0, y = 0, direction = Direction.MAINAXIS) {
    this.x = x;
    this.y = y;
    this.direction = direction;
    return this;
  }
  parse(obj) {
    this.x = obj.x;
    this.y = obj.y;
    this.direction = obj.direction;
    return this;
  }
  equal(position: Position) {
    return this.x === position.x && this.y === position.y && this.direction === position.direction;
  }
  add(add: number) {
    const addX = this.direction === Direction.MAINAXIS ? add : 0;
    const addY = this.direction === Direction.CROSSAXIS ? add : 0;
    return new Position(this.x + addX, this.y + addY, this.direction);
  }
  subtract(sub: number) {
    return this.add(-sub);
  }
  distanceTo(position: IPosition) {
    const x = this.x - position.x;
    const y = this.y - position.y;
    return Math.hypot(x, y);
  }
  point(): Point {
    return { x: this.x, y: this.y };
  }
  getSequence(length: number): Position[] {
    const values = Array(length)
      .fill(null)
      .map((value, index) => index);
    if (this.direction == Direction.MAINAXIS) {
      return values.map((i) => new Position(this.x + i, this.y, this.direction));
    } else {
      return values.map((i) => new Position(this.x, this.y + i, this.direction));
    }
  }
  rotate90Degrees() {
    return new Position(this.y, this.x, this.direction == Direction.MAINAXIS ? Direction.CROSSAXIS : Direction.MAINAXIS);
  }
  static toPosition(position: IPosition) {
    return new Position(position.x, position.y, position.direction);
  }
}
