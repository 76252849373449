<div fxFlexFill #parentFlexButton>
  <div fxFlexFill listenForResize (sizeChanged)="onSizeChanged($event)">
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
      <ng-container *ngFor="let button of standardButtons">
        <button
          [id]="button.id"
          *ngIf="canShowButton(button, FlexibleButtonType.Standard)"
          [disabled]="button.disabled ? true : false"
          mat-raised-button
          [ngClass]="{ 'stretch-badge': button.badgeText?.length > 2 }"
          [matBadge]="button.badgeText"
          [matBadgeHidden]="button.badgeText ? false : true"
          (click)="button.standardAction.event()"
          [ngStyle]="{ 'z-index': button.badgeText ? '100' : '1' }"
        >
          <mat-icon *ngIf="button.icon != null">{{ button.icon }}</mat-icon
          >{{ button.text }}
        </button>
        <a
          [id]="button.id"
          *ngIf="canShowButton(button, FlexibleButtonType.Link)"
          [disabled]="button.disabled ? true : false"
          mat-raised-button
          [href]="button.link.href"
        >
          <mat-icon *ngIf="button.icon != null">{{ button.icon }}</mat-icon
          >{{ button.text }}
        </a>
      </ng-container>
      <button id="expand-button-menu" *ngIf="menuButtons && menuButtons.length > 0" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu>
        <ng-container *ngFor="let button of menuButtons">
          <button
            [id]="button.id"
            *ngIf="canShowButton(button, FlexibleButtonType.Standard)"
            [disabled]="button.disabled ? true : false"
            mat-menu-item
            [matBadge]="button.badgeText"
            [matBadgeHidden]="button.badgeText != null ? true : false"
            matBadgeSize="small"
            class="stretch-badge"
            (click)="button.standardAction.event()"
          >
            <mat-icon *ngIf="button.icon != null">{{ button.icon }}</mat-icon
            >{{ button.text }}
          </button>
          <a
            [id]="button.id"
            *ngIf="canShowButton(button, FlexibleButtonType.Link)"
            [disabled]="button.disabled ? true : false"
            mat-menu-item
            [href]="button.link.href"
          >
            <mat-icon *ngIf="button.icon != null">{{ button.icon }}</mat-icon
            >{{ button.text }}
          </a>
        </ng-container>
      </mat-menu>
    </div>
  </div>
</div>
