import { Injectable, isDevMode } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SelectedQuestionPosition } from '../../models/expand.selected.question';

export enum BrowserDeviceType {
  Browser,
  Ios,
  Android,
}

@Injectable({
  providedIn: 'root',
})
export class ComponentCommunicationService {
  readonly currentRouteTitle = new BehaviorSubject<string>('');
  readonly selectedQuestion = new BehaviorSubject<SelectedQuestionPosition>(null);
  readonly allowCookies = new BehaviorSubject<boolean>(ComponentCommunicationService.getAllowCookies());
  readonly publishedChanged = new Subject<void>();
  readonly reloadCurrentCrosswordGame = new Subject<void>();
  readonly devMode = new BehaviorSubject<boolean>(isDevMode());
  readonly noConnectionToServer = new BehaviorSubject<boolean>(false);
  readonly deviceType = new BehaviorSubject<BrowserDeviceType>(ComponentCommunicationService.getBrowserDeviceType());
  readonly showCrosswordGameActions = new BehaviorSubject<boolean>(false);
  readonly userWantsToJoin = new BehaviorSubject<boolean>(false);
  readonly playerLoggedIn = new BehaviorSubject<boolean>(false);

  private static getBrowserDeviceType() {
    if (Capacitor.getPlatform() == 'android') {
      return BrowserDeviceType.Android;
    } else if (Capacitor.getPlatform() == 'ios') {
      return BrowserDeviceType.Ios;
    }
    return BrowserDeviceType.Browser;
  }

  private static getAllowCookies() {
    return this.getBrowserDeviceType() != BrowserDeviceType.Browser;
  }
}
