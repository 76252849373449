import { LanguageCode } from '../data/language';
import { PriorityValue } from '../data/priority.value';
import { IModel } from './model';
import { IPlayer } from './player';
export enum CWQuestionCategory {
  WordPlay,
  Language,
  Synonym,
  Geography,
  Entertainment,
  History,
  ArtsLiterature,
  ScienceNature,
  SportsLeisure,
}
export enum CWQuestionType {
  Text,
  Image,
}
export enum CWQuestionDifficultyEnum {
  Easy,
  Medium,
  Hard,
}
export enum CWQuestionVisibility {
  All,
  OnlyAuthor,
}
export interface QuestionBasePriority extends ICWQuestionBase {
  priority: PriorityValue;
}
export interface ICWQuestionBase {
  indexId: number;
  question: string;
  answer: string;
  type: CWQuestionType;
  imageId?: string;
}
export interface ICWQuestion extends IModel {
  languageCode: LanguageCode;
  base: ICWQuestionBase;
  difficulty: CWQuestionDifficultyEnum;
  author: IPlayer;
  visibility: CWQuestionVisibility;
  category: CWQuestionCategory;
  tags: string[];
  imageUrl: string;
}
