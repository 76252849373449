import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BuildCrosswordComponent } from './components/build-crossword/build-crossword.component';
import { EditQuestionTilesComponent } from './components/build-crossword/create-edit-question/edit-question-tiles/edit-question-tiles.component';
import { EditQuestionComponent } from './components/build-crossword/create-edit-question/edit-question.component';
import { GenerateQuestionsComponent } from './components/build-crossword/create-edit-question/generate-questions.component';
import { QuestionFormComponent } from './components/build-crossword/create-edit-question/question-form/question-form.component';
import { SearchImagesComponent } from './components/build-crossword/create-edit-question/search-images/search-images.component';
import { CWQuestionTagsComponent } from './components/build-crossword/cwquestion-tags/cwquestion-tags.component';
import { SelectCWQuestionsComponent } from './components/build-crossword/select-cwquestions/select-cwquestions.component';
import { SelectFilterQuestionsComponent } from './components/build-crossword/select-cwquestions/select-filter-questions/select-filter-questions.component';
import { CaptchaGameComponent } from './components/captcha-game/captcha-game.component';
import { CrosswordTileBorderComponent } from './components/common/crossword-tile/border/crossword-tile-border.component';
import { CrosswordTileStylePipe } from './components/common/crossword-tile/content/crossword-tile-style.pipe';
import { CrosswordTileComponent } from './components/common/crossword-tile/content/crossword-tile.component';
import { ShowDirectionComponent } from './components/common/crossword-tile/show-direction/show-direction.component';
import { ShowPriorityComponent } from './components/common/crossword-tile/show-priority/show-priority.component';
import { ShowTileQuestionComponent } from './components/common/crossword-tile/show-tile-question/show-tile-question.component';
import { FlexableButtonMenuComponent } from './components/common/flexable-button-menu/flexable-button-menu.component';
import { SelectFilterItemsComponent } from './components/common/select-filter-items/select-filter-items.component';
import { ShowAllImageComponent } from './components/common/show-crossword/show-all-image/show-all-image.component';
import { ShowAllQuestionComponent } from './components/common/show-crossword/show-all-question/show-all-question.component';
import { ShowCrosswordComponent } from './components/common/show-crossword/show-crossword.component';
import { ShowLargeImageComponent } from './components/common/show-crossword/show-large-image/show-large-image.component';
import { ShowRowQuestionComponent } from './components/common/show-crossword/show-row-question/show-row-question.component';
import { StickyToTopComponent } from './components/common/sticky-to-top/sticky-to-top.component';
import { ConnectionBarComponent } from './components/connection-bar/connection-bar.component';
import { CrosswordLandingComponent } from './components/crossword-landing/crossword-landing.component';
import { AddQuestionDialogComponent } from './components/dialog/add-question-dialog/add-question-dialog.component';
import { AskDirectionMessageBoxComponent } from './components/dialog/ask-direction-message-box/ask-direction-message-box.component';
import { HelpDialogContentComponent } from './components/dialog/help-dialog-content/help-dialog-content.component';
import { LoadingProgressModalContentComponent } from './components/dialog/loading-progress-modal-content/loading-progress-modal-content.component';
import { MessageBoxContentComponent } from './components/dialog/message-box-content/message-box-content.component';
import { UserCodeComponent } from './components/dialog/number-code/number-code.component';
import { QuestionPriorityDialogComponent } from './components/dialog/question-priority/question-priority-dialog.component';
import { QuestionPriorityComponent } from './components/dialog/question-priority/question-priority.component';
import { SelectPlayerColorComponent } from './components/dialog/select-player-color/select-player-color.component';
import { ShareGameComponent } from './components/dialog/share-game/share-game.component';
import { CrosswordActionsComponent } from './components/gameplay/crossword-actions/crossword-actions.component';
import { CrosswordChatComponent } from './components/gameplay/crossword-chat/crossword-chat.component';
import { CrosswordGameInformationComponent } from './components/gameplay/crossword-game-information/crossword-game-information.component';
import { CrosswordInformationComponent } from './components/gameplay/crossword-information/crossword-information.component';
import { PlayCrosswordComponent } from './components/gameplay/play-crossword/play-crossword.component';
import { ListCrosswordBuildsComponent } from './components/list-crossword-builds/list-crossword-builds.component';
import { ListCrosswordGamesComponent } from './components/list-crossword-games/list-crossword-games.component';
import { ListCrosswordsComponent } from './components/list-crosswords/list-crosswords.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { NotificationBarComponent } from './components/notification-bar/notification-bar.component';
import { CreateUserComponent } from './components/user/create-user/create-user.component';
import { ForgotPasswordComponent } from './components/user/forgot-password/forgot-password.component';
import { LoginComponent } from './components/user/login/login.component';
import { PlayerSettingsComponent } from './components/user/player-settings/player-settings.component';
import { ProgressBarComponent } from './components/util-components/progress-bar/progress-bar.component';
import { ToggleShowCrosswordComponent } from './components/util-components/toggle-show-crossword/toggle-show-crossword.component';
import { ResizeDirective } from './components/util/resize.directive';
import { ErrorHandlerModule } from './error/error.handling.module';
import { MaterialModule } from './material/material.module';
import { ComponentCommunicationService } from './services/componentcommunication/component-communication.service';
import { StoreRouteService } from './services/storeroute/store.route.service';
import { CheckLoggedIn } from './util/check.loggedin';
import { CredentialsInterceptor } from './util/credentials.interceptor';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname,
  },
  palette: {
    popup: {
      background: '#000',
    },
    button: {
      background: '#f1d600',
    },
  },
  position: 'top',
  theme: 'edgeless',
  type: 'opt-out',
  autoAttach: false,
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        LoadingProgressModalContentComponent,
        CreateUserComponent,
        MessageBoxContentComponent,
        PlayCrosswordComponent,
        ListCrosswordsComponent,
        CrosswordTileComponent,
        CrosswordGameInformationComponent,
        BuildCrosswordComponent,
        EditQuestionComponent,
        NavigationBarComponent,
        ListCrosswordGamesComponent,
        ListCrosswordBuildsComponent,
        CrosswordActionsComponent,
        CrosswordChatComponent,
        SelectCWQuestionsComponent,
        CrosswordTileStylePipe,
        ProgressBarComponent,
        CrosswordLandingComponent,
        ShowCrosswordComponent,
        HelpDialogContentComponent,
        PlayerSettingsComponent,
        CrosswordTileBorderComponent,
        CWQuestionTagsComponent,
        AskDirectionMessageBoxComponent,
        AddQuestionDialogComponent,
        EditQuestionTilesComponent,
        FlexableButtonMenuComponent,
        QuestionPriorityComponent,
        QuestionPriorityDialogComponent,
        ShowPriorityComponent,
        ShowDirectionComponent,
        SelectFilterQuestionsComponent,
        NotificationBarComponent,
        SelectPlayerColorComponent,
        ToggleShowCrosswordComponent,
        CrosswordInformationComponent,
        ConnectionBarComponent,
        StickyToTopComponent,
        ShowAllQuestionComponent,
        ResizeDirective,
        SelectFilterItemsComponent,
        ForgotPasswordComponent,
        CaptchaGameComponent,
        UserCodeComponent,
        QuestionFormComponent,
        EditQuestionComponent,
        GenerateQuestionsComponent,
        ShareGameComponent,
        SearchImagesComponent,
        ShowTileQuestionComponent,
        ShowLargeImageComponent,
        ShowAllImageComponent,
        ShowRowQuestionComponent,
    ],
    bootstrap: [AppComponent],
    exports: [], imports: [CommonModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        BrowserModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        LayoutModule,
        ErrorHandlerModule,
        TranslateModule.forRoot({
            defaultLanguage: 'sv',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        ServiceWorkerModule.register('./custom-service-worker.js', { enabled: environment.production }),
        NgcCookieConsentModule.forRoot(cookieConfig)], providers: [
        CheckLoggedIn,
        ComponentCommunicationService,
        { provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true },
        { provide: Window, useValue: window },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', subscriptSizing: 'dynamic' } },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
  constructor(private storeRouteServic: StoreRouteService) {}
}
