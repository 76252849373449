<mat-button-toggle-group
  [hideSingleSelectionIndicator]="true"
  class="toggle-buttons"
  #showQuestionsInGroup
  [value]="handleCrosswordView.showCrosswordAsObservable() | async"
  (change)="handleCrosswordView.setShowCrosswordAs($event.value)"
  aria-label=""
>
  <mat-button-toggle test-id="show-crossword-as-matrix" [value]="ShowCrosswordAs.Matrix">
    <mat-icon>view_module</mat-icon>
  </mat-button-toggle>
  <mat-button-toggle test-id="show-crossword-as-list" [value]="ShowCrosswordAs.List">
    <mat-icon>view_list</mat-icon>
  </mat-button-toggle>
</mat-button-toggle-group>
