import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { MessageBoxType } from '../../dialog/message-box-content/message-box-content.component';
import { DialogService } from '../../dialog/service/dialog.service';
import { PlayCrosswordComponent } from './play-crossword.component';
import { UserService } from '../../../services/user/user.service';

@Injectable()
export class CanDeactivatePlayCrosswordComponent implements CanDeactivate<PlayCrosswordComponent> {
  constructor(private translateService: TranslateService, private dialogService: DialogService, private userService:UserService) {}
  canDeactivate(component: PlayCrosswordComponent): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canDeactiveInternal(component);
  }
  private async canDeactiveInternal(component: PlayCrosswordComponent):Promise<boolean>{
    if(!this.userService.isLoggedIn()){
      return true;
    }
    if (component.hasUnplayedChars()) {
      const result = await this.dialogService.showMessageBox(
        '',
        this.translateService.instant('messages.unplayed-chars'),
        MessageBoxType.YesNo,
        true
      );
      return result;
    }
    return true;
  }
}
