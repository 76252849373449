import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

export interface SizeChanged {
  width: number;
  height: number;
}

@Directive({
  selector: '[listenForResize]',
})
export class ResizeDirective implements OnInit {
  @Output()
  sizeChanged = new EventEmitter<SizeChanged>();
  constructor(private eleRef: ElementRef) {}
  ngOnInit(): void {
    this.emitElementSize();
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.emitElementSize();
  }

  private emitElementSize() {
    this.sizeChanged.emit({ width: this.eleRef.nativeElement.offsetWidth, height: this.eleRef.nativeElement.offsetHeight });
  }
}
