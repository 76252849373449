<h3 mat-dialog-title style="text-align: center">
  {{ 'titles.select-player-color' | translate }}
</h3>
<mat-dialog-content style="overflow-y: hidden">
  <div fxFlexFill fxLayout="column" fxLayoutGap="20px" style="padding-bottom: 10px">
    <mat-label *ngIf="playersPrimaryColorIsTaken">{{ 'misc.players-primary-color-is-taken' | translate }}</mat-label>
    <mat-radio-group
      [(ngModel)]="selectedColor"
      aria-label="misc.select-player-color"
      fxLayout="row"
      fxLayoutGap="20px"
      fxLayoutAlign="center"
      style="padding-top: 5px"
    >
      <div *ngFor="let colorColumn of colors" fxLayout="column" fxLayoutGap="10px">
        <mat-radio-button *ngFor="let color of colorColumn" [id]="color.id" [value]="color" [ngStyle]="{ color: color.color }"
          ><mat-icon class="icon-display" [ngStyle]="{ color: color.color }"> circle </mat-icon></mat-radio-button
        >
      </div>
    </mat-radio-group>
    <mat-checkbox id="remember-color" *ngIf="!playersPrimaryColorIsTaken" [(ngModel)]="rememberDecision">{{
      'misc.remember-player-color-decision' | translate
    }}</mat-checkbox>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxFlex fxLayout="row" fxLayoutAlign="end center">
    <button
      id="select-color-ok"
      color="primary"
      [disabled]="!selectedColor"
      (click)="onCloseDialogWithValue()"
      mat-raised-button
      cdkFocusInital
    >
      {{ 'buttons.ok' | translate }}
    </button>
    <button *ngIf="data.allowCancel" id="select-color-cancel" color="primary" (click)="onCancelDialog()" mat-raised-button cdkFocusInital>
      {{ 'buttons.cancel' | translate }}
    </button>
  </div>
</mat-dialog-actions>
