<div
  *ngIf="questionDirection == ArrowDirection.Horizontal"
  [ngStyle]="{
    'border-top.px': triangleSize,
    'border-right.px': 0,
    'border-bottom.px': triangleSize,
    'border-left.px': triangleSize
  }"
  class="triangle_mainaxis"
></div>
<div
  *ngIf="questionDirection == ArrowDirection.Vertical"
  [ngStyle]="{
    'border-top.px': triangleSize,
    'border-right.px': triangleSize,
    'border-bottom.px': 0,
    'border-left.px': triangleSize
  }"
  class="triangle_crossaxis"
></div>
