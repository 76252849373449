/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeWhile } from 'rxjs';
import { AssertUtil } from '../../../../../../Common/util/assert.util';

class SelectedFilterStorage {
  static setSelectedFilterItems(id: string, selectedItems: SelectFilterItem[]) {
    localStorage.setItem(id, selectedItems.map((i) => i.value).join(','));
  }
  static getSelectedFilterItems(id: string, selectedItems: SelectFilterItem[]) {
    const selectedItemValuesAsString = localStorage.getItem(id);
    const selectedItemValuesAsArray = selectedItemValuesAsString?.split(',') ?? [];
    const selectedItemValues = new Set<string>(selectedItemValuesAsArray);
    return selectedItems.filter((i) => selectedItemValues.has(i.value));
  }
}

export interface SelectFilterEvent<T> {
  selectedItem: SelectFilterItem;
  selectedGroup: SelectFilterGroup<T>;
}

export class SelectFilterItem {
  constructor(
    public readonly title: string,
    public readonly value: any,
  ) {}
}
export class SelectFilterGroup<T> {
  expanded = false;

  constructor(
    public readonly title: string,
    public readonly selectItems: SelectFilterItem[],
    public readonly filter: (values: T[], selectedValueSet: Set<any>) => T[],
  ) {}
}
@Component({
  selector: 'app-select-filter-items',
  templateUrl: './select-filter-items.component.html',
  styleUrls: ['./select-filter-items.component.scss'],
})
export class SelectFilterItemsComponent<T> implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() allValues: T[];
  @Input() selectFilterGroups: SelectFilterGroup<T>[];
  @Output() filteredValuesChanged = new EventEmitter<T[]>();
  form = new FormControl<SelectFilterItem[]>([]);
  private alive = true;

  ngOnDestroy(): void {
    this.alive = false;
  }
  ngOnInit() {
    this.form.valueChanges.pipe(takeWhile(() => this.alive)).subscribe((selectedItems) => {
      this.onSelectedFilterItemsChanged(selectedItems);
      SelectedFilterStorage.setSelectedFilterItems(this.id, selectedItems);
    });
    this.initializeSelectedItemsForm();
  }
  private initializeSelectedItemsForm() {
    AssertUtil.assertNotNull(this.id);
    const allSelectedItems = this.getAllSelectedItems(this.selectFilterGroups);
    const selectedItems = SelectedFilterStorage.getSelectedFilterItems(this.id, allSelectedItems);
    this.form.setValue(selectedItems);
  }

  isGroupSelected(group: SelectFilterGroup<T>): boolean {
    const allSelectedItems = new Set<SelectFilterItem>(this.form.value);
    const valueNotSelected = group.selectItems.find((value) => !allSelectedItems.has(value));
    return valueNotSelected == null;
  }
  toggleSelection(group: SelectFilterGroup<T>): void {
    const allFormValues = new Set<SelectFilterItem>(this.form.value);
    if (this.isGroupSelected(group)) {
      group.selectItems.forEach((value) => allFormValues.delete(value));
      group.expanded = false;
    } else {
      group.selectItems.forEach((value) => allFormValues.add(value));
      group.expanded = true;
    }
    this.form.setValue([...allFormValues.values()]);
  }
  onSelectClosed() {
    const groupIsExpaned = this.selectFilterGroups.find((group) => group.expanded);
    if (!groupIsExpaned) {
      this.selectFilterGroups[0].expanded = true;
    }
  }

  private onSelectedFilterItemsChanged(selectedItems: SelectFilterItem[]) {
    let filteredValues = [...this.allValues];
    for (const selectedGroup of this.selectFilterGroups) {
      const selectedGroupItems = this.getGroupsSelectedFilterItems(selectedGroup, selectedItems);
      if (selectedGroupItems.length > 0) {
        const selectedValuesSet = new Set<any>([...selectedGroupItems.map((item) => item.value)]);
        filteredValues = selectedGroup.filter(filteredValues, selectedValuesSet);
      }
    }
    this.filteredValuesChanged.emit(filteredValues);
  }
  private getGroupsSelectedFilterItems(selectedFilterGroup: SelectFilterGroup<T>, selectedItems: SelectFilterItem[]) {
    const selectedItemsSet = new Set<SelectFilterItem>([...selectedItems]);
    return selectedFilterGroup.selectItems.filter((selectedItem) => selectedItemsSet.has(selectedItem));
  }
  private getAllSelectedItems(selectedFilterGroup: SelectFilterGroup<T>[]) {
    return selectedFilterGroup.flatMap((g) => g.selectItems);
  }
}
