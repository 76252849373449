import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GameState } from '../../../../../../Common/data/game.state';
import { ICrosswordGame } from '../../../../../../Common/model/crossword.game';
import { CrosswordGamePlayerUtil } from '../../../../../../Common/util/crossword.game.player.util';
import { CrosswordGameTurnUtil } from '../../../../../../Common/util/crossword.game.turn.util';
import { ComponentCommunicationService } from '../../../services/componentcommunication/component-communication.service';
import { UserService } from '../../../services/user/user.service';
import { GameType } from '../../../../../../Common/data/game.type';

@Injectable({ providedIn: 'root' })
export class PlayCrosswordTitleService {
  constructor(
    private componentCommunication: ComponentCommunicationService,
    private translateService: TranslateService,
    private userService: UserService,
  ) {}

  public updateTitle(crosswordGame: ICrosswordGame) {
    if (crosswordGame.global.state == GameState.Created) {
      this.componentCommunication.currentRouteTitle.next(this.translateService.instant('route-titles.game-created'));
    } else if (crosswordGame.global.state == GameState.Started) {
      const currentTurn =
        CrosswordGameTurnUtil.getCurrentTurn(crosswordGame.turns.playerTurns.length, crosswordGame.playersInfo.players.length) + 1;
      const totalNumberOfTurns = crosswordGame.turns.totalNumberOfTurns;
      if (crosswordGame.global.type == GameType.Single) {
        this.componentCommunication.currentRouteTitle.next(
          this.translateService.instant('route-titles.game-started-single', {
            turn: currentTurn,
            turns: totalNumberOfTurns,
            currentPlayer: this.checkAndCompressTitle(crosswordGame),
          }),
        );
      } else {
        const translateKey = CrosswordGamePlayerUtil.isCurrentPlayer(crosswordGame, this.getPlayer())
          ? 'route-titles.game-started-match-my-turn'
          : 'route-titles.game-started-match-others-turn';
        this.componentCommunication.currentRouteTitle.next(
          this.translateService.instant(translateKey, {
            turn: currentTurn,
            turns: totalNumberOfTurns,
            currentPlayer: this.checkAndCompressTitle(crosswordGame),
          }),
        );
      }
    } else if (crosswordGame.global.state == GameState.Ended) {
      this.componentCommunication.currentRouteTitle.next(this.translateService.instant('route-titles.game-ended'));
    }
  }
  private checkAndCompressTitle(crosswordGame: ICrosswordGame) {
    const name = CrosswordGamePlayerUtil.getCurrentPlayer(crosswordGame)?.name ?? '';
    return name.length > 16 ? name.slice(0, 14) + '...' : name;
  }

  private getPlayer() {
    return this.userService.getLoggedIn();
  }
}
