import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, timer } from 'rxjs';
import { PackageVersion } from '../../../../../Common/data/package.version';
import { TimerUtil } from '../../../../../Common/util/timer.util';
import { DialogService } from '../../components/dialog/service/dialog.service';
import { CacheUtil } from '../../util/cache.util';
import { ConfigService } from '../config/config.service';

const VersionStorageId = 'app-version';
@Injectable({
  providedIn: 'root',
})
export class AppVersionService {
  constructor(
    private swUpdate: SwUpdate,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private storage: StorageMap,
    private configService: ConfigService,
  ) {}
  async listenForUpdates() {
    if (!this.swUpdate.isEnabled) {
      return;
    }
    console.log('SWupdate is enabled and checking for new version');
    this.checkForUpdateInInterval(60 * 1000, true);
  }
  async waitAndApplyNewAppVersion() {
    if (!this.swUpdate.isEnabled) {
      await TimerUtil.sleep(100);
      this.applyNewAppVersion();
      return;
    }
    await this.dialogService.wrapInProgress(async () => {
      for (let i = 0; i < 100; i++) {
        const result = await this.swUpdate.checkForUpdate();
        if (result) {
          this.applyNewAppVersion();
        }
        await TimerUtil.sleep(100);
      }
    }, this.translateService.instant('misc.new-version-available'));
  }
  async applyNewAppVersion() {
    CacheUtil.clearCaches();
    document.location.reload();
  }

  async clearCacheAndReloadIfNoVersionSet() {
    const appVersion: boolean = await lastValueFrom(this.storage.has(VersionStorageId));
    console.log('Has current appversion', appVersion);
    if (!appVersion) {
      await lastValueFrom(this.storage.set('app-version', this.configService.getAppPackageVersion()));
      CacheUtil.clearCaches();
      document.location.reload();
    }
    await lastValueFrom(this.storage.set('app-version', this.configService.getAppPackageVersion()));
  }

  isAppVersionToLow(appVersion: PackageVersion, serverVersion: PackageVersion) {
    return appVersion.major < serverVersion.major || appVersion.minor < serverVersion.minor;
  }

  isAppVersionToHigh(appVersion: PackageVersion, serverVersion: PackageVersion) {
    return appVersion.major > serverVersion.major || appVersion.minor > serverVersion.minor;
  }

  private checkForUpdateInInterval(intervalTime: number, showMessageBeforeReloading = false) {
    timer(0, intervalTime).subscribe(async () => await this.checkForUpdate(showMessageBeforeReloading));
  }

  private async checkForUpdate(showMessageBeforeReloading: boolean) {
    try {
      const result = await this.swUpdate.checkForUpdate();
      if (result) {
        if (showMessageBeforeReloading) {
          await this.dialogService.showSnackbar(this.translateService.instant('misc.new-version-available'), 2000);
        }
        this.applyNewAppVersion();
      }
    } catch (error) {
      console.error('Failed to check for update', error);
    }
  }
}
