import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuildCrosswordComponent } from './components/build-crossword/build-crossword.component';
import { GenerateQuestionsComponent } from './components/build-crossword/create-edit-question/generate-questions.component';
import { CanDeactivateBuildCrosswordComponent } from './components/build-crossword/util/can.deactivate.build.crossword.component';
import { CrosswordLandingComponent } from './components/crossword-landing/crossword-landing.component';
import { CanDeactivatePlayCrosswordComponent } from './components/gameplay/play-crossword/play-crossword-guard.component';
import { PlayCrosswordComponent } from './components/gameplay/play-crossword/play-crossword.component';
import { ListCrosswordBuildsComponent } from './components/list-crossword-builds/list-crossword-builds.component';
import {
  ListCrosswordGameTypes,
  ListCrosswordGamesComponent,
  ListCrosswordGamesData,
} from './components/list-crossword-games/list-crossword-games.component';
import { ListCrosswordsComponent } from './components/list-crosswords/list-crosswords.component';
import { CreateUserComponent } from './components/user/create-user/create-user.component';
import { ForgotPasswordComponent } from './components/user/forgot-password/forgot-password.component';
import { LoginComponent } from './components/user/login/login.component';
import { PlayerSettingsComponent } from './components/user/player-settings/player-settings.component';
import { CheckLoggedIn } from './util/check.loggedin';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'createUser', component: CreateUserComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  {
    path: 'playCrossword/:id',
    component: PlayCrosswordComponent,
    canActivate: [CheckLoggedIn],
    canDeactivate: [CanDeactivatePlayCrosswordComponent],
  },
  { path: 'listCrosswords', component: ListCrosswordsComponent, canActivate: [CheckLoggedIn] },
  {
    path: 'listCrosswordGames',
    component: ListCrosswordGamesComponent,
    canActivate: [CheckLoggedIn],
    data: { type: ListCrosswordGameTypes.Ongoing } as ListCrosswordGamesData,
  },
  {
    path: 'listCrosswordGamesCompleted',
    component: ListCrosswordGamesComponent,
    canActivate: [CheckLoggedIn],
    data: { type: ListCrosswordGameTypes.Complete } as ListCrosswordGamesData,
  },
  { path: 'listCrosswordBuilds', component: ListCrosswordBuildsComponent, canActivate: [CheckLoggedIn] },
  {
    path: 'buildCrossword/:id',
    component: BuildCrosswordComponent,
    canActivate: [CheckLoggedIn],
    canDeactivate: [CanDeactivateBuildCrosswordComponent],
  },
  { path: 'crossword/:id', component: CrosswordLandingComponent, canActivate: [CheckLoggedIn] },
  { path: 'playersettings', component: PlayerSettingsComponent, canActivate: [CheckLoggedIn] },
  { path: 'generatequestions', component: GenerateQuestionsComponent, canActivate: [CheckLoggedIn] },

  { path: '', component: LoginComponent },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CanDeactivatePlayCrosswordComponent, CanDeactivateBuildCrosswordComponent],
})
export class AppRoutingModule {}
