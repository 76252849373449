/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Share } from '@capacitor/share';
import { ComponentCommunicationService, BrowserDeviceType } from '../componentcommunication/component-communication.service';
import { UserService } from '../user/user.service';

@Injectable({ providedIn: 'root' })
export class ShareService {
  constructor(
    private componentCommunicationService: ComponentCommunicationService,
    private userService: UserService,
  ) {}
  async supportsDeviceShare() {
    if (this.componentCommunicationService.deviceType.getValue() == BrowserDeviceType.Browser) {
      const navigator: any = window.navigator;
      return navigator.share != null;
    } else {
      const result = await Share.canShare();
      return result.value;
    }
  }

  async share(text: string, url: string) {
    const navigator: any = window.navigator;
    if (this.componentCommunicationService.deviceType.getValue() == BrowserDeviceType.Browser) {
      try {
        const result = await navigator.share({
          title: 'Krysskampen',
          text: text,
          url: url,
        });
        console.log('Shared with result', result);
      } catch (error) {
        console.error('Failed to share with result', error);
      }
    } else {
      try {
        await Share.share({ dialogTitle: 'Krysskampen', text: text, title: 'Krysskampen' });
      } catch (error) {
        console.log('Share failed:', error);
      }
    }
  }
}
