import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { QuestionAndAnswer } from '../../../../../../Common/data/question.and.answer';
import { CWQuestionCategory, CWQuestionDifficultyEnum, CWQuestionVisibility, ICWQuestion } from '../../../../../../Common/model/cwquestion';
import { LanguageUtil } from '../../../../../../Common/util/language.util';
import { CWQuestionService } from '../../../services/cwquestion/cwquestion.service';
import { GenerateQuestionsService } from '../../../services/generatequestions/generate.questions.service';
import { UserService } from '../../../services/user/user.service';
import { MessageBoxType } from '../../dialog/message-box-content/message-box-content.component';
import { DialogService } from '../../dialog/service/dialog.service';
import { CrosswordConstants } from '../../util/crossword.constants';
import { FormToGenerateQuestions, GeneratedQuestionsForm, UseQuestion, UseQuestionForm } from './question.form.models';

@Component({
  selector: 'app-generate-question',
  templateUrl: './generate-questions.component.html',
})
export class GenerateQuestionsComponent implements OnInit {
  Difficulties = CrosswordConstants.Difficulties;
  CWQuestionVisiblities = CrosswordConstants.CWQuestionVisiblities;
  CWQuestionCategories = CrosswordConstants.CWQuestionCategories;
  formToGenerateQuestions: FormGroup<FormToGenerateQuestions>;
  generatedQuestionsForm: FormGroup<GeneratedQuestionsForm>;

  constructor(
    private matDialogRef: MatDialogRef<GenerateQuestionsComponent>,
    private translateService: TranslateService,
    private cwQuestionService: CWQuestionService,
    private dialogService: DialogService,
    private userService: UserService,
    private generateQuestionsService: GenerateQuestionsService,
  ) {}
  async ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.formToGenerateQuestions = new FormGroup<FormToGenerateQuestions>({
      category: new FormControl(CWQuestionCategory.WordPlay, [Validators.required]),
      freeText: new FormControl(),
      onlyUseFreeText: new FormControl(),
    });
    this.generatedQuestionsForm = new FormGroup<GeneratedQuestionsForm>({
      questions: new FormArray([]),
      difficulty: new FormControl(CWQuestionDifficultyEnum.Medium, [Validators.required]),
      visibility: new FormControl(CWQuestionVisibility.All, [Validators.required]),
      tags: new FormControl<string[]>([]),
      tagInput: new FormControl<string>(''),
    });
  }

  async onGenerateQuestions() {
    if (this.generatedQuestionsForm.value.questions.length > 0) {
      const result = await this.dialogService.showMessageBox(
        '',
        this.translateService.instant('messages.check-generate-questions-again'),
        MessageBoxType.YesNo,
      );
      if (!result) {
        return;
      }
    }
    const formToGenerateQuestions = this.formToGenerateQuestions.value;
    const questionsAndAnswer: QuestionAndAnswer[] = await this.dialogService.wrapInProgress(
      () =>
        this.generateQuestionsService.generate({
          categoryTranslateKey: this.getCategoryTranslateKey(formToGenerateQuestions.category),
          freeText: formToGenerateQuestions.freeText,
          languageCode: LanguageUtil.getLanguageCode(this.translateService.getBrowserLang()),
          numberOfQuestions: 10,
          onlyFreeText: formToGenerateQuestions.onlyUseFreeText,
        }),
      this.translateService.instant('messages.generate-crossword-questions'),
    );
    if (questionsAndAnswer.length == 0) {
      await this.dialogService.showMessageBox(
        '',
        this.translateService.instant('messages.no-questions-when-generating-questions'),
        MessageBoxType.Ok,
      );
      return;
    }
    const formArray = this.generatedQuestionsForm.controls.questions;
    formArray.clear();
    for (const questionAndAnswer of questionsAndAnswer) {
      const control = new FormGroup<UseQuestionForm>({
        question: new FormControl<string>(questionAndAnswer.question, [Validators.required]),
        answer: new FormControl<string>(questionAndAnswer.answer, [
          Validators.required,
          Validators.pattern(this.translateService.instant('validators.supported-chars')),
        ]),
        useQuestion: new FormControl<boolean>(true),
      });
      formArray.push(control);
    }
  }

  async onSaveQuestions() {
    const questionsForms: UseQuestion[] = this.generatedQuestionsForm.controls.questions.value;
    const questionsToCreate = questionsForms.filter((c) => c.useQuestion);
    this.dialogService.wrapInProgress(async () => {
      const cwQuestions = questionsToCreate.map((q) => this.getQuestion(q));
      const questionsExists = await this.cwQuestionService.doesQuestionsAndAnswersExist(cwQuestions.map((c) => c.base));
      if (questionsExists.find((q) => q.exists)) {
        const questionsThatExists = questionsExists.filter((q) => q.exists);
        const text = questionsThatExists.map((q) => `${q.cwQuestion.question} - ${q.cwQuestion.answer}`).join('<p>');
        await this.dialogService.showMessageBox(
          '',
          this.translateService.instant('messages.questions-and-answers-exists', { questionsText: text }),
          MessageBoxType.Error,
        );
      } else {
        const savedQuestions = await this.cwQuestionService.createCWQuestions(cwQuestions);
        this.matDialogRef.close(savedQuestions);
      }
    });
  }
  onCancel() {
    this.matDialogRef.close(null);
  }
  getCategoryName(category: CWQuestionCategory) {
    return this.translateService.instant(this.getCategoryTranslateKey(category));
  }
  getCategoryTranslateKey(category: CWQuestionCategory) {
    return CrosswordConstants.CWQuestionCategories.find((c) => c.value == category)?.label;
  }
  private getQuestion(questionForm: UseQuestion): ICWQuestion {
    const formToGenerateValues = this.formToGenerateQuestions.value;
    const value = this.generatedQuestionsForm.value;
    return {
      languageCode: LanguageUtil.getLanguageCode(this.translateService.getBrowserLang()),
      base: { question: questionForm.question, answer: questionForm.answer, indexId: -1 },
      difficulty: value.difficulty,
      visibility: value.visibility,
      category: formToGenerateValues.category,
      tags: this.getTagsToSave(),
      author: this.userService.getLoggedIn(),
    } as ICWQuestion;
  }
  private async createQuestion(cwQuestionTmp: ICWQuestion) {
    const createCWQuestion: ICWQuestion = { ...cwQuestionTmp, author: this.userService.getLoggedIn() };
    return await this.cwQuestionService.createCWQuestion(createCWQuestion);
  }
  private getTagsToSave(): string[] {
    const value = this.generatedQuestionsForm.value;
    return value.tagInput ? [...value.tags, value.tagInput] : value.tags;
  }
}
