<div class="flex-column">
  <div *ngIf="loaded" fxLayout="column" fxLayoutGap="10px">
    <div fxLayoutAlign="end">
      <button mat-raised-button (click)="onCreateCrosswordBuild()">{{ 'buttons.create-crossword-build' | translate }}</button>
    </div>
    <mat-form-field fxFlex>
      <mat-label>{{ 'labels.filter-crossword-builds' | translate }}</mat-label>
      <input #filterInput matInput (keyup)="applyFilter(filterInput.value)" autocomplete="off" />
    </mat-form-field>
  </div>
  <mat-table
    #table
    *ngIf="loaded"
    id="list-crossword-builds-table"
    (matSortChange)="onSortChange($event)"
    matSort
    matSortActive="name"
    [dataSource]="crosswordBuilds"
  >
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'columns.name' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let crosswordBuild"> {{ crosswordBuild.name }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="updated">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'columns.updated' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let crosswordBuild"> {{ crosswordBuild.updated | date: 'yyyy-MM-dd' }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="published">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'columns.published' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let crosswordBuild">
        {{ crosswordBuild.published ? ('states.yes' | translate) : ('states.no' | translate) }}
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="dispayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let crosswordBuild; columns: dispayedColumns"
      [ngClass]="{ hovered: crosswordBuild.hovered }"
      (click)="onCrosswordBuildSelected(crosswordBuild)"
      (mouseover)="crosswordBuild.hovered = true"
      (mouseout)="crosswordBuild.hovered = false"
      (blur)="crosswordBuild.hovered = false"
      (focus)="crosswordBuild.hovered = true"
    >
    </mat-row>
  </mat-table>
  <mat-paginator
    [ngStyle]="{ display: !loaded || crosswordBuilds.data.length == 0 ? 'none' : 'block' }"
    [pageSizeOptions]="[10, 50, 100]"
  ></mat-paginator>
  <app-progress-bar *ngIf="!loaded"></app-progress-bar>
</div>
