import { PriorityValue } from '../../../../../Common/data/priority.value';
import { GamePlayerColor } from '../../../../../Common/model/crossword.game';

export class PriorityMatchColor {
  private static PriorityMatchColors: PriorityMatchColor[] = [];
  private static Low = new PriorityMatchColor(PriorityValue.Low, '--priority-match-color-low');
  private static Medium = new PriorityMatchColor(PriorityValue.Medium, '--priority-match-color-medium');
  private static High = new PriorityMatchColor(PriorityValue.High, '--priority-match-color-high');
  private static Theme = new PriorityMatchColor(PriorityValue.Theme, '--priority-match-color-theme');

  constructor(public readonly priority: PriorityValue, public readonly color: string) {
    PriorityMatchColor.PriorityMatchColors.push(this);
  }
  public static getColor(priority: PriorityValue) {
    for (const color of PriorityMatchColor.PriorityMatchColors) {
      if (color.priority == priority) {
        return getComputedStyle(document.documentElement).getPropertyValue(color.color);
      }
    }
    throw new Error('Unsupported PriorityMatchColor:' + priority);
  }
}
