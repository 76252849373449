import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export class TileKeyUp {
  public static Up: TileKeyUp = new TileKeyUp('arrowup');
  public static Down: TileKeyUp = new TileKeyUp('arrowdown');
  public static Right: TileKeyUp = new TileKeyUp('arrowright');
  public static Left: TileKeyUp = new TileKeyUp('arrowleft');
  public static Backspace: TileKeyUp = new TileKeyUp('backspace');
  public static Character: TileKeyUp = new TileKeyUp('character');
  private constructor(private keyCode: string) {}
  public checkKeyCode(keyCode: string) {
    return this.keyCode == keyCode;
  }
}

@Injectable({
  providedIn: 'root',
})
export class TileKeyService {
  private readonly supportedCharRegExp: string;
  private readonly simpleKeyCodes: TileKeyUp[] = [TileKeyUp.Up, TileKeyUp.Down, TileKeyUp.Right, TileKeyUp.Left, TileKeyUp.Backspace];
  constructor(private translateService: TranslateService) {
    this.supportedCharRegExp = this.translateService.instant('validators.supported-chars');
  }
  getTileKeyCode(key: string) {
    const lowerCase = key.toLowerCase();
    const keyCode = this.simpleKeyCodes.find((tileKeyUp) => tileKeyUp.checkKeyCode(lowerCase));
    if (keyCode) {
      return keyCode;
    }
    if (lowerCase.length == 1 && lowerCase.match(this.supportedCharRegExp)) {
      return TileKeyUp.Character;
    }
    return null;
  }
}
