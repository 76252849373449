import { ICrosswordSize } from '../model/crossword';
import { IndexPriority } from '../model/crossword.build';
import { QuestionBasePriority } from '../model/cwquestion';

export interface AccuracyParameters {
  questionsCount: number;
  randomQResets: number;
  themeQResets: number;
  crossingsNeeded: number;
  removePercent: number;
  addQuestions: {
    random: number;
    theme: number;
    questionsCountPerRound: number;
  };
  repositionQuestions: number;
}

export class GenerateCrosswordAccuracy {
  private static default: AccuracyParameters = {
    questionsCount: 100,
    randomQResets: 100,
    themeQResets: 30,
    crossingsNeeded: 1,
    removePercent: 10,
    addQuestions: { random: 10, theme: 10, questionsCountPerRound: 50 },
    repositionQuestions: 1,
  };
  static BuildRandomCrossword: AccuracyParameters = {
    questionsCount: 5000,
    randomQResets: 2800,
    themeQResets: 900,
    crossingsNeeded: 24,
    removePercent: 2,
    addQuestions: { random: 34, theme: 9, questionsCountPerRound: 714 },
    repositionQuestions: 8,
  };
  static Customize: AccuracyParameters = {
    questionsCount: 800,
    randomQResets: 4000,
    themeQResets: 400,
    crossingsNeeded: 5,
    removePercent: 8,
    addQuestions: { random: 15, theme: 9, questionsCountPerRound: 450 },
    repositionQuestions: 8,
  };
  static VeryHigh: AccuracyParameters = {
    ...GenerateCrosswordAccuracy.default,
    questionsCount: 2000,
    randomQResets: 6000,
    crossingsNeeded: 8,
  };
  static High: AccuracyParameters = {
    ...GenerateCrosswordAccuracy.default,
    questionsCount: 1000,
    randomQResets: 3000,
    crossingsNeeded: 6,
  };
  static Medium: AccuracyParameters = {
    ...GenerateCrosswordAccuracy.default,
    questionsCount: 300,
    randomQResets: 500,
    crossingsNeeded: 2,
  };
  static Low: AccuracyParameters = {
    ...GenerateCrosswordAccuracy.default,
    questionsCount: 100,
    randomQResets: 50,
    crossingsNeeded: 0,
  };
}

export interface BaseBuildSettings {
  questions: QuestionBasePriority[];
  accuracy: AccuracyParameters;
}
export interface GenerateCrosswordData extends BaseBuildSettings {
  size: ICrosswordSize;
}
export interface PriorityGenerateCrosswordData extends GenerateCrosswordData {}
export interface BuildJobGenerateCrosswordData {
  accuracy: AccuracyParameters;
  size: ICrosswordSize;
  questions: IndexPriority[];
  crosswordBuildId: any;
}
