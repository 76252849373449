<div listenForResize (sizeChanged)="onSizeChanged($event)" fxFlexFill class="flex-row">
  <app-crossword-tile-border
    *ngFor="let tile of tiles"
    [tile]="tile"
    [tilesState]="tilesState"
    [tileContainerType]="TileContainerType.Row"
    [ngStyle]="{
      'width.px': tileSize,
      'height.px': tileSize
    }"
  >
    <app-crossword-tile
      [tile]="tile"
      [tileSize]="tileSize"
      [tilesState]="tilesState"
      [tileContainerType]="TileContainerType.Row"
      (tileEvent)="onTileEvent($event)"
    ></app-crossword-tile
  ></app-crossword-tile-border>
</div>
