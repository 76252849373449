export class RetryUtil {
  static async retry<T>(method: () => T, checkValue: (value: T) => boolean, retryCount: number) {
    for (let i = 0; i < retryCount; i++) {
      const value = await method();
      if (checkValue(value)) {
        return value;
      }
    }
    return null;
  }
}
