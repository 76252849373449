export class AssertError extends Error {
  constructor(message?: string) {
    super(message);
  }
}
export class AssertUtil {
  static shouldAssert = true;
  static assert(assertFunction: () => boolean, assertMessage?: string): void {
    if (this.shouldAssert) {
      if (!assertFunction()) {
        const errorMessage = assertMessage != null ? assertMessage : `Assert failed for function:${assertFunction.toString()}`;
        throw new AssertError(errorMessage);
      }
    }
  }
  static assertNotNull(value: unknown, assertMessage?: string): void {
    this.assert(() => value != null, assertMessage);
  }
}
