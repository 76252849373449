import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { catchError } from 'rxjs/operators';
import { lastValueFrom, of } from 'rxjs';
import { UserService } from '../user/user.service';

@Injectable({ providedIn: 'root' })
export class StoreRouteService {
  constructor(private router: Router, private route: ActivatedRoute, private storage: StorageMap, private userService: UserService) {
    this.userService.waitUntilInitialized().then(() => this.listenForCurrentRouteAndStoreIt());
    this.route.queryParamMap.subscribe(async (paramMap) => {
      const clearLocalStorage = 'clearLocalStorage';
      if (paramMap.has(clearLocalStorage)) {
        await lastValueFrom(this.storage.clear());
      }
    });
  }
  private listenForCurrentRouteAndStoreIt() {
    this.router.events.subscribe((val) => {
      if (this.userService.isLoggedIn()) {
        if (val instanceof NavigationEnd) {
          this.storeUrl(this.router.url);
        }
      }
    });
  }
  private storeUrl(url: string) {
    this.storage.set('url', url).subscribe({
      error: (error) => {
        console.error('Failed to store url', error);
      },
    });
  }
  public async getStoredRoute() {
    const url: string = await lastValueFrom(this.storage.get('url', { type: 'string' }).pipe(catchError(() => of(null))));
    return url;
  }
}
