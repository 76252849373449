<div [formGroup]="questionForm" fxLayout="column" fxLayoutGap="5px" class="my-small-form-field">
  <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
    <mat-checkbox formControlName="useQuestion">{{ 'labels.use-question' | translate }}</mat-checkbox>
    <mat-icon (click)="checkAnswer()" style="cursor: pointer">info</mat-icon>
  </div>
  <mat-form-field subscriptSizing="dynamic" fxLayout="column">
    <mat-label>{{ 'labels.enter-question' | translate }}</mat-label>
    <input fxFlex id="enter-question" matInput formControlName="question" autocomplete="off" maxlength="50" />

    <mat-error *ngIf="questionForm.controls.question.hasError('required')">{{ 'errors.question-missing' | translate }}</mat-error>
  </mat-form-field>
  <div>
    <span>{{ 'labels.enter-answer' | translate }}</span>
    <app-edit-question-tiles [editQuestionData]="editQuestionTiles" [answer]="questionForm.controls.answer"></app-edit-question-tiles>
    <mat-error *ngIf="questionForm.controls.answer.hasError('required')">{{ 'errors.answer-missing' | translate }}</mat-error>
    <mat-error *ngIf="questionForm.controls.answer.hasError('pattern') && !questionForm.controls.answer.hasError('required')">{{
      'errors.answer-characters' | translate
    }}</mat-error>
    <mat-error
      *ngIf="questionForm.controls.answer.hasError('notOneOfFilterLengths') && !questionForm.controls.answer.hasError('required')"
      >{{ 'errors.answer-not-one-of-filter-lengths' | translate }}</mat-error
    >
  </div>
</div>
