import { Component, OnInit, Output } from '@angular/core';
import { ComponentCommunicationService } from '../../services/componentcommunication/component-communication.service';
import { PushNotificationService } from '../../services/pushnotifcation/push-notification.service';
import { FlexibleButtonInMenu, FlexibleButtonType } from '../common/flexable-button-menu/model/flexible.button.in.menu';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user/user.service';
import { SettingsQuestions } from '../../../../../Common/model/player.settings';
import { DialogService } from '../dialog/service/dialog.service';
import { MessageBoxType } from '../dialog/message-box-content/message-box-content.component';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss'],
})
export class NotificationBarComponent implements OnInit {
  @Output()
  showToolbar = false;
  notificationButtons: FlexibleButtonInMenu[] = [];
  constructor(
    public componentCommunication: ComponentCommunicationService,
    private notificationService: PushNotificationService,
    private translateService: TranslateService,
    private userService: UserService,
    private dialogService: DialogService,
  ) {}

  async ngOnInit() {
    this.refreshButtons();
    if (await this.hasAPushSubscription()) {
      await this.notificationService.listenForMessagesIfNotListening();
      this.showToolbar = false;
    } else {
      this.showToolbar = await this.shouldShowToolbar();
    }
  }
  private refreshButtons() {
    this.notificationButtons.push({
      id: 'subscribe-to-notifications',
      text: this.translateService.instant('buttons.subscribe-to-notifications'),
      icon: 'check',
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.subscribeToNotifications() },
    });
    this.notificationButtons.push({
      id: 'dont-subscribe-to-notifications',
      text: this.translateService.instant('buttons.dont-subscribe-to-notifications'),
      icon: 'not_interested',
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.dontSubscribeToNotifications() },
    });
  }

  async subscribeToNotifications() {
    await this.notificationService.subscribeToNotifications();
    this.showToolbar = false;
  }
  async dontSubscribeToNotifications() {
    this.showToolbar = false;
    const settings = this.userService.getPlayerSettings();
    const answer = await this.dialogService.showMessageBox(
      '',
      this.translateService.instant('messages.ask-for-push-notifcation-again'),
      MessageBoxType.YesNo,
    );
    if (!answer) {
      settings.settingsQuestionsDismissed.push(SettingsQuestions.SubscribeToNotifications);
      await this.userService.savePlayerSettings();
    }
  }
  private async shouldShowToolbar() {
    if (!this.notificationService.supportsPushNotification()) {
      return false;
    }
    if (await this.notificationService.hasSubscription()) {
      return false;
    }
    const settings = this.userService.getPlayerSettings();
    if (settings.settingsQuestionsDismissed.find((setting) => setting == SettingsQuestions.SubscribeToNotifications)) {
      return false;
    }
    return true;
  }
  private async hasAPushSubscription() {
    if (!this.notificationService.supportsPushNotification()) {
      return false;
    }
    return await this.notificationService.hasSubscription();
  }
}
