import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, Subject } from 'rxjs';
import { AddCWQuestionUtil } from '../../../../../../Common/builder/util/add.cwquestion.util';
import { Direction, Position } from '../../../../../../Common/data/position';
import { IndexPriority } from '../../../../../../Common/model/crossword.build';
import { ICWQuestion, ICWQuestionBase } from '../../../../../../Common/model/cwquestion';
import { AssertUtil } from '../../../../../../Common/util/assert.util';
import { CWQuestionUtil, FilterCWQuestion } from '../../../../../../Common/util/cwquestion.util';
import { IndexPriorityUtil } from '../../../../../../Common/util/index.priority.util';
import { CWQuestionService } from '../../../services/cwquestion/cwquestion.service';
import { AddQuestionDialogComponent, AddQuestionDialogData } from '../../dialog/add-question-dialog/add-question-dialog.component';
import { AskDirection } from '../../dialog/ask-direction-message-box/ask-direction-message-box.component';
import { DialogService } from '../../dialog/service/dialog.service';
import { EditQuestionDialogService } from '../../dialog/service/edit-question-dialog.service';
import { CrosswordConstants } from '../../util/crossword.constants';
import { AddQuestionTileData } from '../../util/handleCrosswordView/build.crossword.handle.crossword.view';
import { CrosswordBuildForm } from '../build-crossword.component';

export class AddQuestionInBuildUtil {
  constructor(
    private crosswordBuildForm: CrosswordBuildForm,
    private sampleQuestionsChanged: Subject<void>,
    private dialogService: DialogService,
    private matDialog: MatDialog,
    private cwquestionService: CWQuestionService,
    private editQuestionDialogService: EditQuestionDialogService,
    private translateService: TranslateService,
  ) {}
  async addQuestion(addQuestionTileData: AddQuestionTileData) {
    const addCWQuestionUtil = new AddCWQuestionUtil(this.crossword);
    const addQuestionToDirection = await this.getDirectionToAddQuestion(addCWQuestionUtil, addQuestionTileData);
    if (addQuestionToDirection == null) {
      return;
    }
    const newQuestionPosition = new Position(addQuestionTileData.x, addQuestionTileData.y, addQuestionToDirection);
    const filter = CWQuestionUtil.removeFiltersLongerThen(
      addCWQuestionUtil.getFilterForQuestions(newQuestionPosition),
      CrosswordConstants.MaxNumberOfChars,
    );
    const data: AddQuestionDialogData = {
      crosswordBuildForm: this.crosswordBuildForm,
      sampleQuestionsChanged: this.sampleQuestionsChanged,
      filterCharsAndLengths: filter,
    };
    const questionIndexId: number = await this.addOrSelectQuestion(filter, data);
    if (questionIndexId) {
      const priorityIndex = await this.getPriorityIndex(questionIndexId);
      const cwQuestions = await this.cwquestionService.loadQuestionsWithIndexIds([questionIndexId]);
      AssertUtil.assert(() => cwQuestions.length == 1);
      const cwQuestion = cwQuestions[0];
      this.assertThatQuestionFits(newQuestionPosition, cwQuestion);

      this.crossword.crosswordQuestions.push({
        cwQuestion: cwQuestion,
        position: newQuestionPosition,
        priority: priorityIndex.priority,
      });
      this.crosswordBuildForm.cwQuestionsIds.setValue(
        IndexPriorityUtil.getIndexesWithIndexes(this.crosswordBuildForm.cwQuestionsIds.value, [priorityIndex]),
      );
      this.sampleQuestionsChanged.next();
      return true;
    }
    return false;
  }
  private async addOrSelectQuestion(filter: FilterCWQuestion, data: AddQuestionDialogData) {
    if (await this.doesItExistAQuestionThatFits(filter)) {
      return await lastValueFrom(
        this.matDialog
          .open(AddQuestionDialogComponent, {
            data,
            width: `${window.innerWidth - 20}px`,
            maxHeight: '600px',
            maxWidth: `${window.innerHeight - 20}px`,
          })
          .afterClosed(),
      );
    } else {
      const emptyQuestion: ICWQuestion = CWQuestionUtil.getEmptyQuestion(this.translateService.getBrowserLang());
      const cwQuestion: ICWQuestion = await this.editQuestionDialogService.showEditQuestionDialog({
        cwQuestion: emptyQuestion,
        filter: filter,
      });
      return cwQuestion?.base.indexId;
    }
  }

  private async doesItExistAQuestionThatFits(filter: FilterCWQuestion) {
    const idsInCrossword = this.crosswordBuildForm.crossword.value.crosswordQuestions.map((q) => q.cwQuestion.indexId);
    const result = await this.cwquestionService.loadQuestions({ advfilter: filter, pageIndex: 0, pageSize: 1, notIds: idsInCrossword });
    return result.queryResult.length > 0;
  }

  private assertThatQuestionFits(newQuestionPosition: Position, cwQuestion: ICWQuestionBase) {
    const addCWQuestion = new AddCWQuestionUtil(this.crossword);
    AssertUtil.assert(() => addCWQuestion.canFitQuestion(newQuestionPosition, cwQuestion));
  }

  private async getDirectionToAddQuestion(addCWQuestionUtil: AddCWQuestionUtil, addQuestionTileData: AddQuestionTileData) {
    const canFitMainAxis = addCWQuestionUtil.canFitNewQuestion(
      new Position(addQuestionTileData.x, addQuestionTileData.y, Direction.MAINAXIS),
    );
    const canFitCrossAxis = addCWQuestionUtil.canFitNewQuestion(
      new Position(addQuestionTileData.x, addQuestionTileData.y, Direction.CROSSAXIS),
    );
    if (canFitMainAxis && canFitCrossAxis) {
      const direction = await this.dialogService.showAskDirectionDialog();
      if (direction == AskDirection.Side) {
        return addQuestionTileData.matrixDirection;
      } else if (direction == AskDirection.Down) {
        return addQuestionTileData.matrixDirection == Direction.MAINAXIS ? Direction.CROSSAXIS : Direction.MAINAXIS;
      }
    } else if (canFitMainAxis) {
      return Direction.MAINAXIS;
    } else {
      return Direction.CROSSAXIS;
    }
  }
  private get crossword() {
    return this.crosswordBuildForm.crossword.value;
  }
  private async getPriorityIndex(questionIndexId: number) {
    const priorityIndex = IndexPriorityUtil.findIndex(this.crosswordBuildForm.cwQuestionsIds.value, questionIndexId);
    if (priorityIndex) {
      return priorityIndex;
    }
    const priorityValue = await this.dialogService.showQuestionPriorityDialog(true);
    if (priorityValue == null) {
      return;
    }
    const value: IndexPriority = { index: questionIndexId, priority: priorityValue };
    return value;
  }
}
