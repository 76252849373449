import { Component } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';
import { ErrorResult, ErrorResultUtil, ErrorType } from '../../../../../../Common/data/error.result';
import { LanguageService } from '../../../services/language/language.service';
import { PropertyErrorStateMatcher } from '../../../util/property.error.state.matcher';
import { MessageBoxType } from '../../dialog/message-box-content/message-box-content.component';
import { UserCodeType } from '../../dialog/number-code/number-code.component';
import { CaptchaDialogService } from '../../dialog/service/captcha-dialog.service';
import { DialogService } from '../../dialog/service/dialog.service';
import { UserCodeDialogService } from '../../dialog/service/user-code-dialog.service';

interface SetNewPasswordForm {
  password: FormControl<string>;
  confirmPassword: FormControl<string>;
}
interface ForgotUserForm {
  email: FormControl<string>;
}
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent {
  confirmPasswordMatcher = new PropertyErrorStateMatcher('notTheSamePassword');
  confirmPasswordValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    const pass = control.get('password').value;
    const confirmPassword = control.get('confirmPassword').value;
    return pass === confirmPassword ? null : { notTheSamePassword: true };
  };

  forgotUserForm: FormGroup<ForgotUserForm>;
  newPasswordForm: FormGroup<SetNewPasswordForm>;
  checkedAndConfirmedThatPlayerIsHuman = false;
  showSetNewPassword = false;
  userCode: number;
  constructor(
    private userService: UserService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private capchaDialogService: CaptchaDialogService,
    private languageService: LanguageService,
    private router: Router,
    private userCodeDialogService: UserCodeDialogService,
  ) {
    this.createForm();
  }
  private createForm() {
    this.forgotUserForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });
    this.newPasswordForm = new FormGroup<SetNewPasswordForm>(
      {
        password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        confirmPassword: new FormControl('', [Validators.required]),
      },
      { validators: [this.confirmPasswordValidator] },
    );
  }
  async checkThatPlayerIsHuman() {
    this.checkedAndConfirmedThatPlayerIsHuman = await this.capchaDialogService.showDialog();
  }
  async onSubmitForValidationCode() {
    const forgotUserValues = this.forgotUserForm.value;
    const result = await ErrorResultUtil.ExecuteAndCatchError(
      () =>
        this.dialogService.wrapInProgress(
          () =>
            this.userService.forgotPassword({
              email: forgotUserValues.email,
              languageCode: this.languageService.getLanugageCode(),
            }),
          this.translateService.instant('messages.sending-mail-for-forgot-password-code'),
        ),
      ErrorType.WRONG_EMAIL,
      async () => {
        await this.dialogService.showMessageBox(
          '',
          this.translateService.instant('messages.forgot-password-wrong-email'),
          MessageBoxType.Error,
        );
        return false;
      },
    );
    if (!result) {
      return;
    }
    const userCode: number = await this.userCodeDialogService.showAskUserCode({
      type: UserCodeType.ForgotPassword,
      email: forgotUserValues.email,
    });
    if (userCode) {
      this.userCode = userCode;
      this.showSetNewPassword = true;
    }
  }
  async onSubmitForNewPassword() {
    try {
      await this.dialogService.wrapInProgress(async () => {
        const controls = this.newPasswordForm.controls;
        const forgotUserForm = this.forgotUserForm.controls;
        await this.userService.forgotPasswordSetNewPassword({
          password: controls.password.value,
          code: this.userCode,
          email: forgotUserForm.email.value,
        });
      }, this.translateService.instant('messages.resetting-password'));
      await this.dialogService.showMessageBox('', this.translateService.instant('messages.password-changed'), MessageBoxType.Ok);
      this.router.navigateByUrl('/login');
    } catch (error) {
      const errorResult: ErrorResult = error.error;
      if (errorResult?.type == ErrorType.USER_CODE_DOSNT_MATCH) {
        await this.dialogService.showMessageBox(
          '',
          this.translateService.instant('messages.forgot-password-wrong-usercode'),
          MessageBoxType.Error,
        );
        return;
      }

      throw error;
    }
  }
}
