import { GameState } from '../data/game.state';
import { GameType } from '../data/game.type';
import { PlayerInfo } from '../data/player.score';
import { GamePlayerColor, ICrosswordGame, SelectableGamePlayerColors } from '../model/crossword.game';
import { IPlayer } from '../model/player';
import { AssertUtil } from './assert.util';
import { CrosswordGamePlayBase, CrosswordGameQuestion } from './crossword.game.play.base';
import { CrosswordGamePlayerUtil } from './crossword.game.player.util';
import { CrosswordGameTurnUtil } from './crossword.game.turn.util';
import { IdUtil } from './id.util';
import { ObjectUtil } from './object.util';
export class CrosswordGameUtil {
  static areAllCharsSet(crosswordGame: ICrosswordGame) {
    const crosswordPlay = new CrosswordGamePlayBase();
    crosswordPlay.setCrosswordGame(crosswordGame);
    crosswordPlay.refreshTiles();
    return crosswordPlay.areAllCharsSet();
  }
  static getGameQuestionsSortedAfterDistanceToTopLeft(crosswordQuestions: CrosswordGameQuestion[]) {
    return crosswordQuestions.sort((c1, c2) => Math.hypot(c1.interval.x, c1.interval.y) - Math.hypot(c2.interval.x, c2.interval.y));
  }
  static isCreatorAndHasntSetPlayerColor(crosswordGame: ICrosswordGame, player: IPlayer) {
    if (!IdUtil.idEquals(crosswordGame.initial.createdByPlayerId, player._id)) {
      return false;
    }
    const playerInfo = CrosswordGamePlayerUtil.getPlayerInfo(crosswordGame, player._id);
    AssertUtil.assert(() => playerInfo != null);
    return playerInfo.gamePlayerColor == GamePlayerColor.Default;
  }
  static shouldUpdateCrosswordTiles(crosswordGameBefore: ICrosswordGame, crosswordGameChangedTo: ICrosswordGame) {
    return crosswordGameChangedTo.version.turns > crosswordGameBefore.version.turns;
  }
  static shouldUpdatePlayersInfoOnTiles(crosswordGameBefore: ICrosswordGame, crosswordGameChangedTo: ICrosswordGame) {
    return crosswordGameChangedTo.version.playersInfo > crosswordGameBefore.version.playersInfo;
  }
  static shouldUpdateCrosswordChats(crosswordGameBefore: ICrosswordGame, crosswordGameChangedTo: ICrosswordGame) {
    return crosswordGameChangedTo.version.chats > crosswordGameBefore.version.chats;
  }
  static addPlayerScoreTo(crosswordGame: ICrosswordGame, score: number, percentageCorrect: number) {
    const playerInfo = CrosswordGamePlayerUtil.findCurrentPlayerInfo(crosswordGame);
    playerInfo.score += score;
    playerInfo.percentageCorrect += percentageCorrect;
    playerInfo.lastScore = score;
  }
  static setCharStoredToPlayer(crosswordGame: ICrosswordGame, charsStored: number) {
    const playerInfo = CrosswordGamePlayerUtil.findCurrentPlayerInfo(crosswordGame);
    playerInfo.storedChars = charsStored;
  }
  static getCharStoredToCurrentPlayer(crosswordGame: ICrosswordGame) {
    const playerInfo = CrosswordGamePlayerUtil.findCurrentPlayerInfo(crosswordGame);
    return playerInfo.storedChars;
  }
  static getAvailablePlayerColors(crosswordGame: ICrosswordGame) {
    const currentPlayerColors = new Set<GamePlayerColor>(crosswordGame.playersInfo.playerInfo.map((p) => p.gamePlayerColor));
    return SelectableGamePlayerColors.filter((sc) => !currentPlayerColors.has(sc));
  }
  static getCopyOfCrosswordGame(crosswordGame: ICrosswordGame): ICrosswordGame {
    return ObjectUtil.copyAndClean(crosswordGame);
  }
  static getHighestScorePlayer(crosswordGame: ICrosswordGame) {
    let highestPlayerScore: PlayerInfo = crosswordGame.playersInfo.playerInfo[0];
    for (const playerScore of crosswordGame.playersInfo.playerInfo) {
      if (playerScore.score > highestPlayerScore.score) {
        highestPlayerScore = playerScore;
      }
    }
    return highestPlayerScore;
  }
  static startGame(crosswordGame: ICrosswordGame) {
    this.startGameAsPlayer(crosswordGame, CrosswordGamePlayerUtil.getRandomPlayer(crosswordGame)._id);
  }
  static startGameAsPlayer(crosswordGame: ICrosswordGame, playerId: any) {
    crosswordGame.global.type = crosswordGame.playersInfo.players.length > 1 ? GameType.Match : GameType.Single;
    crosswordGame.global.state = GameState.Started;
    crosswordGame.turns.currentPlayerId = playerId;
    crosswordGame.turns.totalNumberOfTurns = CrosswordGameTurnUtil.getTotalNumberOfTurns(
      crosswordGame.playersInfo.players.length,
      crosswordGame.initial.crossword
    );
  }
  static getNumberOfCorrectQuestions(crosswordGame: ICrosswordGame) {
    const crosswordPlay = new CrosswordGamePlayBase();
    crosswordPlay.setCrosswordGame(crosswordGame);
    crosswordPlay.refreshTiles();
    return crosswordPlay.getQuestionsCompleted().length;
  }
}
