export class CachedList<T> {
  private list: T[] = [];
  private lastTime: number = Number.MIN_SAFE_INTEGER;
  constructor(private cacheTime: number) {}
  addItems(items: T[]) {
    this.list = items;
    this.lastTime = Date.now();
  }
  getItems() {
    return this.list;
  }
  hasExpired() {
    return Date.now() > this.lastTime + this.cacheTime;
  }
  clear() {
    this.lastTime = Number.MIN_SAFE_INTEGER;
  }
}
