export class BrowserUtil {
  static isIOS() {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
  static isPWA(): boolean {
    const mqStandAlone = '(display-mode: standalone)';
    if ((<any>window.navigator).standalone || window.matchMedia(mqStandAlone).matches) {
      return true;
    }
    return false;
  }
  static isChromeBrowser() {
    const windowAny: any = window;
    return windowAny.chrome != null;
  }
  static supportsPushNotification() {
    if (this.isIOS()) {
      return this.isPWA();
    } else {
      return true;
    }
  }
}
