<div fxLayout="row">
  <div
    #showCrossword
    id="showCrossword"
    *ngIf="matrixTilesMain && rowTiles"
    listenForResize
    (sizeChanged)="onSizeChanged($event)"
    fxFlexFill
    style="position: relative"
  >
    <div
      fxFlexFill
      id="matrixContainer"
      [ngClass]="{ showing: showCrosswordAs == ShowCrosswordAs.Matrix, hidden: showCrosswordAs != ShowCrosswordAs.Matrix }"
    >
      <div
        fxLayout="row"
        [ngStyle]="{
          position: 'relative',
          width: '100%',
          'height.px': (matrixDirection == Direction.MAINAXIS ? crosswordSize.crossAxis : crosswordSize.mainAxis) * matrixTileSize
        }"
      >
        <div fxLayout="column" *ngFor="let tileColumn of matrixDirection == Direction.MAINAXIS ? matrixTilesMain : matrixTilesCross">
          <div *ngFor="let tile of tileColumn" fxLayout="row">
            <app-crossword-tile-border
              [tile]="tile"
              [matrixDirection]="matrixDirection"
              [tilesState]="tilesState"
              [tileContainerType]="TileContainerType.Matrix"
              [ngStyle]="{
                'width.px': matrixTileSize,
                'height.px': matrixTileSize
              }"
            >
              <app-crossword-tile
                [tile]="tile"
                [tileSize]="matrixTileSize"
                [tilesState]="tilesState"
                [matrixDirection]="matrixDirection"
                [tileContainerType]="TileContainerType.Matrix"
                [showCrosswordAs]="showCrosswordAs"
                (tileEvent)="onMatrixTileEvent($event)"
              ></app-crossword-tile>
            </app-crossword-tile-border>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rowContainer"
      [ngClass]="{ showing: showCrosswordAs == ShowCrosswordAs.List, hidden: showCrosswordAs != ShowCrosswordAs.List }"
      class="flex-column"
    >
      <mat-list>
        <ng-container *ngFor="let row of rowTiles">
          <div class="flex-column" style="padding-bottom: 10px">
            <app-show-row-question [cwQuestion]="row.cwQuestion"></app-show-row-question>
            <div class="flex-row">
              <app-crossword-tile-border
                *ngFor="let tile of row.tiles"
                [tile]="tile"
                [tilesState]="tilesState"
                [tileContainerType]="TileContainerType.Row"
                [ngStyle]="{
                  'width.px': rowTileSize,
                  'height.px': rowTileSize
                }"
              >
                <app-crossword-tile
                  [tile]="tile"
                  [tileSize]="rowTileSize"
                  [tilesState]="tilesState"
                  [tileContainerType]="TileContainerType.Row"
                  [showCrosswordAs]="showCrosswordAs"
                  (tileEvent)="onRowTileEvent($event, row.tiles)"
                ></app-crossword-tile
              ></app-crossword-tile-border>
            </div>
          </div>
        </ng-container>
      </mat-list>
    </div>
    <ng-container *ngIf="showShowQuestionText">
      <app-show-all-question
        *ngIf="showShowQuestionText.question.type != CWQuestionType.Image"
        [questionPosition]="showShowQuestionText.position"
        [question]="showShowQuestionText.question"
        [size]="matrixTileSize"
        [showCrosswordWidth]="tileContainerWidth | async"
        style="z-index: 100"
      ></app-show-all-question>
      <app-show-all-image
        *ngIf="showShowQuestionText.question.type == CWQuestionType.Image"
        [questionPosition]="showShowQuestionText.position"
        [question]="showShowQuestionText.question"
        [size]="matrixTileSize"
        [showCrosswordWidth]="tileContainerWidth | async"
        [matrixDirection]="matrixDirection"
        [crosswordSize]="crosswordSize"
        style="z-index: 100"
      ></app-show-all-image>
    </ng-container>
  </div>
</div>
