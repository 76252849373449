import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CWQuestionService } from '../../../services/cwquestion/cwquestion.service';

@Component({
  selector: 'app-cwquestion-tags',
  templateUrl: './cwquestion-tags.component.html',
})
export class CWQuestionTagsComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<string[]>;

  @Input()
  tagInput: FormControl<string>;
  @Input()
  tags: FormControl<string[]>;

  allTags: string[];
  @ViewChild('tagsInputElement') tagsInputElement: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private cwQuestionService: CWQuestionService) {}
  async ngOnInit() {
    this.allTags = await this.cwQuestionService.getTags();
    this.filteredTags = this.tagInput.valueChanges.pipe(
      map((tag: string | null) => (tag ? this.getInputTags(tag) : this.getSelectableTags())),
    );
  }
  onMatChipSeperatorKey(): void {
    //Allow time for tag to be selected
    setTimeout(() => {
      const inputValue = this.tagsInputElement.nativeElement.value?.trim();
      if (inputValue) {
        this.addTag(inputValue);
      }
      this.tagsInputElement.nativeElement.value = '';
      this.tagInput.setValue(null);
    }, 100);
  }

  onMatChipRemoved(tag: string): void {
    const tags: string[] = this.tags.value;
    const index = tags.indexOf(tag);
    if (index >= 0) {
      tags.splice(index, 1);
    }
  }

  onTagSelected(event: MatAutocompleteSelectedEvent): void {
    this.addTag(event.option.viewValue);
    this.tagsInputElement.nativeElement.value = '';
    this.tagInput.setValue(null);
  }

  private getInputTags(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.getSelectableTags().filter((tag) => tag.toLowerCase().indexOf(filterValue) === 0);
  }
  private getSelectableTags() {
    const tags = this.getTagsAsLowerCaseSet();
    return this.allTags.filter((tag) => !tags.has(tag.toLowerCase()));
  }
  private addTag(tag: string) {
    const tags: string[] = this.tags.value;
    if (!tags.find((tagTmp) => tagTmp.toLowerCase() == tag.toLowerCase())) {
      tags.push(tag);
    }
  }
  private getTagsAsLowerCaseSet() {
    const tags: string[] = this.tags.value;
    const tagsLowerCase = tags.map((category) => category.toLowerCase());
    return new Set<string>(tagsLowerCase);
  }
}
