import { CrosswordGameNotification, GameNotificationType } from '../data/crossword.game.notification';
import { IPlayer } from '../model/player';
import { IdUtil } from './id.util';

export class GenerateNotificationUtil {
  static generateNotification(player: IPlayer, gameId: string, type: GameNotificationType, messageData?: any): CrosswordGameNotification {
    return {
      id: IdUtil.getSimpleNewId(),
      playerId: player._id,
      playerName: player.name,
      gameId: gameId,
      type: type,
      messageData: messageData,
    };
  }
}
