import { Injectable } from '@angular/core';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { MessageBoxType } from '../../components/dialog/message-box-content/message-box-content.component';
import { DialogService } from '../../components/dialog/service/dialog.service';
import { UserService } from '../user/user.service';
import { PushNotificationInterface } from './push.notification.interface';

export interface MessageToServiceWorker {
  command: string;
  value: any;
}

@Injectable({
  providedIn: 'root',
})
export class FireBaseNotificationService implements PushNotificationInterface {
  private notificationMessages = new Subject<any>();
  constructor(
    private readonly userService: UserService,
    private translateService: TranslateService,
    private dialogService: DialogService,
  ) {}

  async openNotification(): Promise<void> {
    //TODO opennotifcation
  }

  getNotificationMessageObservable() {
    return this.notificationMessages.asObservable();
  }
  async closeNotifications() {
    if (await this.hasSubscription()) {
      await PushNotifications.removeAllDeliveredNotifications();
    }
  }
  async subscribeToNotifications(): Promise<boolean> {
    const playerSettings = this.userService.getPlayerSettings();
    const result = await PushNotifications.requestPermissions();
    if (result.receive != 'granted') {
      this.dialogService.showMessageBox('', this.translateService.instant('messages.notifiction-not-approved'), MessageBoxType.Ok);
      return false;
    }
    PushNotifications.register();
    const token = await this.getToken();

    this.listenForMessages();
    if (playerSettings.fireBaseToken?.value != token.value) {
      this.setTokenInUserSettings(token);
      this.dialogService.showSnackbar(this.translateService.instant('messages.listening-for-notifications'), 3000);
    }
    return true;
  }
  public async listenForMessagesIfNotListening() {
    this.subscribeToNotifications();
  }
  private listenForMessages() {
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      // handle the notification payload
      this.notificationMessages.next(notification.data);
    });
    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      // Take needed action on notification tap
      this.notificationMessages.next(notification.notification.data);
    });
  }
  public supportsPushNotification() {
    return true;
  }
  async hasSubscription() {
    const permissionStatus = await PushNotifications.checkPermissions();
    if (permissionStatus.receive != 'granted') {
      return false;
    }
    PushNotifications.register();
    const token = await this.getToken();
    const settings = this.userService.getPlayerSettings();
    return settings.fireBaseToken?.value == token.value;
  }
  async unsubscribeToNotifications() {
    await PushNotifications.removeAllListeners();
  }
  private setTokenInUserSettings(token: Token) {
    const settings = this.userService.getPlayerSettings();
    settings.fireBaseToken = token;
    this.userService.savePlayerSettings();
  }
  private getToken() {
    return new Promise<Token>((resolve, reject) => {
      PushNotifications.addListener('registration', (token: Token) => {
        resolve(token);
      });
      PushNotifications.addListener('registrationError', (error: any) => {
        reject(error);
      });
    });
  }
}
