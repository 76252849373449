<mat-dialog-content style="max-height: 520px">
  <app-select-cwquestions
    [crosswordBuildForm]="data.crosswordBuildForm"
    [sampleQuestionsChanged]="data.sampleQuestionsChanged"
    [filterVisibleQuestions]="data.filterCharsAndLengths"
    [type]="SelectCWQuestionType.SelectOne"
    (selectedCWQuestionsChanged)="onSelectedQuestionsChanged($event)"
    (addedNewQuestion)="onAddedNewQuestion($event)"
  ></app-select-cwquestions>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxFlex fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button [disabled]="selectedQuestionIndexId < 0" [mat-dialog-close]="selectedQuestionIndexId">
      {{ 'buttons.select-add-question' | translate }}
    </button>
    <button mat-raised-button mat-dialog-close>
      {{ 'buttons.cancel' | translate }}
    </button>
  </div>
</mat-dialog-actions>
