import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class PropertyErrorStateMatcher implements ErrorStateMatcher {
  constructor(private checkForProperty: string) {}
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if (control == null || form == null) {
      throw new Error('Cannot attach a property error state matcher to something without control or form');
    }
    const invalidCtrl = control.invalid && control.dirty;
    const invalidPropertyInForm = form.hasError(this.checkForProperty) && (form.touched || form.invalid);
    return invalidCtrl || invalidPropertyInForm;
  }
}
