import { TranslateService } from '@ngx-translate/core';
import { LanguageUtil } from '../../../../../Common/util/language.util';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private translateService: TranslateService,
    private window: Window,
  ) {}

  getLocale(): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowAny: any = this.window;
    if (windowAny.locale) {
      return windowAny.locale;
    }
    return this.translateService.getBrowserLang();
  }
  getLanugageCode() {
    return LanguageUtil.getLanguageCode(this.getLocale());
  }
}
