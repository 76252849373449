import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HandleCrosswordView, ShowCrosswordAs } from '../../util/handleCrosswordView/handle.crossword.view';

@Component({
  selector: 'app-toggle-show-crossword',
  templateUrl: './toggle-show-crossword.component.html',
  styleUrls:['./toggle-show-crossword.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleShowCrosswordComponent {
  ShowCrosswordAs = ShowCrosswordAs;
  @Input()
  handleCrosswordView: HandleCrosswordView;
}
