<div
  *ngIf="tile.type == TileType.Question || tile.type == TileType.PriorityQuestion"
  class="stretch-max flex-column disable-text-selection"
  style="cursor: pointer; position: relative; justify-content: center"
  (click)="onQuestionTileClicked()"
>
  <app-show-priority
    [priority]="tile.tileCWQuestion.priority"
    [tileSize]="tileSize"
    [questionDirection]="questionDirection"
    [tileType]="tile.type"
  ></app-show-priority>
  <app-show-tile-question [tileSize]="tileSize" [question]="tile.cwQuestions[0]"></app-show-tile-question>
  <app-show-direction [tileSize]="tileSize" [questionDirection]="questionDirection"></app-show-direction>
</div>
<div *ngIf="tile.type === TileType.Input" class="stretch-max flex-column">
  <input
    [id]="'tile-' + tile.x + '-' + tile.y"
    #tileInput
    class="tile-input stretch-max"
    [disabled]="!canEdit"
    [ngStyle]="{
      'font-size.px': tileSize / 2
    }"
    [attr.maxlength]="1"
    (focus)="onFocused()"
    (keyup)="onKeyup($event)"
    [value]="tile.text"
    (click)="tileInput.select()"
    autocomplete="off"
  />
</div>
<div
  *ngIf="tile.type === TileType.AddQuestion"
  class="stretch-max flex-column disable-text-selection"
  style="justify-content: center; align-items: center"
  (click)="onAddQuestionTileClicked()"
>
  <mat-icon>add</mat-icon>
</div>
<div
  *ngIf="tile.type == TileType.SetChar"
  class="stretch-max flex-column disable-text-selection"
  style="justify-content: center; align-items: center"
  (click)="onReadonlyCharClicked()"
>
  <span style="cursor: pointer" [ngStyle]="{ 'font-size.px': tileSize / 2 }">{{ tile.text.toUpperCase() }} </span>
</div>
