import { GamePlayerColor } from '../../../../../Common/model/crossword.game';

export class PlayerMatchColor {
  private static PlayerMatchColors: PlayerMatchColor[] = [
    new PlayerMatchColor(GamePlayerColor.Default, '--tile-style-set-by-default-this-turn', '--tile-style-set-by-default'),
    new PlayerMatchColor(GamePlayerColor.PlayerColor1, '--tile-style-set-by-player1-this-turn', '--tile-style-set-by-player1'),
    new PlayerMatchColor(GamePlayerColor.PlayerColor2, '--tile-style-set-by-player2-this-turn', '--tile-style-set-by-player2'),
    new PlayerMatchColor(GamePlayerColor.PlayerColor3, '--tile-style-set-by-player3-this-turn', '--tile-style-set-by-player3'),
    new PlayerMatchColor(GamePlayerColor.PlayerColor4, '--tile-style-set-by-player4-this-turn', '--tile-style-set-by-player4'),
  ];

  constructor(
    public readonly playerMatch: GamePlayerColor,
    public readonly thisTurnColor: string,
    public readonly color: string,
  ) {}
  public static getColor(playerMatch: GamePlayerColor, thisTurn: boolean) {
    for (const playerMatchColor of PlayerMatchColor.PlayerMatchColors) {
      if (playerMatchColor.playerMatch == playerMatch) {
        return getComputedStyle(document.documentElement).getPropertyValue(
          thisTurn ? playerMatchColor.thisTurnColor : playerMatchColor.color,
        );
      }
    }
    return this.PlayerMatchColors[0].color;
  }
}
