<div fxFlexFill fxLayoutAlign="center center">
  <mat-card fxFlex="0 1 500px" style="margin: 5px 0px">
    <form id="login-form" fxLayout="column" fxFlex [formGroup]="createUserForm" (ngSubmit)="onSubmit()" novalidate>
      <mat-card-header>
        <mat-card-title>
          {{ 'titles.create-user' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content fxFlex>
        <div class="form-group" fxFlex fxLayout="column" fxLayoutGap="10px">
          <mat-form-field>
            <mat-label>{{ 'labels.enter-name' | translate }}</mat-label>
            <input test-id="enter-name" matInput formControlName="name" required autocomplete="off" />
            <mat-error *ngIf="createUserForm.controls.name.hasError('required')">
              {{ 'errors.name-missing' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'labels.enter-email' | translate }}</mat-label>
            <input test-id="enter-email" matInput formControlName="email" required autocomplete="email" />
            <mat-error *ngIf="createUserForm.controls.email.hasError('required')">
              {{ 'errors.email-missing' | translate }}
            </mat-error>
            <mat-error *ngIf="createUserForm.controls.email.hasError('email')">
              {{ 'errors.email-adress-invalid' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'labels.enter-password' | translate }}</mat-label>
            <input
              test-id="enter-password"
              matInput
              [placeholder]="'labels.enter-password' | translate"
              formControlName="password"
              type="password"
              required
              autocomplete="new-password"
            />
            <mat-error *ngIf="createUserForm.controls.password.hasError('required')">
              {{ 'errors.password-missing' | translate }}
            </mat-error>
            <mat-error *ngIf="createUserForm.controls.password.hasError('minlength')">
              {{ 'errors.password-to-short' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'labels.enter-password-again' | translate }}</mat-label>
            <input
              test-id="enter-password-again"
              matInput
              formControlName="confirmPassword"
              type="password"
              [errorStateMatcher]="confirmPasswordMatcher"
            />
            <mat-error test-id="not-same-password" *ngIf="createUserForm.hasError('notTheSamePassword')">
              {{ 'errors.not-the-same-password' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-card-actions fxLayoutGap="10px" fxLayout="row">
        <mat-checkbox
          test-id="captcha-checkbox"
          color="primary"
          [disabled]="checkedAndConfirmedThatPlayerIsHuman"
          [value]="checkedAndConfirmedThatPlayerIsHuman"
          (change)="checkThatPlayerIsHuman()"
        >
          {{ 'checkboxes.captcha-user-check' | translate }}
        </mat-checkbox>
        <div fxFlex fxLayout="row" fxLayoutAlign="end">
          <button
            test-id="create-user"
            mat-raised-button
            class="create-user-button"
            type="submit"
            [disabled]="!createUserForm.valid || !checkedAndConfirmedThatPlayerIsHuman"
            color="primary"
          >
            {{ 'buttons.create-user' | translate }}
          </button>
        </div>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
