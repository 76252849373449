import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { lastValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorReport } from '../../../../../Common/model/error.report';
import { AppSettingsService } from '../appsettings/app-settings.service';
import { ConfigService } from '../config/config.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class ReportErrorService {
  private maxErrorCountToStore = 100;
  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
    private appSettingsService: AppSettingsService,
    private storage: StorageMap,
    private userService: UserService,
  ) {}
  async initialize(maxErrorCountToStore = 100) {
    this.maxErrorCountToStore = maxErrorCountToStore;
    const errorReports = await this.getStoredErrorReportsAndResest();
    await Promise.all(errorReports.map((errorReport) => this.tryToReportError(errorReport)));
  }
  async clearStorage() {
    await this.setStoredErrorReports([]);
  }
  async reportError(message: string, stack: string) {
    const errorReport: ErrorReport = {
      message: message,
      stack: stack,
      playerName: this.getPlayerName(),
    } as ErrorReport;
    if (this.userService.isLoggedIn()) {
      return await this.tryToReportError(errorReport);
    } else {
      this.storeError(errorReport);
      return errorReport;
    }
  }
  async getErrorReportsOnServer() {
    const routes = this.configService.getRoutes();

    return await lastValueFrom(this.httpClient.get<ErrorReport[]>(this.appSettingsService.appSettings.apiurl + routes.errorReport));
  }
  async clearReportsOnServer() {
    const routes = this.configService.getRoutes();

    return await lastValueFrom(this.httpClient.delete<void>(this.appSettingsService.appSettings.apiurl + routes.errorReport));
  }
  private async tryToReportError(errorReport: ErrorReport) {
    try {
      const routes = this.configService.getRoutes();

      const reportedError: ErrorReport = await lastValueFrom(
        this.httpClient.post<ErrorReport>(this.appSettingsService.appSettings.apiurl + routes.errorReport, errorReport),
      );
      return reportedError;
    } catch (error) {
      console.warn('Failed to submit error report, storing error for later send', error);
      await this.storeError(errorReport);
    }
  }
  private async getStoredErrorReportsAndResest() {
    const errorReports: ErrorReport[] = await this.getStoredErrorReports();
    await this.setStoredErrorReports([]);
    return errorReports;
  }
  public async storeError(reportedError: ErrorReport) {
    let errorReports: ErrorReport[] = await this.getStoredErrorReports();
    if (!errorReports) {
      errorReports = [];
    }
    if (errorReports.length < this.maxErrorCountToStore) {
      errorReports.push(reportedError);
      await this.setStoredErrorReports(errorReports);
    }
  }

  private async setStoredErrorReports(errorReports: ErrorReport[]) {
    return new Promise<void>((resolve, reject) => {
      this.storage.set('errorreports', errorReports).subscribe({
        next: () => {
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  private async getStoredErrorReports() {
    const errorReports: ErrorReport[] = (await lastValueFrom(
      this.storage.get('errorreports').pipe(catchError(() => of(null))),
    )) as ErrorReport[];
    return errorReports;
  }
  private getPlayerName() {
    if (this.userService.isLoggedIn()) {
      return this.userService.getLoggedIn().name;
    }
    return 'Not logged in';
  }
}
