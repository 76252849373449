import { CrosswordGameNotification, GameNotificationType } from '../data/crossword.game.notification';
import { IPlayer } from '../model/player';
import { PlayerSettings } from '../model/player.settings';
import { IdUtil } from './id.util';

export class NotificationUtil {
  static shouldShowPushNotification(notification: CrosswordGameNotification, player: IPlayer): boolean {
    switch (notification.type) {
      case GameNotificationType.CurrentPlayerChanged:
      case GameNotificationType.RemindPlayer:
        return NotificationUtil.isNotificationPlayer(notification, player);
      case GameNotificationType.PassedTurn:
      case GameNotificationType.CharsAdded:
      case GameNotificationType.PlayerJoined:
      case GameNotificationType.PlayerLeft:
      case GameNotificationType.AddedChatMessage:
        return !NotificationUtil.isNotificationPlayer(notification, player);
      default:
        return true;
    }
  }
  static shouldShowInGameNotification(notification: CrosswordGameNotification, player: IPlayer): boolean {
    switch (notification.type) {
      case GameNotificationType.PlayerJoined:
      case GameNotificationType.PlayerLeft:
      case GameNotificationType.AddedChatMessage:
        return !NotificationUtil.isNotificationPlayer(notification, player);
      case GameNotificationType.RemindPlayer:
        return NotificationUtil.isNotificationPlayer(notification, player);
      default:
        return true;
    }
  }
  static isNotificationImportant(notification: CrosswordGameNotification, player: IPlayer): boolean {
    switch (notification.type) {
      case GameNotificationType.CurrentPlayerChanged:
      case GameNotificationType.RemindPlayer:
        return NotificationUtil.isNotificationPlayer(notification, player);
      case GameNotificationType.GameCompleted:
      case GameNotificationType.GameStarted:
        return true;
      default:
        return false;
    }
  }

  static shouldShowInGameNotificationAsAlert(notification: CrosswordGameNotification) {
    switch (notification.type) {
      case GameNotificationType.GameCompleted:
        return true;
      default:
        return false;
    }
  }
  static getNewNotifications(notifications: CrosswordGameNotification[], newNotifications: CrosswordGameNotification[]) {
    return newNotifications.filter((notication) => notifications.find((notificationTmp) => notificationTmp.id == notication.id) == null);
  }

  static shouldSendNotification(playerSettings: PlayerSettings, notification: CrosswordGameNotification, player: IPlayer) {
    if (!NotificationUtil.shouldShowPushNotification(notification, player)) {
      return false;
    }
    if (playerSettings.onlyImportentNotifications && !NotificationUtil.isNotificationImportant(notification, player)) {
      return false;
    }
    return true;
  }
  static cantSendMessageToSubscription(error: any) {
    return error.statusCode == null || error.statusCode === 404 || error.statusCode === 410;
  }
  static isNotificationPlayer(notification: CrosswordGameNotification, player: IPlayer) {
    return IdUtil.idEquals(notification.playerId, player._id);
  }
}
