import { Interval } from '../../../../../Common/data/interval';
import { Point } from '../../../../../Common/data/point';
import { Direction, IPosition } from '../../../../../Common/data/position';
import { PriorityValue } from '../../../../../Common/data/priority.value';
import { ICrossword, ICrosswordCWQuestion, ICrosswordSize } from '../../../../../Common/model/crossword';
import { GamePlayerColor, ICrosswordGame } from '../../../../../Common/model/crossword.game';
import { ICWQuestionBase } from '../../../../../Common/model/cwquestion';
import { AssertUtil } from '../../../../../Common/util/assert.util';
import { CrosswordGamePlayerUtil } from '../../../../../Common/util/crossword.game.player.util';
import { CrosswordUtil } from '../../../../../Common/util/crossword.util';
import {
  BorderStyle,
  CharSetOnTile,
  CrosswordTile,
  QuestionWithCrosswordTiles,
  SetCharTileData,
  TileShowCorrect,
  TilesState,
  TileType,
} from '../../models/tile';

export class TileUtil {
  static setTileTurnFromTile(matrixTile: CrosswordTile, rowTile: CrosswordTile) {
    rowTile.typeData = { ...matrixTile.typeData };
  }
  static setTilesAsSetCharAndFlagCorrect(
    crosswordGame: ICrosswordGame,
    playerId: unknown,
    correctTiles: CrosswordTile[],
    tileShowCorrect: TileShowCorrect
  ) {
    const playerInfo = CrosswordGamePlayerUtil.getPlayerInfo(crosswordGame, playerId);
    for (const tile of correctTiles) {
      tile.type = TileType.SetChar;
      const charTileData: SetCharTileData = { thisTurn: true, showCorrect: tileShowCorrect, playerMatch: playerInfo.gamePlayerColor };
      tile.typeData = charTileData;
    }
  }
  static setTilesAsSetChar(
    tiles: CrosswordTile[], chars:string[][]
  ) {
    for (const tile of tiles) {
      tile.type = TileType.SetChar;
      const charTileData: SetCharTileData = { thisTurn: true, showCorrect: TileShowCorrect.Correct, playerMatch: GamePlayerColor.PlayerColor1 };
      tile.typeData = charTileData;
      tile.text = chars[tile.x][tile.y];
    }
  }
  static findTilesFromPoints(matrixTiles: CrosswordTile[][], correctPoints: Point[]) {
    return correctPoints.map((p) => matrixTiles[p.x][p.y]);
  }
  public static getCrosswordTilesCross(tiles: CrosswordTile[][]) {
    const tilesTmp: CrosswordTile[][] = [];
    for (let x = 0; x < tiles.length; x++) {
      for (let y = 0; y < tiles[x].length; y++) {
        if (tilesTmp[y] == null) {
          tilesTmp[y] = [];
        }
        tilesTmp[y][x] = tiles[x][y];
      }
    }
    return tilesTmp;
  }

  public static createCrosswordTiles(size: ICrosswordSize) {
    const tiles: CrosswordTile[][] = [];
    for (let x = 0; x < size.mainAxis; x++) {
      tiles[x] = [];
      for (let y = 0; y < size.crossAxis; y++) {
        tiles[x].push({ x, y, text: '', type: TileType.Empty, cwQuestions: [] });
      }
    }
    return tiles;
  }
  public static clearMatrixInputCrosswordTiles(tiles: CrosswordTile[][]) {
    for (let x = 0; x < tiles.length; x++) {
      for (let y = 0; y < tiles[x].length; y++) {
        const tile = tiles[x][y];
        if (tile.type == TileType.Input) {
          tile.text = '';
        }
      }
    }
  }
  public static clearRowInputCrosswordTiles(questionTiles: QuestionWithCrosswordTiles[]) {
    for (let i = 0; i < questionTiles.length; i++) {
      const questionTile = questionTiles[i];
      for (let x = 0; x < questionTile.tiles.length; x++) {
        const tile = questionTile.tiles[x];
        if (tile.type == TileType.Input) {
          tile.text = '';
        }
      }
    }
  }
  public static createRowOfCrosswordTiles(size) {
    const tiles: CrosswordTile[] = [];
    for (let x = 0; x < size; x++) {
      tiles.push({ x, y: 0, text: '', type: TileType.Input, cwQuestions: [] });
    }
    return tiles;
  }
  public static setRowOfCrosswordTiles(tiles: CrosswordTile[], answer: string) {
    AssertUtil.assert(() => answer.length <= tiles.length);
    for (let x = 0; x < tiles.length; x++) {
      tiles[x].text = x < answer.length ? answer.charAt(x) : '';
    }
    return tiles;
  }
  public static setTilesFromCrossword(tiles: CrosswordTile[][], crossword: ICrossword) {
    TileUtil.setQuestionAndInputTilesFromCWQuestions(tiles, crossword.crosswordQuestions);
  }
  public static setQuestionAndInputTilesFromCWQuestions(tiles: CrosswordTile[][], crosswordQuestions: ICrosswordCWQuestion[]) {
    for (const crosswordQuestion of crosswordQuestions) {
      const position = crosswordQuestion.position;
      const question = crosswordQuestion.cwQuestion;
      TileUtil.setCWQuestionTile(tiles[position.x][position.y], question, crosswordQuestion.priority, position.direction);
      for (let i = 0; i < question.answer.length; i++) {
        TileUtil.setCWInputQuestionTile(position, i, tiles, question);
      }
    }
  }

  public static setSetCharOnTiles(tiles: CrosswordTile[][], setChars: CharSetOnTile[]) {
    for (const setChar of setChars) {
      const tile = tiles[setChar.x][setChar.y];
      tile.type = TileType.SetChar;
      tile.text = setChar.char;
    }
  }

  public static setAllInputTilesAsReadOnlyChars(tiles: CrosswordTile[][]) {
    for (const tileRow of tiles) {
      for (const tile of tileRow) {
        if (tile.type === TileType.Input) {
          tile.type = TileType.SetChar;
        }
      }
    }
  }
  public static setTileTurnOnTiles(tiles: CrosswordTile[][], crosswordGame: ICrosswordGame, playerId: string) {
    const playerIsCurrentPlayer = CrosswordGamePlayerUtil.isCurrentPlayerId(crosswordGame, playerId);
    for (const playerTurn of crosswordGame.turns.playerTurns) {
      const playerTurnIsCurrentPlayer = CrosswordGamePlayerUtil.isCurrentPlayerId(crosswordGame, playerTurn.playerId);
      const notShowAsThisTurn = playerTurnIsCurrentPlayer && playerIsCurrentPlayer;
      const thisTurn = crosswordGame.turns.turn == playerTurn.turn && !notShowAsThisTurn;
      const playerInfo = CrosswordGamePlayerUtil.getPlayerInfo(crosswordGame, playerTurn.playerId);
      for (const tileSet of playerTurn.tilesSet) {
        const tile = tiles[tileSet.x][tileSet.y];
        const charTileData: SetCharTileData = {
          thisTurn: thisTurn,
          playerMatch: playerInfo.gamePlayerColor,
          showCorrect: TileShowCorrect.Correct,
        };
        tile.typeData = charTileData;
      }
    }
  }
  public static setTypeOnTiles(tiles: CrosswordTile[][], positions: Point[], tileType: TileType) {
    for (const position of positions) {
      const tile = tiles[position.x][position.y];
      tile.type = tileType;
    }
  }
  private static setCWInputQuestionTile(position: IPosition, i: number, tiles: CrosswordTile[][], question: ICWQuestionBase) {
    const pos = CrosswordUtil.getIncreasePosition(position, i);
    const tile = tiles[pos.x][pos.y];
    tile.type = TileType.Input;
    tile.cwQuestions.push(question);
  }

  private static setCWQuestionTile(tile: CrosswordTile, question: ICWQuestionBase, priority: PriorityValue, direction: Direction) {
    tile.type = TileType.Question;
    tile.tileCWQuestion = { direction: direction, priority: priority };
    tile.text = question.question;
    tile.cwQuestions.push(question);
  }

  public static setSetCharTilesFromQuestions(tiles: CrosswordTile[][], crosswordQuestions: ICrosswordCWQuestion[], showAnswer: boolean) {
    for (const crosswordQuestion of crosswordQuestions) {
      const position = crosswordQuestion.position;
      const question = crosswordQuestion.cwQuestion;
      for (let i = 0; i < question.answer.length; i++) {
        const pos = CrosswordUtil.getIncreasePosition(position, i);
        const tile = tiles[pos.x][pos.y];

        tile.type = TileType.SetChar;
        tile.text = showAnswer ? question.answer.slice(i, i + 1) : '';
      }
    }
  }
  public static rotateTiles(tiles: CrosswordTile[][]) {
    const tilesTmp: CrosswordTile[] = [];
    const size = tiles.length;
    for (let x = 0; x < size; x++) {
      for (let y = 0; y < size; y++) {
        tilesTmp.push(tiles[x][y]);
      }
    }
    return tilesTmp;
  }
  public static isHighligted(x: number, y: number, interval: Interval) {
    return this.insideInterval(interval, x, y);
  }
  public static getMatrixHightlightBorderStyleMain(x: number, y: number, interval: Interval): BorderStyle {
    const borderStyle = new BorderStyle(0, 0, 0, 0);
    if (this.insideInterval(interval, x, y)) {
      if (!this.insideInterval(interval, x, y + 1)) {
        borderStyle.bottom = 1;
      }
      if (!this.insideInterval(interval, x + 1, y)) {
        borderStyle.right = 1;
      }
      if (!this.insideInterval(interval, x - 1, y)) {
        borderStyle.left = 1;
      }
      if (!this.insideInterval(interval, x, y - 1)) {
        borderStyle.top = 1;
      }
    }
    return borderStyle;
  }
  public static getMatrixHightlightBorderStyleCross(x: number, y: number, interval: Interval): BorderStyle {
    const borderStyle = new BorderStyle(0, 0, 0, 0);
    if (this.insideInterval(interval, x, y)) {
      if (!this.insideInterval(interval, x + 1, y)) {
        borderStyle.bottom = 1;
      }
      if (!this.insideInterval(interval, x, y + 1)) {
        borderStyle.right = 1;
      }
      if (!this.insideInterval(interval, x, y - 1)) {
        borderStyle.left = 1;
      }
      if (!this.insideInterval(interval, x - 1, y)) {
        borderStyle.top = 1;
      }
    }
    return borderStyle;
  }
  public static getRowHightlightBorderStyle(x: number, y: number, interval: Interval): BorderStyle {
    const borderStyle = new BorderStyle(0, 0, 0, 0);
    if (this.insideInterval(interval, x, y)) {
      borderStyle.bottom = 1;
      borderStyle.top = 1;
      if (!this.insideInterval(interval, x + 1, y)) {
        borderStyle.right = 1;
      }
      if (x === 0) {
        borderStyle.left = 1;
      }
    }
    return borderStyle;
  }
  public static getMatrixGreyStyleMain(x: number, y: number): BorderStyle {
    return new BorderStyle(x === 0 ? 1 : 0, 1, y === 0 ? 1 : 0, 1);
  }
  public static getMatrixGreyStyleCross(x: number, y: number): BorderStyle {
    return new BorderStyle(y === 0 ? 1 : 0, 1, x === 0 ? 1 : 0, 1);
  }
  public static getRowGreyStyle(x: number): BorderStyle {
    return new BorderStyle(x === 0 ? 1 : 0, 1, 1, 1);
  }
  private static insideInterval(interval: Interval, x: number, y: number) {
    return interval != null && interval.insideInterval(x, y);
  }
  public static getTileFromPoint(point: Point, tiles: CrosswordTile[][]) {
    const row = tiles[point.x];
    return row != null ? row[point.y] : null;
  }
  public static isTileStateEqual(tilesState1: TilesState, tilesState2: TilesState) {
    if (tilesState1.canAddChars !== tilesState2.canAddChars) {
      return false;
    }
    if (tilesState1.focused.tile !== tilesState2.focused.tile) {
      return false;
    }
    if (tilesState1.focused.interval != null && tilesState2.focused.interval == null) {
      return false;
    }
    if (tilesState1.focused.interval == null && tilesState2.focused.interval != null) {
      return false;
    }
    if (tilesState1.focused.interval == null && tilesState2.focused.interval == null) {
      return true;
    }
    if (!tilesState1.focused.interval.equalInterval(tilesState2.focused.interval)) {
      return false;
    }
    return true;
  }
}
