import { AfterContentInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ObjectUtil } from '../../../../../../Common/util/object.util';
import { FlexibleButtonInMenu, FlexibleButtonType } from './model/flexible.button.in.menu';
import { SizeChanged } from '../../util/resize.directive';

function overWriteChangedValues(menu1: FlexibleButtonInMenu, menu2: FlexibleButtonInMenu) {
  for (const key in menu1) {
    if (menu1[key] != menu2[key]) {
      menu1[key] = menu2[key];
    }
  }
}

@Component({
  selector: 'app-flexable-button-menu',
  templateUrl: './flexable-button-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexableButtonMenuComponent implements AfterContentInit, OnChanges {
  FlexibleButtonType = FlexibleButtonType;
  @Input()
  buttons: FlexibleButtonInMenu[];
  @Input()
  keepOrder?: boolean;

  buttonsContained: FlexibleButtonInMenu[] = [];
  menuButtons: FlexibleButtonInMenu[];
  standardButtons: FlexibleButtonInMenu[];
  width = 0;

  ngAfterContentInit(): void {
    this.refreshContainedButtons();
    this.positionContainedButtons();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.buttons) {
      this.refreshContainedButtons();
      this.positionContainedButtons();
    }
  }

  onSizeChanged(size: SizeChanged) {
    if (this.width != size.width) {
      this.width = size.width;
      this.positionContainedButtons();
    }
  }
  canShowButton(button: FlexibleButtonInMenu, type: FlexibleButtonType) {
    return button.visible != false && button.type == type;
  }
  private refreshContainedButtons() {
    if (this.buttons.length != this.buttonsContained.length) {
      this.buttonsContained = [];
      this.buttonsContained.push(...this.buttons);
      return;
    }
    for (let i = 0; i < this.buttons.length; i++) {
      if (!ObjectUtil.isObjectEqual(this.buttons[i], this.buttonsContained[i])) {
        overWriteChangedValues(this.buttonsContained[i], this.buttons[i]);
      }
    }
  }
  private positionContainedButtons() {
    if (!this.buttonsContained || !this.width) {
      return;
    }
    const buttonsContainedToFit = this.buttonsContained.filter((button) => !button.menuOnly);
    const buttonsToSetDirectlyInMenu = this.buttonsContained.filter((button) => button.menuOnly);

    const buttonsFitted = this.getFitButton(buttonsContainedToFit);
    buttonsFitted.menu.push(...buttonsToSetDirectlyInMenu);
    if (!ObjectUtil.isObjectEqual(buttonsFitted.standard, this.standardButtons)) {
      this.standardButtons = buttonsFitted.standard;
    }
    if (!ObjectUtil.isObjectEqual(buttonsFitted.menu, this.menuButtons)) {
      this.menuButtons = buttonsFitted.menu;
    }
  }
  private getFitButton(buttonsContainedToFit: FlexibleButtonInMenu[]) {
    const buttonsFitted: { standard: FlexibleButtonInMenu[]; menu: FlexibleButtonInMenu[] } = { standard: [], menu: [] };
    let widthRemaining = this.width - 20;

    for (const button of buttonsContainedToFit) {
      if (button.visible == false) {
        continue;
      }
      let widthNeeded = 25;
      if (button.icon) {
        widthNeeded += 25;
      }
      if (button.text) {
        widthNeeded += button.text.length * 10;
      }
      if (widthNeeded < widthRemaining) {
        widthRemaining -= widthNeeded;
        buttonsFitted.standard.push(button);
      } else {
        buttonsFitted.menu.push(button);
        if (this.keepOrder) {
          widthRemaining = 0;
        }
      }
    }
    return buttonsFitted;
  }
}
