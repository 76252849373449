export enum GameNotificationType {
  AddedChatMessage,
  CurrentPlayerChanged,
  CharsAdded,
  GameStarted,
  GameCompleted,
  PlayerJoined,
  PlayerLeft,
  PassedTurn,
  RemindPlayer,
  PlayerTimedOut,
}
export enum PlayerToRescive {
  All,
  Me,
  AllButMe,
  Creator,
  None,
}
export interface GameNotificationAction {
  actionText: string;
  url: string;
}
export interface CrosswordGameNotification {
  id: string;
  gameId: string;
  playerId: string;
  playerName: string;
  type: GameNotificationType;
  messageData: any;
}
