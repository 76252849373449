<div mat-dialog-title style="text-align: center">
  {{ 'titles.search-images' | translate }}
</div>
<mat-dialog-content [formGroup]="searchImages">
  <div fxLayout="column" style="margin-bottom: 10px; margin-top: 5px" fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
      <mat-form-field fxFlex>
        <mat-label>{{ 'labels.search-images' | translate }}</mat-label>
        <input test-id="image-search-input" matInput formControlName="search" (keyup.enter)="onSearchImages()" />
      </mat-form-field>
      <button test-id="image-search-button" mat-raised-button (click)="onSearchImages()">{{ 'buttons.search-images' | translate }}</button>
    </div>
    <mat-grid-list *ngIf="imageSearchResult.length > 0" listenForResize (sizeChanged)="onSizeChanged($event)" fxFlex [cols]="columnCount">
      <mat-grid-tile *ngFor="let imageResult of imageSearchResult">
        <div
          fxFlexFill
          fxLayout="column"
          [ngClass]="{
            'image-selected': searchImages.controls.selectedImage.value == imageResult,
            'image-not-selected': searchImages.controls.selectedImage.value != imageResult
          }"
        >
          <div fxFlex style="overflow: hidden">
            <img
              test-id="image-search-result"
              fxFlexFill
              style="object-fit: contain"
              [src]="imageResult.smallImageUrl"
              [alt]="'Image url:' + imageResult.smallImageUrl"
              (click)="onImageClicked(imageResult)"
            />
          </div>
          <div fxLayout="row">
            <span *ngIf="imageResult.tags" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap">{{
              imageResult.tags.join(',')
            }}</span>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <div *ngIf="searched && imageSearchResult.length == 0" style="height: 100px" fxLayout="column" fxLayoutAlign="center center">
      <span>{{ 'labels.search-images-no-images' | translate: { searchTerm: searchImages.controls.search.value } }}</span>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button test-id="add-image-question" [disabled]="!searchImages.valid" mat-raised-button (click)="onSelectImage()">
    {{ 'buttons.ok' | translate }}
  </button>
  <button *ngIf="data.currentImageUrl?.length > 0" test-id="remove-image-question" mat-raised-button (click)="onRemoveImage()">
    {{ 'buttons.delete' | translate }}
  </button>
  <button mat-raised-button (click)="onCancel()">{{ 'buttons.cancel' | translate }}</button>
</mat-dialog-actions>
