import { ChangeDetectionStrategy, ChangeDetectorRef, Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import { ICrossword } from '../../../../../Common/model/crossword';
import { ICrosswordGame } from '../../../../../Common/model/crossword.game';
import { CrosswordGameStats } from '../../../../../Common/model/crossword.game.stats';
import { CrosswordGameStatsUtil } from '../../../../../Common/util/crossword.game.stats.util';
import { CrosswordUtil } from '../../../../../Common/util/crossword.util';
import { ComponentCommunicationService } from '../../services/componentcommunication/component-communication.service';
import { CrosswordService } from '../../services/crossword/crossword.service';
import { CrosswordGameService } from '../../services/crosswordgame/crossword-game.service';
import { CrosswordGameStatsService } from '../../services/crosswordgamestats/crossword.game.stats.service';
import { ShareService } from '../../services/share/share.service';
import { UserService } from '../../services/user/user.service';
import { FlexibleButtonInMenu, FlexibleButtonType } from '../common/flexable-button-menu/model/flexible.button.in.menu';
import { MessageBoxType } from '../dialog/message-box-content/message-box-content.component';
import { DialogService } from '../dialog/service/dialog.service';
import { CrosswordLandingHandleCrosswordView } from '../util/handleCrosswordView/crossword.landing.handle.crossword.tiles';
import { ShowHandleCrosswordView } from '../util/handleCrosswordView/show.handle.crossword.view';

enum ShareType {
  ByEmail,
  ByDeviceShare,
}
@Component({
  selector: 'app-crossword-landing',
  templateUrl: './crossword-landing.component.html',
  styleUrls: ['./crossword-landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrosswordLandingComponent implements OnInit, OnDestroy {
  ShareType = ShareType;
  readonly handleCrosswordView: ShowHandleCrosswordView;
  crossword: ICrossword;
  crosswordActionButtons: FlexibleButtonInMenu[];
  private crosswordGameStats: CrosswordGameStats[];
  private shareType: ShareType;
  private alive = true;
  constructor(
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private crosswordService: CrosswordService,
    private crosswordGameService: CrosswordGameService,
    private componentCommunication: ComponentCommunicationService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private shareService: ShareService,
    private crosswordGameStatsService: CrosswordGameStatsService,
  ) {
    this.handleCrosswordView = new CrosswordLandingHandleCrosswordView();
  }

  async ngOnInit() {
    this.shareType = (await this.shareService.supportsDeviceShare()) ? ShareType.ByDeviceShare : ShareType.ByEmail;

    this.activatedRoute.params.pipe(takeWhile(() => this.alive)).subscribe(async (paramMap) => {
      const id = paramMap.id;
      await this.initWithId(id);
    });
  }
  ngOnDestroy(): void {
    this.alive = false;
  }

  async initWithId(id: string) {
    if (id != null && id !== '-1') {
      const crosswordTmp = await this.crosswordService.getCrossword(id);
      if (!crosswordTmp) {
        await this.dialogService.showMessageBox('', this.translateService.instant('messages.missing-crossword'), MessageBoxType.Ok);
        this.router.navigateByUrl('/listCrosswords');
        return;
      }

      this.crossword = crosswordTmp;
      this.crosswordGameStats = await this.crosswordGameStatsService.getCrosswordStats(id);
      this.updateTitle();
      this.refreshHandleCrosswordTile();
      this.refreshCrosswordActionButtons();
      this.cdr.detectChanges();
    } else {
      await this.dialogService.showMessageBox('', this.translateService.instant('messages.missing-crossword'), MessageBoxType.Ok);
      this.router.navigateByUrl('/listCrosswords');
    }
  }

  private refreshCrosswordActionButtons() {
    this.crosswordActionButtons = [];
    const hasPlayedCrossword = this.hasPlayedCrosswordBeforeOrInDevMode();
    this.crosswordActionButtons.push({
      id: 'start-crossword-game',
      text: this.translateService.instant('buttons.game-type-multiplayer'),
      icon: 'start',
      type: FlexibleButtonType.Standard,
      disabled: hasPlayedCrossword,
      standardAction: {
        event: () => this.onOpenNewMatch(),
      },
    });

    this.crosswordActionButtons.push({
      id: 'share-crossword',
      text: this.translateService.instant('buttons.share-crossword'),
      icon: 'share',
      visible: this.shareType === ShareType.ByDeviceShare,
      type: FlexibleButtonType.Standard,
      standardAction: {
        event: () => this.shareService.share(this.getBodyText(), this.getShareUrl()),
      },
    });

    if (isDevMode()) {
      this.crosswordActionButtons.push({
        id: 'print-crossword',
        text: this.translateService.instant('buttons.print-crossword'),
        icon: 'print',
        menuOnly: true,
        type: FlexibleButtonType.Standard,
        standardAction: { event: () => this.onPrintCrossword() },
      });
    }
  }
  private hasPlayedCrosswordBeforeOrInDevMode() {
    if (this.componentCommunication.devMode.getValue()) {
      return false;
    }
    return CrosswordGameStatsUtil.hasPlayerPlayedCrossword(this.crosswordGameStats, this.userService.getLoggedIn()._id);
  }

  private onPrintCrossword(): void {
    console.log(JSON.stringify(this.crossword));
    const enterAnswers: string[] = [];
    const sortedAfterCenter = CrosswordUtil.getQuestionsSortedAfterDistanceToCenter(this.crossword.crosswordQuestions);
    for (const question of sortedAfterCenter) {
      const answer = question.cwQuestion.answer;
      const nameText = answer.slice(0, 1).toUpperCase() + answer.slice(1);
      enterAnswers.push(`public static ${nameText} = new EnterAnswer('${answer}');`);
    }
    console.log(enterAnswers.join('\n'));
  }
  private getShareUrl() {
    return `${document.location.href}?share=true`;
  }
  private async onOpenNewMatch() {
    await this.openCrosswordGame();
  }
  private refreshHandleCrosswordTile() {
    this.handleCrosswordView.refresh(this.crossword);
  }
  private updateTitle() {
    this.componentCommunication.currentRouteTitle.next(this.crossword.name ? this.crossword.name : '');
  }
  private async openCrosswordGame() {
    this.dialogService.wrapInProgress(async () => {
      const crosswordGame = (await this.crosswordGameService.createCrosswordGame(this.crossword)) as ICrosswordGame;

      this.router.navigate(['/playCrossword', crosswordGame._id]);
    }, this.translateService.instant('messages.starting-crosword-puzzle'));
  }
  private getBodyText() {
    return this.translateService.instant('misc.share-crossword-body-text', {
      player: this.userService.getLoggedIn().name,
      crosswordName: this.crossword.name,
    });
  }
}
