import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, Observable, Subject, takeWhile, timer } from 'rxjs';
import { BrowserUtil } from '../../../util/browser.util';

@Component({
  selector: 'app-sticky-to-top',
  templateUrl: './sticky-to-top.component.html',
  styleUrls: ['./sticky-to-top.component.scss'],
})
export class StickyToTopComponent {}
