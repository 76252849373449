export class DocumentUtil {
  static clearSelection() {
    const documentAny: any = document;
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (documentAny.selection) {
      documentAny.selection.empty();
    }
  }
  static waitForLoad<T>(action: () => T): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const interval = setInterval(() => {
        const result = action();
        if (result) {
          clearInterval(interval);
          resolve(result);
        }
      }, 50);
    });
  }
}
