import { Point } from '../data/point';
import { ICrosswordGame } from '../model/crossword.game';
import { CrosswordGameStats } from '../model/crossword.game.stats';
import { IdUtil } from './id.util';

export class CrosswordGameStatsUtil {
  static getAllTilesSet(crosswordGame: ICrosswordGame) {
    const allTilesSet: Point[] = [];
    for (const playerTurn of crosswordGame.turns.playerTurns) {
      allTilesSet.push(...playerTurn.tilesSet);
    }
    return allTilesSet;
  }
  static hasPlayerPlayedCrossword(crosswordGameStats: CrosswordGameStats[], playerId: any) {
    for (const crosswordGameStat of crosswordGameStats) {
      if (crosswordGameStat.playerStatistics.find((p) => IdUtil.idEquals(p.playerId, playerId)) != null) {
        return true;
      }
    }
    return false;
  }
}
