<h4 mat-dialog-title style="text-align: center">
  {{ 'titles.captcha-check-user' | translate }}
</h4>
<mat-dialog-content fxFlexFill style="max-width: 600px">
  <ng-container *ngIf="!toManyFailedAttempts">
    <div fxFlexFill *ngIf="crossword" fxLayoutAlign="center">
      <app-show-crossword fxFlex [handleCrosswordView]="handleCrosswordView"> </app-show-crossword>
    </div>
    <app-progress-bar *ngIf="!crossword"></app-progress-bar>
  </ng-container>
  <div *ngIf="toManyFailedAttempts" fxFlexFill fxLayoutAlign="center center">
    <h3>{{ 'misc.to-many-failed-attempts' | translate }}</h3>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
  <ng-container *ngIf="crossword && !toManyFailedAttempts">
    <button test-id="refresh" mat-raised-button (click)="onRefreshCrossword()">
      <mat-icon>refresh</mat-icon>{{ 'buttons.refresh-captcha-crossword' | translate }}
    </button>
    <button test-id="ok" mat-raised-button [disabled]="!handleCrosswordView.isAllSet()" (click)="onCompletedCrosswordClicked()">
      <mat-icon>check</mat-icon
      >{{
        'buttons.check-captcha-crossword-correct'
          | translate: { charsSet: handleCrosswordView.getNumberOfCharsSet(), charsToSet: handleCrosswordView.getNumberOfCharsToSet() }
      }}
    </button>
  </ng-container>
  <button *ngIf="toManyFailedAttempts" mat-raised-button (click)="matDialogRef.close(false)">
    {{ 'buttons.close-dialog' | translate }}
  </button>
</mat-dialog-actions>
