import { PriorityValue } from '../../data/priority.value';
import { QuestionBasePriority } from '../../model/cwquestion';
import { ArrayUtil } from '../../util/array.util';
import { ReMatrix, ReQuestion } from './re.matrix';

export class ReQuestionsContainer {
  private readonly questionsContainer: Map<PriorityValue, ReQuestion[]>;
  constructor(readonly questionsBase: QuestionBasePriority[]) {
    this.questionsContainer = new Map<PriorityValue, ReQuestion[]>([
      [PriorityValue.Low, []],
      [PriorityValue.Medium, []],
      [PriorityValue.High, []],
      [PriorityValue.Theme, []],
    ]);

    for (const q of questionsBase) {
      this.questionsContainer.get(q.priority).push(new ReQuestion(q));
    }
  }
  public getQuestionsToFit(matrix: ReMatrix, priorityValue: PriorityValue, maxLength: number) {
    const ids = this.getIdsSet(matrix);
    return ArrayUtil.shuffleArray(this.getQuestionsOfPriority(priorityValue))
      .filter((question) => !ids.has(question.indexId))
      .slice(0, maxLength);
  }
  public getQuestionsOfPriority(priorityValue: PriorityValue) {
    return this.questionsContainer.get(priorityValue);
  }
  private getIdsSet(crosswordMatrix: ReMatrix) {
    return new Set(crosswordMatrix.getQuestionIds());
  }
  private getRandomQuestions() {
    return [
      ...this.questionsContainer.get(PriorityValue.Low),
      ...this.questionsContainer.get(PriorityValue.Medium),
      ...this.questionsContainer.get(PriorityValue.High),
    ];
  }
}
