import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
export enum MessageBoxType {
  Error,
  Ok,
  OkCancel,
  YesNo,
}
export interface MessageBoxData {
  title: string;
  message: string;
  type: MessageBoxType;
}
@Component({
  selector: 'app-message-box-content',
  templateUrl: './message-box-content.component.html',
})
export class MessageBoxContentComponent {
  MessageBoxType = MessageBoxType;
  constructor(@Inject(MAT_DIALOG_DATA) public data: MessageBoxData) {}
}
