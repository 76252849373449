import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { FilterCWQuestion } from '../../../../../../Common/util/cwquestion.util';
import { CrosswordBuildForm } from '../../build-crossword/build-crossword.component';
import { SelectCWQuestionType } from '../../build-crossword/select-cwquestions/select-cwquestion-type';

export interface AddQuestionDialogData {
  crosswordBuildForm: CrosswordBuildForm;
  sampleQuestionsChanged: Subject<void>;
  filterCharsAndLengths: FilterCWQuestion;
}

@Component({
  templateUrl: './add-question-dialog.component.html',
})
export class AddQuestionDialogComponent {
  selectedQuestionIndexId = 0;
  SelectCWQuestionType = SelectCWQuestionType;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddQuestionDialogData,
    private dialogRef: MatDialogRef<AddQuestionDialogComponent>,
  ) {}

  onSelectedQuestionsChanged(selectedQuestionsIds: number[]) {
    this.selectedQuestionIndexId = selectedQuestionsIds.length == 1 ? selectedQuestionsIds[0] : null;
  }
  onAddedNewQuestion(questionId: number) {
    this.dialogRef.close(questionId);
  }
}
