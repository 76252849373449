import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SearchQuestionsItem, SearchQuestionsQuery } from '../../../../../../Common/query/search.question.query';
import { CWQuestionService } from '../../../services/cwquestion/cwquestion.service';

export class CWQuestionDataSource implements DataSource<SearchQuestionsItem> {
  private questionsSubject = new BehaviorSubject<SearchQuestionsItem[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public allIds = new BehaviorSubject<number[]>([]);
  private delaySearch = new Subject<SearchQuestionsQuery>();
  constructor(private cwQuestionService: CWQuestionService) {
    this.delaySearch.pipe(debounceTime(500)).subscribe((searchQuestionsQuery) => this.loadQuestions(searchQuestionsQuery));
  }
  connect(): Observable<SearchQuestionsItem[] | readonly SearchQuestionsItem[]> {
    return this.questionsSubject.asObservable();
  }
  disconnect(): void {
    this.questionsSubject.complete();
    this.loadingSubject.complete();
    this.delaySearch.complete();
  }
  async loadQuestionsWithDelay(searchQuestionsQuery: SearchQuestionsQuery) {
    this.loadingSubject.next(true);
    this.delaySearch.next(searchQuestionsQuery);
  }
  async loadQuestions(searchQuestionsQuery: SearchQuestionsQuery) {
    this.loadingSubject.next(true);
    const result = await this.cwQuestionService.loadQuestions(searchQuestionsQuery);
    this.allIds.next(result.allIds);
    this.questionsSubject.next(result.queryResult);
    this.loadingSubject.next(false);
  }
}
