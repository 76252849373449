import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IDevDatabaseWithResult } from '../../../../../Common/test/dev.database';
import { AppSettingsService } from '../appsettings/app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class DevelopService {
  private clearDevelopDatabaseUrl = 'api/develop/clearDatabase';
  private setupSmallDatabaseUrl = 'api/develop/setupSmallDatabase';
  private setupMediumDatabaseUrl = 'api/develop/setupMediumDatabase';

  constructor(private http: HttpClient, private appSettingsService: AppSettingsService) {}
  async clearDatabase() {
    return await lastValueFrom(
      this.http.get<IDevDatabaseWithResult>(this.appSettingsService.appSettings.apiurl + this.clearDevelopDatabaseUrl)
    );
  }
  async setupSmallDatabase() {
    return await lastValueFrom(
      this.http.get<IDevDatabaseWithResult>(this.appSettingsService.appSettings.apiurl + this.setupSmallDatabaseUrl)
    );
  }
  async setupMediumDatabase() {
    try {
      return await lastValueFrom(
        this.http.get<IDevDatabaseWithResult>(this.appSettingsService.appSettings.apiurl + this.setupMediumDatabaseUrl)
      );
    } catch (error) {
      console.error('Failed to setup database', error);
    }
  }
}
