import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GameState } from '../../../../../../Common/data/game.state';
import { GameType } from '../../../../../../Common/data/game.type';
import { PlayerInfo } from '../../../../../../Common/data/player.score';
import { ICrosswordGame } from '../../../../../../Common/model/crossword.game';
import { IPlayer } from '../../../../../../Common/model/player';
import { CrosswordGamePlayBase } from '../../../../../../Common/util/crossword.game.play.base';
import { CrosswordGamePlayerUtil } from '../../../../../../Common/util/crossword.game.player.util';
import { CrosswordGameTurnUtil } from '../../../../../../Common/util/crossword.game.turn.util';
import { IdUtil } from '../../../../../../Common/util/id.util';
import { PlayerMatchColor } from '../../util/player.match.color';

export interface PlayerInfoData extends PlayerInfo {
  color: string;
  selected: boolean;
  playerName: string;
  order: string;
  percentageCorrect: number;
}

@Component({
  selector: 'app-crossword-game-information',
  templateUrl: './crossword-game-information.component.html',
})
export class CrosswordGameInformationComponent implements OnChanges, AfterViewInit, OnInit {
  matchDisplayColumn = ['currentPlayer', 'order', 'playerName', 'score', 'color'];
  singleDisplayColumn = ['playerName', 'score', 'color', 'percentageCorrect'];
  displayedColumns: string[];
  @Input()
  crosswordGame: ICrosswordGame;
  @Input()
  crossword;
  dataSource = new MatTableDataSource<PlayerInfoData>();
  private crosswordPlay = new CrosswordGamePlayBase();
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.displayedColumns = this.crosswordGame.global.type == GameType.Match ? this.matchDisplayColumn : this.singleDisplayColumn;
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.crosswordGame) {
      this.crosswordPlay.setCrosswordGame(this.crosswordGame);
      this.crosswordPlay.refreshTiles();

      const playerInfoData = this.crosswordGame.playersInfo.players.map((player) => this.getPlayerInfoData(player));
      this.dataSource.data = playerInfoData;
    }
  }
  isCurrentPlayer(playerInfo: PlayerInfoData) {
    return (
      this.crosswordGame.global.state == GameState.Started && IdUtil.idEquals(this.crosswordGame.turns.currentPlayerId, playerInfo.playerId)
    );
  }
  private getPlayerInfoData(player: IPlayer): PlayerInfoData {
    const playerInfo = CrosswordGamePlayerUtil.getPlayerInfo(this.crosswordGame, player._id);
    return {
      ...playerInfo,
      playerName: CrosswordGamePlayerUtil.getPlayer(this.crosswordGame, playerInfo.playerId).name,
      order: CrosswordGameTurnUtil.getPlayerOrderInGame(this.crosswordGame, playerInfo.playerId),
      color: PlayerMatchColor.getColor(playerInfo.gamePlayerColor, true),
      selected: false,
    };
  }
}
