import { AssertUtil } from '../../../../../Common/util/assert.util';

interface CompressSettings {
  maxCharCountPerLine: number;
  maxLinesCount: number;
  compressLineWith: string;
}
export class StringUtil {
  private static seperator = ' ';
  public static replaceNewLineWithBr(str: string) {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
  }
  public static compress(value: string, settings: CompressSettings) {
    AssertUtil.assert(() => settings.compressLineWith.length < settings.maxCharCountPerLine);
    const lines = [''];
    const words = value.split(this.seperator);
    for (const word of words) {
      const currentLine = lines[lines.length - 1];
      const addedWordToLine = StringUtil.getCurrentLineWithSeperator(currentLine) + word;
      if (addedWordToLine.length <= settings.maxCharCountPerLine) {
        lines[lines.length - 1] = addedWordToLine;
      } else if (word.length <= settings.maxCharCountPerLine) {
        if (lines.length < settings.maxLinesCount) {
          lines.push(word);
        } else {
          lines[lines.length - 1] = this.compressLine(addedWordToLine, settings);
          break;
        }
      } else {
        if (lines.length < settings.maxLinesCount) {
          lines.push(this.compressLine(word, settings));
          break;
        } else {
          lines[lines.length - 1] = this.compressLine(addedWordToLine, settings);
          break;
        }
      }
    }
    return lines.join(this.seperator);
  }
  private static getCurrentLineWithSeperator(currentLine: string) {
    return currentLine.length > 0 ? currentLine + this.seperator : '';
  }

  private static compressLine(value: string, settings: CompressSettings) {
    return value.substring(0, settings.maxCharCountPerLine - settings.compressLineWith.length) + settings.compressLineWith;
  }

  public static replaceAt(value: string, index: number, replacement: string) {
    const returnValue = value.slice(0, index) + replacement + value.slice(index + replacement.length);
    AssertUtil.assert(() => value.length == returnValue.length);
    return returnValue;
  }
  public static replaceAtInArray(values: string[], outherIndex: number, index: number, replacement: string) {
    for (let i = 0; i < replacement.length; i++) {
      values[outherIndex + i] = this.replaceAt(values[outherIndex + i], index, replacement[i]);
    }
  }
  public static convertStringToArrayOfStringsWithLength(value: string, length: number) {
    const valueAsArray: string[] = [];
    for (let i = 0; i < length; i++) {
      valueAsArray[i] = ' ';
    }
    for (let i = 0; i < value.length; i++) {
      valueAsArray[i] = value[i];
    }
    return valueAsArray;
  }
  static repeatChar(length: number, char: string): string {
    return Array(length).fill(char).join('');
  }
}
