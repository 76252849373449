import { Component, Input, OnInit } from '@angular/core';
import { PriorityValue } from '../../../../../../../Common/data/priority.value';
import { QuestionBasePriority } from '../../../../../../../Common/model/cwquestion';
import { ImageService } from '../../../../services/image/image.service';
import { DialogService } from '../../../dialog/service/dialog.service';

@Component({
  selector: 'app-show-row-question',
  templateUrl: './show-row-question.component.html',
  styleUrls: ['./show-row-question.component.scss'],
})
export class ShowRowQuestionComponent implements OnInit {
  PriorityValue = PriorityValue;
  @Input() cwQuestion: QuestionBasePriority;
  imageUrl: string;
  constructor(
    private imageService: ImageService,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    if (this.cwQuestion.imageId) {
      this.imageUrl = this.imageService.getImageUrl(this.cwQuestion.imageId);
    }
  }

  onOpenImage() {
    this.dialogService.showLargeImage(this.cwQuestion);
  }
}
