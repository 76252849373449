import { LanguageCode } from '../data/language';
import { GamePlayerColor } from './crossword.game';
import { IModel } from './model';
import { IWebSubscription } from './subscription';

export enum HelpInstruction {
  Game,
  ChooseGame,
  BuildCrossword,
  AddAppToHomescreen,
}
export enum SettingsQuestions {
  SubscribeToNotifications = 3,
  SendNotificationByEmail = 4,
}

export interface FirebaseToken {
  value: string;
}

export interface PlayerSettings extends IModel {
  playerId: any;
  administrator: boolean;
  helpInstructionsDismissed: HelpInstruction[];
  settingsQuestionsDismissed: SettingsQuestions[];
  sendNotificationsByEmailWhenNoPush: boolean;
  onlyImportentNotifications: boolean;
  webSubscription: IWebSubscription;
  fireBaseToken: FirebaseToken;
  preferredPlayerColor: GamePlayerColor;
  languageCode: LanguageCode;
}
