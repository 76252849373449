import { GenerateCrosswordData } from '../data/generate.crossword.data';
import { PriorityValue } from '../data/priority.value';
import { ICrossword } from '../model/crossword';
import { ReAddQuestions } from './modifiers/re.add.questions';
import { ReCrosswordBuildAction } from './modifiers/re.build.action';
import { ReRemoveQuestionsForBetterFit } from './modifiers/re.remove.questions.for.better.fit';
import { ReRemoveQuestionsWithToLittleCrossings } from './modifiers/re.remove.questions.with.to.little.crossings';
import { ReRepositionQuestionsToBetterPositions } from './modifiers/re.reposition.questions.to.better.positions';
import { ReAddQuestionsToPosition } from './util/re.add.questions.to.position';
import { ReMatchPositionAndQuestion } from './util/re.match.position.for.question';
import { ReMatrix } from './util/re.matrix';
import { ReQuestionsContainer } from './util/re.questions.container';
import { ReRandomPositionAndQuestion } from './util/re.random.position.for.question';
import { ReRemoveQuestions } from './util/re.remove.questions';
import { CrosswordMatrixScore } from './util/statistics.util';

export class ReBuildCrosswordMatrix {
  private readonly randomQBuildActions: ReCrosswordBuildAction[] = [];
  private readonly themeQBuildActions: ReCrosswordBuildAction[] = [];
  constructor(private settings: GenerateCrosswordData) {
    this.initRandomQModifiers();
    this.initThemeQModifiers();
  }
  static build(settings: GenerateCrosswordData) {
    return new ReBuildCrosswordMatrix(settings).buildCrossword();
  }
  public buildCrossword() {
    const themeQuestionIds = this.settings.questions.filter((q) => q.priority == PriorityValue.Theme).map((q) => q.indexId);
    const allMatrixes: ReMatrix[] = [];
    const containsAllThemeQuestions: ReMatrix[] = [];
    for (let i = 0; i < 10; i++) {
      const cmatrix = this.buildCrosswordMatrix();
      allMatrixes.push(cmatrix);
      const questionIds = new Set<number>([...cmatrix.getQuestionIds()]);
      if (themeQuestionIds.find((q) => !questionIds.has(q))) {
        continue;
      }
      containsAllThemeQuestions.push(cmatrix);
      if (containsAllThemeQuestions.length >= 3) {
        break;
      }
    }
    const cmatrixes = containsAllThemeQuestions.length > 0 ? containsAllThemeQuestions : allMatrixes;
    const sortedCmatrixes = cmatrixes.sort((c1, c2) => c1.getScore().compare(c2.getScore())).reverse();
    const crossword = sortedCmatrixes[0].getCrossword();
    this.logStatistics(sortedCmatrixes, crossword);
    return crossword;
  }

  private buildCrosswordMatrix() {
    let matrix = new ReMatrix(this.settings.size);
    matrix = this.buildWithActions(matrix, this.settings.accuracy.themeQResets, this.themeQBuildActions, true);
    matrix = this.buildWithActions(matrix, this.settings.accuracy.randomQResets, this.randomQBuildActions, false);
    return matrix;
  }
  private buildWithActions(bestMatrix: ReMatrix, iterations: number, actions: ReCrosswordBuildAction[], onlyCharScore: boolean) {
    let bestScore = new CrosswordMatrixScore(0, 0);
    for (let i = 0; i < iterations; i++) {
      let matrix = bestMatrix.copy();

      for (const action of actions) {
        matrix = action.run(matrix, i);
      }

      const score = onlyCharScore ? matrix.getCharScore() : matrix.getScore();
      if (!bestScore || score.greatherThen(bestScore)) {
        bestMatrix = matrix;
        bestScore = score;
      }
    }
    return bestMatrix;
  }

  private initThemeQModifiers() {
    const removeQuestions = new ReRemoveQuestions();
    const questionsContainer = new ReQuestionsContainer(this.settings.questions);
    const randomPositionAndQuestion = new ReRandomPositionAndQuestion();
    const addQuestionsToPosition = new ReAddQuestionsToPosition(this.settings, randomPositionAndQuestion, questionsContainer);

    this.themeQBuildActions.push(new ReRemoveQuestionsForBetterFit(this.settings.accuracy, removeQuestions, true));
    this.themeQBuildActions.push(new ReAddQuestions(addQuestionsToPosition, PriorityValue.Theme));
  }
  private initRandomQModifiers() {
    const removeQuestions = new ReRemoveQuestions();
    const questionsContainer = new ReQuestionsContainer(this.settings.questions);
    const matchPositionAndQuestion = new ReMatchPositionAndQuestion();
    const addQuestionsToPosition = new ReAddQuestionsToPosition(this.settings, matchPositionAndQuestion, questionsContainer);

    this.randomQBuildActions.push(new ReRemoveQuestionsWithToLittleCrossings(this.settings.accuracy));
    this.randomQBuildActions.push(new ReRemoveQuestionsForBetterFit(this.settings.accuracy, removeQuestions, false));
    this.randomQBuildActions.push(new ReAddQuestions(addQuestionsToPosition, PriorityValue.High));
    this.randomQBuildActions.push(new ReAddQuestions(addQuestionsToPosition, PriorityValue.Medium));
    this.randomQBuildActions.push(new ReAddQuestions(addQuestionsToPosition, PriorityValue.Low));

    this.randomQBuildActions.push(new ReRepositionQuestionsToBetterPositions(this.settings, matchPositionAndQuestion, removeQuestions));
  }

  private logStatistics(sortedCmatrixes: ReMatrix[], crossword: ICrossword) {
    const questionsContainer = new ReQuestionsContainer(this.settings.questions);
    console.log(
      'Built crosswords with score:',
      sortedCmatrixes.map((c) => c.getScore().withPriority)
    );
    let text = 'Questions with priority ';
    text += this.getTextOfPriority('theme', questionsContainer, crossword, PriorityValue.Theme);
    text += this.getTextOfPriority('high', questionsContainer, crossword, PriorityValue.High);
    text += this.getTextOfPriority('medium', questionsContainer, crossword, PriorityValue.Medium);
    text += this.getTextOfPriority('low', questionsContainer, crossword, PriorityValue.Low);

    console.log(text);
  }
  private getTextOfPriority(text: string, questionsContainer: ReQuestionsContainer, crossword: ICrossword, priorityValue: PriorityValue) {
    const crosswordQuestionOfPriority = crossword.crosswordQuestions.filter((c) => c.priority == priorityValue);
    const allQuestionsOfPriority = questionsContainer.getQuestionsOfPriority(priorityValue);
    return `${text}:${crosswordQuestionOfPriority.length}(${allQuestionsOfPriority.length}), `;
  }
}
