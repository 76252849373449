import { Injectable } from '@angular/core';
import { PriorityGenerateCrosswordData } from '../../../../../Common/data/generate.crossword.data';
import { ICrossword } from '../../../../../Common/model/crossword';

@Injectable({
  providedIn: 'root',
})
export class GenerateCrosswordWorkerService {
  private readonly worker: Worker;
  constructor() {
    this.worker = new Worker(new URL('./generate.crossword.worker.ts', import.meta.url));
  }
  generateCrossword(generateData: PriorityGenerateCrosswordData) {
    return new Promise<ICrossword>((resolve, reject) => {
      const generateDataMessage: PriorityGenerateCrosswordData = { ...generateData };
      this.worker.postMessage(generateDataMessage);
      this.worker.onmessage = ({ data }) => {
        if (data.crossword != null) {
          resolve(data.crossword as ICrossword);
        } else {
          reject(data);
        }
      };
    });
  }
}
