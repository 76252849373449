import { Position } from '../../data/position';
import { PriorityValue } from '../../data/priority.value';
import { ICrossword } from '../../model/crossword';
import { ICWQuestionBase, QuestionBasePriority } from '../../model/cwquestion';
import { SearchQuestionsItem } from '../../query/search.question.query';
import { AssertUtil } from '../../util/assert.util';
import { CWQuestionUtil, FilterCWQuestion } from '../../util/cwquestion.util';
import { QuestionPosition, BitFilter, QUESTION_BIT, UNSET_CHAR } from '../models/build.models';
import { ReMatrix, ReQuestion } from './re.matrix';

export class AddCWQuestionUtil {
  private readonly matrix: ReMatrix;
  constructor(crossword: ICrossword) {
    this.matrix = ReMatrix.createFromCrossword(crossword);
  }
  canFitNewQuestion(position: Position) {
    return this.getOpenIntervalForPosition(position) != null;
  }
  canFitQuestion(position: Position, question: ICWQuestionBase): boolean {
    const openInterval = this.getOpenIntervalForPosition(position);
    if (openInterval != null) {
      const priorityQuestion: QuestionBasePriority = { ...question, priority: PriorityValue.Low };
      const reQuestion = new ReQuestion(priorityQuestion);
      return openInterval.canAddQuestion(reQuestion);
    } else {
      return false;
    }
  }

  private getOpenIntervalForPosition(position: Position) {
    const openIntervals = this.matrix.getOpenIntervals();
    return openIntervals.find((i) => i.interval.equal(position));
  }

  getFilterForQuestions(position: Position) {
    const openInterval = this.getOpenIntervalForPosition(position);
    return openInterval.getBitFilter();
  }
}
