import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import { CrosswordGameService } from 'src/app/services/crosswordgame/crossword-game.service';
import { CrosswordGameNotification } from '../../../../../../Common/data/crossword.game.notification';
import { GameState } from '../../../../../../Common/data/game.state';
import { GameType } from '../../../../../../Common/data/game.type';
import { CrosswordType } from '../../../../../../Common/model/crossword';
import { ICrosswordGame } from '../../../../../../Common/model/crossword.game';
import { IPlayer } from '../../../../../../Common/model/player';
import { HelpInstruction } from '../../../../../../Common/model/player.settings';
import { CrosswordGamePlayerUtil } from '../../../../../../Common/util/crossword.game.player.util';
import { CrosswordGameUtil } from '../../../../../../Common/util/crossword.game.util';
import { IdUtil } from '../../../../../../Common/util/id.util';
import { ComponentCommunicationService } from '../../../services/componentcommunication/component-communication.service';
import { GamePlayerColorService } from '../../../services/game-player-color/game-player-color.service';
import {
  HandleNotificationService,
  NotificationMessage,
  NotificationMessageType,
} from '../../../services/handlenotification/handle.notification.service';
import { HelpDialogsService } from '../../../services/helpdialogs/help-dialogs.service';
import { PushNotificationService } from '../../../services/pushnotifcation/push-notification.service';
import { UserService } from '../../../services/user/user.service';
import { MessageBoxType } from '../../dialog/message-box-content/message-box-content.component';
import { DialogService } from '../../dialog/service/dialog.service';
import { CrosswordConstants } from '../../util/crossword.constants';
import { CrosswordGamePlay } from '../../util/crossword.game.play';
import { DocumentUtil } from '../../util/document.util';
import { GameHandleCrosswordView } from '../../util/handleCrosswordView/game.handle.crossword.view';
import { ParamsUtil } from '../../util/params.util';
import { StringUtil } from '../../util/string.util';
import { PlayCrosswordTitleService } from './play-crossword-title.service';
@Component({
  selector: 'app-play-crossword',
  templateUrl: './play-crossword.component.html',
  styleUrls: ['./play-crossword.component.scss'],
})
export class PlayCrosswordComponent implements OnInit, OnDestroy {
  GameType = GameType;
  GameState = GameState;
  CrosswordType = CrosswordType;
  crosswordGame: ICrosswordGame;
  readonly gameHandleCrosswordView: GameHandleCrosswordView;
  private readonly crosswordGamePlay: CrosswordGamePlay;
  private alive = true;
  constructor(
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private crosswordGameService: CrosswordGameService,
    private userService: UserService,
    private componentCommunication: ComponentCommunicationService,
    private translateService: TranslateService,
    private pushNotificationService: PushNotificationService,
    private helpDialogsService: HelpDialogsService,
    private router: Router,
    private handleNotificationService: HandleNotificationService,
    private gamePlayerColorService: GamePlayerColorService,
    private playCrosswordTitleService: PlayCrosswordTitleService,
  ) {
    this.crosswordGameService.initializePlay();

    this.gameHandleCrosswordView = this.crosswordGameService.getGameHandleCrosswordView();
    this.crosswordGamePlay = this.crosswordGameService.getCrosswordGamePlay();
  }
  ngOnDestroy(): void {
    this.alive = false;
    this.crosswordGameService.setCheckForCrosswordChanged(false);
    this.componentCommunication.showCrosswordGameActions.next(false);
  }
  async ngOnInit() {
    const queryParams = await ParamsUtil.getQueryParam(this.activatedRoute);
    const params = await ParamsUtil.getParams(this.activatedRoute);
    await this.initWithId(params.id, queryParams.join);
    this.pushNotificationService
      .getNotificationMessageObservable()
      .pipe(takeWhile(() => this.alive))
      .subscribe((crosswordGameNotification: CrosswordGameNotification) => {
        if (this.crosswordGame._id == crosswordGameNotification.gameId) {
          this.crosswordGameService.checkIfCrosswordGameIsUpdated();
        }
      });
  }

  @HostListener('window:focus')
  onWindowFocus() {
    if (this.crosswordGame) {
      this.crosswordGameService.checkIfCrosswordGameIsUpdated();

      DocumentUtil.clearSelection();
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  windowBeforeunload(event: BeforeUnloadEvent) {
    if (this.hasUnplayedChars()) {
      event.returnValue = this.translateService.instant('messages.unplayed-chars');
    }
  }
  hasUnplayedChars() {
    if (!this.crosswordGame) {
      return false;
    }
    return this.isCurrentPlayer() && this.gameHandleCrosswordView.getCharsSetOnTile().length > 0;
  }
  private isCurrentPlayer() {
    return IdUtil.idEquals(this.crosswordGame.turns.currentPlayerId, this.getPlayer()._id);
  }

  private getPlayer(): IPlayer {
    return this.userService.getLoggedIn();
  }

  private async initWithId(id: string, joinGame: boolean) {
    this.crosswordGame = await this.loadAndSetCurrentCrosswordGame(id);
    if (this.crosswordGame) {
      this.updateCrosswordGameTiles();
      this.playCrosswordTitleService.updateTitle(this.crosswordGame);
      this.crosswordGameService.crosswordGameSubject
        .pipe(takeWhile(() => this.alive))
        .subscribe((crosswordGame) => this.handleCrosswordGameChanged(crosswordGame));
      this.crosswordGameService.setCheckForCrosswordChanged(true);
      this.componentCommunication.showCrosswordGameActions.next(true);
      await this.helpDialogsService.checkAndShowDialog(HelpInstruction.Game);
      await this.checkAndShowDescription();
      if (CrosswordGameUtil.isCreatorAndHasntSetPlayerColor(this.crosswordGame, this.getPlayer())) {
        await this.setNewPlayerColorForCreator();
      }
      if (joinGame) {
        this.componentCommunication.userWantsToJoin.next(true);
      }
    } else {
      await this.dialogService.showMessageBox('', this.translateService.instant('messages.missing-game'), MessageBoxType.Ok);
      this.router.navigateByUrl('/listCrosswordGames');
    }
  }

  private async checkAndShowDescription() {
    if (
      !CrosswordGamePlayerUtil.isPlayerInCrosswordGame(this.crosswordGame, this.getPlayer()) &&
      this.crosswordGame.initial.crossword.description
    ) {
      await this.dialogService.showMessageBox(
        this.translateService.instant('titles.crossword-description', { name: this.crosswordGame.initial.crossword.name }),
        StringUtil.replaceNewLineWithBr(this.crosswordGame.initial.crossword.description),
        MessageBoxType.Ok,
      );
    }
  }

  private updateCrosswordGameTiles() {
    this.crosswordGamePlay.refreshTiles();
    this.gameHandleCrosswordView.refreshTiles(this.getCharsThatCanBeAdded());
  }

  private getCharsThatCanBeAdded() {
    if (
      this.crosswordGame.global.state == GameState.Started &&
      CrosswordGamePlayerUtil.isCurrentPlayer(this.crosswordGame, this.getPlayer())
    ) {
      return CrosswordGameUtil.getCharStoredToCurrentPlayer(this.crosswordGame) + CrosswordConstants.CharsAddedPerTurn;
    }
    return 0;
  }

  private async handleCrosswordGameChanged(crosswordGameChangedTo: ICrosswordGame) {
    const previousGame = this.crosswordGame;
    this.crosswordGame = crosswordGameChangedTo;
    const handleNotificationData = this.handleNotificationService.getNotificationMessages(
      previousGame,
      crosswordGameChangedTo,
      this.getPlayer(),
    );
    if (handleNotificationData.messages.length) {
      this.showMessages(handleNotificationData.messages);
    }
    if (CrosswordGameUtil.shouldUpdateCrosswordTiles(previousGame, this.crosswordGame)) {
      this.updateCrosswordGameTiles();
      this.playCrosswordTitleService.updateTitle(this.crosswordGame);
    } else if (CrosswordGameUtil.shouldUpdatePlayersInfoOnTiles(previousGame, this.crosswordGame)) {
      this.gameHandleCrosswordView.refreshTileTurnOnTiles(this.getCharsThatCanBeAdded());
    }
  }

  private async showMessages(messages: NotificationMessage[]) {
    //If progress is showing, hide it before showing messages
    await this.dialogService.showProgress(false);
    for (const message of messages) {
      if (message.type == NotificationMessageType.Alert) {
        await this.dialogService.showMessageBox('', message.text, MessageBoxType.Ok, true);
      } else {
        await this.dialogService.showSnackbar(message.text, 5000);
      }
    }
  }

  private async loadAndSetCurrentCrosswordGame(id: string) {
    try {
      return await this.crosswordGameService.loadAndSetCurrentCrosswordGame(id);
    } catch (error) {
      console.warn('Failed to get crossword with id', id);
    }
    return null;
  }

  private async setNewPlayerColorForCreator() {
    const crosswordGameToUpdate = CrosswordGameUtil.getCopyOfCrosswordGame(this.crosswordGame);
    const playerColor = await this.gamePlayerColorService.getNewPlayerColor(crosswordGameToUpdate, false);
    CrosswordGamePlayerUtil.setPlayerToGamePlayerColor(crosswordGameToUpdate, this.getPlayer(), playerColor);
    this.dialogService.wrapInProgress(
      () => this.crosswordGameService.updateCrosswordGame(crosswordGameToUpdate),
      this.translateService.instant('messages.save-color-in-game'),
    );
  }
}
