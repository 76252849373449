import { ActivatedRoute } from '@angular/router';
import { lastValueFrom, take } from 'rxjs';

export class ParamsUtil {
  static getQueryParam(activatedRoute: ActivatedRoute) {
    return lastValueFrom(activatedRoute.queryParams.pipe(take(1)));
  }
  static getParams(activatedRoute: ActivatedRoute) {
    return lastValueFrom(activatedRoute.params.pipe(take(1)));
  }
}
