import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ErrorResultUtil, ErrorType } from '../../../../../../Common/data/error.result';
import { PriorityValue } from '../../../../../../Common/data/priority.value';
import { LanguageUtil } from '../../../../../../Common/util/language.util';
import { CrosswordGameService } from '../../../services/crosswordgame/crossword-game.service';
import { UserService } from '../../../services/user/user.service';
import { MessageBoxType } from '../message-box-content/message-box-content.component';
import { DialogService } from '../service/dialog.service';

export enum UserCodeType {
  ForgotPassword,
  VerifyEmail,
  JoinGame,
}

export interface UserCodeSettings {
  type: UserCodeType;
  email?: string;
}

interface UserCode {
  code: FormControl<string>;
}

@Component({
  selector: 'app-number-code',
  templateUrl: './number-code.component.html',
})
export class UserCodeComponent {
  UserCodeType = UserCodeType;
  codeForm: FormGroup<UserCode>;
  priorityValue: PriorityValue;
  length: number;
  title: string;
  constructor(
    private translateService: TranslateService,
    private dialogService: DialogService,
    private crosswordGameService: CrosswordGameService,
    private matDialogRef: MatDialogRef<UserCodeComponent>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: UserCodeSettings,
  ) {
    this.length = this.getLength();
    this.title = this.getTitle();
    this.codeForm = new FormGroup<UserCode>({
      code: new FormControl('', [
        Validators.required,
        Validators.minLength(this.length),
        Validators.maxLength(this.length),
        Validators.pattern('[0-9]*'),
      ]),
    });
  }
  onOkPressed() {
    if (this.data.type == UserCodeType.ForgotPassword) {
      this.handleOkForForgotPassword();
    }
    if (this.data.type == UserCodeType.VerifyEmail) {
      this.handleOkForVerifyEmail();
    }
    if (this.data.type == UserCodeType.JoinGame) {
      this.handleOkForJoinCrosswordGame();
    }
  }
  onCancel() {
    this.matDialogRef.close(null);
  }
  async onResendVerifyCodeToUser() {
    await this.dialogService.wrapInProgress(
      () => this.userService.resendVerifyCodeToUser({ languageCode: LanguageUtil.getLanguageCode(this.translateService.getBrowserLang()) }),
      this.translateService.instant('messages.resending-verify-code-to-user'),
    );
    await this.dialogService.showSnackbar(this.translateService.instant('messages.sent-verify-code-to-user'), 3000);
  }
  private getLength() {
    if (this.data.type == UserCodeType.JoinGame) {
      return 4;
    }
    return 6;
  }
  private getTitle() {
    if (this.data.type == UserCodeType.JoinGame) {
      return this.translateService.instant('titles.join-game');
    }
    return this.translateService.instant('titles.user-code');
  }
  private async handleOkForVerifyEmail() {
    const value = this.codeForm.value;
    const result = await ErrorResultUtil.ExecuteAndCatchError(
      () =>
        this.dialogService.wrapInProgress(
          () => this.userService.verifyCreatedUser({ code: Number.parseInt(value.code) }),
          this.translateService.instant('messages.verify-create-player'),
        ),
      ErrorType.USER_CODE_DOSNT_MATCH,
      () => this.handleUserCodeDosntMatch(),
    );
    if (result) {
      this.matDialogRef.close(value);
    }
  }
  private async handleUserCodeDosntMatch() {
    await this.dialogService.showMessageBox('', this.translateService.instant('messages.forgot-password-wrong-email'), MessageBoxType.Ok);
    return false;
  }

  private async handleOkForForgotPassword() {
    const value = this.codeForm.value;
    const result = await ErrorResultUtil.ExecuteAndCatchError(
      () =>
        this.dialogService.wrapInProgress(
          () => this.userService.verifyForgotPasswordCode({ email: this.data.email, code: Number.parseInt(value.code) }),
          this.translateService.instant('messages.verify-forgot-password-code'),
        ),
      ErrorType.FORGOT_USER_EMAIL_DOSNT_EXIST,
      () =>
        this.dialogService.showMessageBox(
          '',
          this.translateService.instant('messages.forgot-password-user-code-incorrect'),
          MessageBoxType.Ok,
        ),
    );
    if (result) {
      this.matDialogRef.close(Number.parseInt(this.codeForm.controls.code.value));
    } else {
      await this.dialogService.showMessageBox(
        '',
        this.translateService.instant('messages.forgot-password-user-code-incorrect'),
        MessageBoxType.Ok,
      );
    }
  }
  private async handleOkForJoinCrosswordGame() {
    const value = this.codeForm.value;
    const crosswordGameId = await this.dialogService.wrapInProgress(
      () => this.crosswordGameService.findGame({ joinCode: Number.parseInt(value.code) }),
      this.translateService.instant('messages.find-crossword-game-id'),
    );
    if (crosswordGameId == null) {
      await this.dialogService.showMessageBox(
        '',
        this.translateService.instant('messages.could-not-find-crossword-game'),
        MessageBoxType.Ok,
      );
      return;
    }
    this.matDialogRef.close(crosswordGameId);
  }
}
