import { PriorityValue } from '../data/priority.value';
import { CWQuestionCategory, CWQuestionVisibility, ICWQuestionBase } from '../model/cwquestion';
import { FilterCWQuestion } from '../util/cwquestion.util';
export enum SortDirection {
  Descending = -1,
  Ascending = 1,
}

export interface SearchQuestionsQuery {
  filter?: string;
  sortDirection?: SortDirection;
  pageIndex: number;
  pageSize: number;
  tags?: string[];
  priorities?: PriorityValue[];
  answerLengths?: number[];
  sortColumn?: string;
  ids?: number[];
  notIds?: number[];
  advfilter?: FilterCWQuestion;
  categories?: CWQuestionCategory[];
}
export interface SearchQuestionsResult {
  queryResult: SearchQuestionsItem[];
  allIds: number[];
}
export interface SearchQuestionsItem extends ICWQuestionBase {
  questionId: any;
  authorId: string;
  author: string;
  tags: string[];
  difficulty: number;
  visibility: CWQuestionVisibility;
  answerLength: number;
  updated: Date;
  category: CWQuestionCategory;
  imageUrl: string;
}
