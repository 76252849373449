<mat-toolbar *ngIf="showToolbar" color="accent">
  <mat-toolbar-row>
    <div fxFlexFill fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
      <div
        fxFlex
        fxLayout.lt-sm="column"
        fxLayout.gt-xs="row"
        fxLayoutAlign="center center"
        fxLayoutGap.lt-sm="-15px"
        fxLayoutGap.gt-xs="5px"
      >
        <span [ngStyle.lt-sm]="'font-size: small'" [ngStyle.gt-xs]="'font-size: medium'">
          {{ 'misc.subscribe-to-notications-line1' | translate }}</span
        >
        <span [ngStyle.lt-sm]="'font-size: small'" [ngStyle.gt-xs]="'font-size: medium'">
          {{ 'misc.subscribe-to-notications-line2' | translate }}</span
        >
      </div>
      <app-flexable-button-menu fxFlex [buttons]="notificationButtons"> </app-flexable-button-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
