import { ICrossword } from '../../model/crossword';
import { ReMatrix } from './re.matrix';

export class CrosswordMatrixScore {
  constructor(public readonly withPriority: number, public readonly withoutPriority: number) {}
  greatherThen(cMatrixScore: CrosswordMatrixScore) {
    return this.getDiff(cMatrixScore) > 0;
  }
  private getDiff(cMatrixScore: CrosswordMatrixScore) {
    const withNormalizedDiff = this.getNormalizedDiff(this.withPriority, cMatrixScore.withPriority);
    const withoutNormalizedDiff = this.getNormalizedDiff(this.withoutPriority, cMatrixScore.withoutPriority);
    return withNormalizedDiff * 3 + withoutNormalizedDiff;
  }

  compare(cMatrixScore: CrosswordMatrixScore) {
    const diff = this.getDiff(cMatrixScore);
    if (diff == 0) {
      return 0;
    }
    return diff > 0 ? 1 : -1;
  }
  private getNormalizedDiff(value1: number, value2: number) {
    return (value1 - value2) / Math.max(value1, value2);
  }
}

export class CrosswordScoreUtil {
  static getScore(crossword: ICrossword) {
    return ReMatrix.createFromCrossword(crossword).getScore();
  }
}
