import { Component, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CWQuestionType } from '../../../../../../../Common/model/cwquestion';
import { EditQuestionTiles } from '../edit-question-tiles/edit-question-tiles.component';
import { UseQuestionForm } from '../question.form.models';

@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
})
export class QuestionFormComponent implements OnChanges {
  @Input()
  questionForm: FormGroup<UseQuestionForm>;

  editQuestionTiles: EditQuestionTiles;

  constructor(private translateService: TranslateService) {}

  ngOnChanges(): void {
    const value = this.questionForm.value;
    this.editQuestionTiles = {
      question: { answer: value.answer, question: value.question, indexId: -1, type: CWQuestionType.Text },
    };
  }
  checkAnswer(): void {
    const value = this.questionForm.value;
    const url = this.translateService.instant('misc.check-answer-with-search', { question: value.question, answer: value.answer });
    window.open(url, '_blank');
  }
}
