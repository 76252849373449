export class StoredBool {
  constructor(
    private readonly name: string,
    readonly defaultValue: boolean,
  ) {
    if (localStorage.getItem(this.name) === null) {
      this.value = defaultValue;
    }
  }
  get value(): boolean {
    return localStorage.getItem(this.name) === 'true';
  }
  set value(value: boolean) {
    localStorage.setItem(this.name, value ? 'true' : 'false');
  }
}
