import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PriorityValue } from '../../../../../../Common/data/priority.value';
export interface QuestionPriorityData {
  disableCancel: boolean;
}
@Component({
  selector: 'app-question-priority-dialog',
  templateUrl: './question-priority-dialog.component.html',
})
export class QuestionPriorityDialogComponent {
  priorityValue: PriorityValue;
  constructor(
    private matDialogRef: MatDialogRef<QuestionPriorityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QuestionPriorityData
  ) {}
  onPrioritySelected(priorityValue: PriorityValue) {
    this.priorityValue = priorityValue;
  }
  onCloseDialogWithValue() {
    this.matDialogRef.close(this.priorityValue);
  }
  onCancel() {
    this.matDialogRef.close(null);
  }
}
